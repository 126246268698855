import React from "react";
import { useInView } from "react-intersection-observer";
import { Span } from "./AnimatedSpanStyles";
import "animate.css";
const AnimatedSpanStyles = ({ children }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  return (
    <Span ref={ref} opacity={inView ? "1" : "0"}>
      {children}
    </Span>
  );
};

export default AnimatedSpanStyles;
