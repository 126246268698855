//REACT
import React, { useContext } from "react";
import { LanguageActivity } from "../../../../context/LanguageActivity";

//STYLES
import "./SubEspaisDefaultPage.css";

//IMAGES
import cross from "../../../../assets/EspaisPage/cross.svg";
import BotiguesDefaultPage from "../BotiguesDefaultPage/BotiguesDefaultPage";

const SubEspaisDefaultPage = ({
  title,
  text,
  verticalNavbar,
  section,
  setController,
  id,
  isRestoration,
  table,
  isStore,
  section2,
  verticalHeight,
  opacity,
  cta,
  text2,
  cta2,
  text3,
}) => {
  const { data } = useContext(LanguageActivity);

  const handleClickSubespais = (key) => {
    setController({ boolean: true, key: key });
  };

  return (
    <div className="subespaisDefaultPage-pater" id={id}>
      <div className="subespaisDefaultPage-container">
        {verticalNavbar !== undefined && (
          <div
            className={
              verticalHeight === 90
                ? "vertical-link-container"
                : "vertical-link-container height-adjusted"
            }
          >
            {verticalNavbar.map((item, index) => {
              return (
                <p
                  className={
                    opacity === item.key
                      ? "vertical-link opacity"
                      : "vertical-link"
                  }
                  key={index}
                  onClick={() => {
                    handleClickSubespais(item.key);
                  }}
                >
                  {item.name}
                </p>
              );
            })}
          </div>
        )}
        {isStore === true && (
          <BotiguesDefaultPage
            info={table}
            section={section}
            setController={setController}
            section2={section2}
          />
        )}
        {!isStore && (
          <div
            className={
              isRestoration
                ? "subespaisDefaultPage-wrapper restorationController"
                : "subespaisDefaultPage-wrapper"
            }
          >
            <div className="central-wrapper">
              {data.espais !== undefined && (
                <p className="subespaisDefaultPage-wrapper-section">
                  {section}
                  {section2 !== undefined && section2}
                </p>
              )}
              <p className="subespaisDefaultPage-wrapper-title">{title}</p>
              <p className="subespaisDefaultPage-wrapper-text">{text}</p>
              {text2 !== undefined && (
                <p className="subespaisDefaultPage-wrapper-text">{text2}</p>
              )}
              {text3 !== undefined && (
                <p className="subespaisDefaultPage-wrapper-text">{text3}</p>
              )}
              {cta !== undefined && title === "Roche Bobois" && (
                <a
                  style={{
                    color: "var(--skyblue)",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                  href="mailto:rbmercantic@roche-bobois.es"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {cta}
                </a>
              )}
              {cta !== undefined && title !== "Roche Bobois" && (
                <a
                  style={{ color: "var(--skyblue)", marginBottom: "1rem" }}
                  href="https://www.mercanticshop.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {cta}
                </a>
              )}

              {cta2 !== undefined && (
                <a
                  style={{ color: "var(--skyblue)", marginBottom: "1rem" }}
                  href="tel:639 279 144"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {cta2}
                </a>
              )}
              <img
                src={cross}
                alt="cross"
                className="cross"
                onClick={() => {
                  setController({
                    key: 0,
                    boolean: false,
                  });
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubEspaisDefaultPage;
