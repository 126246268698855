export const homePageData = {
  catala: {
    cookies: {
      br1: "Utilitzem cookies al nostre web per tal de proporcinar la millor experiència guardant les teves preferències.",
      br2: "Si ho desitges, pots visitar ",
      br3: "Gestió Cookies",
      br4: " per tal de gestionar el consentiment",
    },
    frame2: {
      mobile: {
        paragraph1: {
          br1: "Mercantic és un ",
          br2: "punt de trobada ",
          br3: "pels amants de les ",
          br4: "antiguitats, les arts, ",
          br5: "la gastronomia i la ",
          br6: "música.",
        },
        paragraph2: {
          br1: "Creat l'any 1992 en",
          br2: "l'antiga fàbrica de ",
          br3: "ceràmica fundada ",
          br4: "per Josep Barnils el ",
          br5: "1956, s'ha convertit",
          br6: " en un centre cultural",
          br7: "al cor de Sant Cugat.",
        },
      },
      laptop: {
        paragraph1: {
          br1: "Mercantic és un punt de",
          br2: "trobada pels amants de les",
          br3: "antiguitats, les arts, la ",
          br4: "gastronomia i la música.",
        },
        paragraph2: {
          br1: "Creat l'any 1992 en l'antiga ",
          br2: "fàbrica de ceràmica",
          br3: "fundada per Josep Barnils",
          br4: "el 1956, s'ha convertit en",
          br5: "un centre cultural al cor de",
          br6: "Sant Cugat.",
        },
      },
    },
    frame4: [
      {
        key: 0,
        title: "Mercats",
        number: "01",
        paragraph1:
          "Mercantic compta amb un mercat d'antiguitats permanent obert de dimarts a diumenge.",
        paragraph2:
          " Paral·lelament, se celebren mercats de gran varietat tots els caps de setmana.",
        footer: {
          footer1: "Mercats",
        },
        path: "markets",
      },
      {
        key: 1,
        title: "Concerts",
        number: "02",
        paragraph1:
          "L'oferta musical gira al voltant de la sala El Siglo, oferint setmanalment concerts de música en viu al vespre i al migdia, creant així l'ambient perfecte per prendre el ja clàssic vermut dels dissabtes i diumenges a les 12h.",
        paragraph2:
          " La Sala es pot llogar per a esdeveniments privats segons disponibilitat.",
        footer: {
          footer1: "Agenda",
        },
        path: "schedule",
      },
      {
        key: 2,
        title: "Tallers",
        number: "03",
        paragraph1:
          "Per a tots aquells que busquen aprendre noves habilitats i desenvolupar la seva creativitat al Mercantic.",
        paragraph2:
          " Aquí trobareu una àmplia varietat de tallers, tots impartits per experts en cada àrea.",
        footer: {
          footer1: "Els nostres tallers",
          footer2: "Agenda",
        },
        path: "workshops",
      },
      {
        key: 3,
        title: "Gastronomia",
        number: "04",
        paragraph1:
          "Mercantic ofereix una àmplia selecció de restaurants amb una cuina local i de temporada, feta amb ingredients frescos i de proximitat. ",
        paragraph2:
          " Les opcions inclouen el Can Gula, la Taverna Catalana, el Restaurant Greta, la Casa Ñidro i la food truck Cocina Inquieta.",
        footer: {
          footer1: "Els nostres restaurants",
        },
        path: "gastronomy",
      },
    ],
    frame5: {
      mobile: {
        paragraph1:
          "En aquest recinte artístic, podeu trobar una gran varietat d'expressions culturals envoltades d'història i disseny. Brocanters, artesans, restauradors, artistes, músics i creadors multidisciplinaris, conformen la quotidianitat d’aquest espai on dia a dia se celebra la creativitat, la diversitat i la comunitat.",
        paragraph2:
          "L'espai disposa d'una superfície de més de 15.000 m² dividits entre la Nau Central, el Barri de les Casetes i el Districte Antic amb més de 100 comerciants residents i sis restaurants de la més variada oferta gastronòmica.",
      },
      laptop: {
        paragraph1:
          "En aquest recinte artístic, podeu trobar una gran varietat d'expressions culturals envoltades d'història i disseny. Brocanters, artesans, restauradors, artistes, músics i creadors multidisciplinaris, conformen la quotidianitat d’aquest espai on dia a dia se celebra la creativitat, la diversitat i la comunitat.",
        paragraph2:
          "L'espai disposa d'una superfície de més de 15.000 m² dividits entre la Nau Central, el Barri de les Casetes i el Districte Antic amb més de 100 comerciants residents i sis restaurants de la més variada oferta gastronòmica.",
      },
      paragraph3: "El recinte està obert al públic de dimarts a diumenge",
    },
    frame7: [
      {
        title: "Zones",
        key: 0,
        route: "spaces/home",
      },
      { title: "Mercats", key: 1, route: "markets" },
      {
        title: "Tallers",
        key: 2,
        route: "workshops",
      },
      {
        title: "Gastronomia",
        key: 3,
        route: "gastronomy",
      },
      {
        title: "Agenda",
        key: 4,
        route: "schedule",
      },
    ],
  },
  castella: {
    cookies: {
      br1: "Utilizamos cookies en nuestra web para proporcinar la mejor experiencia guardando tus preferencias.",
      br2: "Si lo deseas, puedes visitar ",
      br3: "Gestión Cookies",
      br4: " para gestionar el consentimiento de las mismas",
    },
    frame2: {
      mobile: {
        paragraph1: {
          br1: "Mercantic es un ",
          br2: "punto de encuentro ",
          br3: "para los amantes de ",
          br4: "las antigüedades, las artes ",
          br5: "la gastronomía y la ",
          br6: "música.",
        },
        paragraph2: {
          br1: "Creado en 1992 en ",
          br2: "la antigua fábrica de ",
          br3: "cerámica fundada ",
          br4: "por Josep Barnils en ",
          br5: "1956, se ha convertido",
          br6: "en un centro cultural en",
          br7: " el corazón de Sant Cugat.",
        },
      },
      laptop: {
        paragraph1: {
          br1: "Mercantic es un punto de",
          br2: "encuentro para los amantes de ",
          br3: "las antigüedades, las artes, la",
          br4: "gastronomía y la música.",
        },
        paragraph2: {
          br1: "Creado en 1992 en la antigua",
          br2: "fábrica de cerámica",
          br3: "fundada por Josep Barnils",
          br4: "en 1956, se ha convertido en ",
          br5: "un centro cultural en el corazón ",
          br6: "de Sant Cugat.",
        },
      },
    },
    frame4: [
      {
        key: 0,
        title: "Mercados",
        number: "01",
        paragraph1:
          "Mercantic cuenta con un mercado de antigüedades permanente abierto de martes a domingo.",
        paragraph2:
          " Además, se celebran mercados de gran variedad todos los fines de semana.",
        footer: {
          footer1: "Mercados",
        },
        path: "markets",
      },
      {
        key: 1,
        title: "Conciertos",
        number: "02",
        paragraph1:
          "La oferta musical gira en torno a la sala El Siglo, ofreciendo semanalmente conciertos de música en vivo por las tardes y al mediodía, creando así el ambiente perfecto para disfrutar del vermut de los sábados y domingos a las 12h",
        paragraph2:
          " Esta misma sala, se puede alquilar para eventos privados según disponibilidad.",
        footer: {
          footer1: "Agenda",
        },
        path: "schedule",
      },
      {
        key: 2,
        title: "Talleres",
        number: "03",
        paragraph1:
          "Para todos aquellos que buscan aprender nuevas habilidades y desarrollar su creatividad.",
        paragraph2:
          " en Mercantic encontrarás una amplia variedad de talleres, todos son impartidos por expertos en cada área. Descubre todos los talleres del mes en nuestra agenda.",
        footer: {
          footer1: "Nuestros talleres",
          footer2: "Agenda",
        },
        path: "workshops",
      },
      {
        key: 3,
        title: "Gastronomía",
        number: "04",
        paragraph1:
          "Mercantic ofrece una amplia selección de restaurantes con una cocina local y de temporada, elaborada con ingredientes frescos y locales",
        paragraph2:
          " Las opciones incluyen Can Gula, la Taverna Catalana, el Restaurante Greta, la Casa Ñidro y el food truck Cocina Inquieta.",
        footer: {
          footer1: "Nuestros restaurantes.",
        },
        path: "gastronomy",
      },
    ],
    frame5: {
      mobile: {
        paragraph1:
          "En este recinto artístico, puedes encontrar una gran variedad de expresiones culturales rodeadas de historia y diseño. Brocantes, artesanos, restauradores, artistas, músicos y creadores multidisciplinarios conforman la cotidianidad de este espacio donde se celebra la creatividad, la diversidad y la comunidad día a día",
        paragraph2:
          "El espacio cuenta con una superficie de más de 15,000 m² divididos entre la Nave Central, el Barrio de las Casetes y el Districte Antic, con más de 100 comerciantes residentes y seis restaurantes que ofrecen una amplia oferta gastronómica.",
      },
      laptop: {
        paragraph1:
          "En este recinto artístico, puedes encontrar una gran variedad de expresiones culturales rodeadas de historia y diseño. Brocantes, artesanos, restauradores, artistas, músicos y creadores multidisciplinarios conforman la cotidianidad de este espacio donde se celebra la creatividad, la diversidad y la comunidad día a día",
        paragraph2:
          "El espacio cuenta con una superficie de más de 15,000 m² divididos entre la Nave Central, el Barrio de las Casetes y el Districte Antic, con más de 100 comerciantes residentes y seis restaurantes que ofrecen una amplia oferta gastronómica.",
      },
      paragraph3: "El recinto está abierto al público de martes a domingo",
    },
    frame7: [
      {
        title: "Zonas",
        key: 0,
        route: "spaces/home",
      },
      { title: "Mercados", key: 1, route: "markets" },
      {
        title: "Talleres",
        key: 2,
        route: "workshops",
      },
      {
        title: "Gastronomía",
        key: 3,
        route: "gastronomy",
      },
      {
        title: "Agenda",
        key: 4,
        route: "schedule",
      },
    ],
  },
  angles: {
    cookies: {
      br1: "We use cookies on our website to give you the most relevant experience by remembering your preferences.",
      br2: "You may visit ",
      br3: "Cookies Management",
      br4: " to provide a controlled consent",
    },
    frame2: {
      mobile: {
        paragraph1: {
          br1: "Mercantic is ",
          br2: "a meeting point ",
          br3: " for lovers of",
          br4: " antiques, art",
          br5: "gastronomy, and",
          br6: "music.",
        },
        paragraph2: {
          br1: "Founded in 1992 by",
          br2: "Josep Barnils, Mercantic",
          br3: "was created within ",
          br4: "the walls of a former ",
          br5: "ceramics factory and has",
          br6: "become since a cultural staple",
          br7: "in the heart of Sant Cugat.",
        },
      },
      laptop: {
        paragraph1: {
          br1: "Mercantic is a meeting point",
          br2: "for the lovers of antiques, ",
          br3: "arts, gastronomy and",
          br4: "music.",
        },
        paragraph2: {
          br1: "Founded in 1992 by Josep ",
          br2: "Barnils, Mercantic was created",
          br3: "within the walls of a",
          br4: "former ceramics factory and has",
          br5: "become since a cultural staple",
          br6: "in the heart of Sant Cugat.",
        },
      },
    },
    frame4: [
      {
        key: 0,
        title: "Markets",
        number: "01",
        paragraph1:
          "Mercantic hosts a permanent antique market open Tuesday to Sunday.",
        paragraph2: "Additional markets are hosted every weekend.",
        footer: {
          footer1: "Markets",
        },
        path: "markets",
      },
      {
        key: 1,
        title: "Concerts",
        number: "02",
        paragraph1:
          "Live music takes place the El Siglo hall in the evening and middays on a rotating weekly schedule - the perfect atmosphere for vermouth drinking on the weekends.",
        paragraph2:
          "The venue can be rented for private events subject to availability.",
        footer: {
          footer1: "Agenda",
        },
        path: "schedule",
      },
      {
        key: 2,
        title: "Workshops",
        number: "03",
        paragraph1:
          "For those looking to learn new skills and develop their creativity at Mercantic.",
        paragraph2:
          " Here you will find a wide variety of workshops, all taught by experts in each field.",
        footer: {
          footer1: "Our Workshops",
          footer2: "Agenda",
        },
        path: "workshops",
      },
      {
        key: 3,
        title: "Gastronomy",
        number: "04",
        paragraph1:
          "Mercantic offers a wide selection of local and seasonal cuisine made from the freshest ingredients.",
        paragraph2:
          " The options include Can Gula, la Taverna Catalana, Restaurant Greta, Casa Ñidro, and the Cocina Inquieta food truck.",
        footer: {
          footer1: "Our Restaurants",
        },
        path: "gastronomy",
      },
    ],
    frame5: {
      mobile: {
        paragraph1:
          "This historic space fosters artistic and cultural development. Brocanteurs, craftsmen, restorers, artists, musicians, and multidisciplinary creators make up the everyday here. Creativity, diversity, and community are celebrated everyday.",
        paragraph2:
          "The space covers an area of over 15,000 m2 divided between the Central Nave, the Casetes District, and the Antic District, with over 100 resident traders and six restaurants offering a diverse gastronomic selection.The venue is open to the public from Tuesday to Sunday",
      },
      laptop: {
        paragraph1:
          "This historic space fosters artistic and cultural development. Brocanteurs, craftsmen, restorers, artists, musicians, and multidisciplinary creators make up the everyday here. Creativity, diversity, and community are celebrated everyday.",
        paragraph2:
          "The space covers an area of over 15,000 m2 divided between the Central Nave, the Casetes District, and the Antic District, with over 100 resident traders and six restaurants offering a diverse gastronomic selection.",
      },
      paragraph3: "The venue is open to the public from Tuesday to Sunday.",
    },
    frame7: [
      {
        title: "Zones",
        key: 0,
        route: "spaces/home",
      },
      { title: "Markets", key: 1, route: "markets" },
      {
        title: "Workshops",
        key: 2,
        route: "workshops",
      },
      {
        title: "Gastronomy",
        key: 3,
        route: "gastronomy",
      },
      {
        title: "Agenda",
        key: 4,
        route: "schedule",
      },
    ],
  },
};
