// eslint-disable no-alert
//REACT
import React, { useContext, useState, useEffect } from "react";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import { NavbarActivity } from "../../../context/NavbarActivity";

//Assets
import { LanguageActivity } from "../../../context/LanguageActivity";
import { UserActivity } from "../../../context/UserActivity";
import { useNavigate } from "react-router-dom";
//STYLING
import "./DropdownMenu.css";
import {
  LanguageParagraph,
  DropdownContainer,
  DropdownMenuPater,
  ItemParagraph,
  FooterParagraph,
  DropdownFooter,
  DropdownWrapper,
  FirstSquaredRow,
  FirstSquareFirstSquaredRow,
  SecondSquaredRow,
  FirstSquareSecondSquaredRow,
  SecondSquareSecondSquaredRow,
  DropdownLink,
} from "./DropwdownMenuStyles";
import menu from "../../../assets/DropdownMenu/MENU.png";
import agendaBackground from "../../../assets/DropdownMenu/MENU_AGENDA.png";
import marketsBackground from "../../../assets/DropdownMenu/MENU_MERCATS.png";
import contactBackground from "../../../assets/DropdownMenu/MENU_CONTACTO.png";
import gastronomyBackground from "../../../assets/DropdownMenu/MENU_GASTRONOMI╠üA.png";
import spacesBackground from "../../../assets/DropdownMenu/MENU_ESPAIS.png";
import workshopsBackground from "../../../assets/DropdownMenu/MENU_TALLERSDef.png";
import productBackgroundDesktop from "../../../assets/DropdownMenu/FONDO_MENU_PRODUCTOS_DESKTOP.svg";

//COMPONENTS
import Navbar from "../Navbar/Navbar";

const Dropdownmenu = () => {
  const { data, setLanguageSelected } = useContext(LanguageActivity);
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = useState({
    itemColor: "var(--skyblue)",
    footerColor: "var(--mercagreen)",
    footerBackground: "var(--skyblue)",
  });
  const [backgroundImage, setbackgroundImage] = useState(menu);
  const [hoverController, setHoverController] = useState(null);
  const [backgroundSizeController, setBackgroundSizeController] =
    useState("100vw 100vh");
  const displayMobile = useWindowSize();
  const { setDropdownMenuSelected, setEnableScrollToTop } =
    useContext(UserActivity);
  const navigate = useNavigate();
  const {
    setNavbarColor,
    setHamburgerColor,
    setNavbarBackground,
    hamburgerColor,
    navbarColor,
  } = useContext(NavbarActivity);
  useEffect(() => {
    if (!displayMobile) {
      switch (hoverController) {
        case 0:
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
          setBackgroundSizeController("100vw 100vh");
          break;
        case 1:
          setNavbarColor("var(--mercared)");
          setHamburgerColor("var(--skyblue)");
          setbackgroundImage(marketsBackground);
          setBackgroundColor({
            itemColor: "var(--mercared)",
            footerColor: "var(--skyblue)",
            footerBackground: "var(--mercared)",
          });
          setBackgroundSizeController("100vw 100vh");
          break;
        case 2:
          setNavbarColor("var(--lightgreen)");
          setHamburgerColor("var(--mercagreen)");
          setbackgroundImage(workshopsBackground);
          setBackgroundColor({
            itemColor: "var(--mercagreen)",
            footerColor: "var(--lightgreen)",
            footerBackground: "var(--mercagreen)",
          });
          setBackgroundSizeController("100vw 100vh");
          break;
        case 3:
          if (displayMobile) {
            setNavbarColor("var(--mercabrown)");
            setHamburgerColor("var(--mercapink)");

            setBackgroundColor({
              itemColor: "var(--mercabrown)",
              footerColor: "var(--mercapink)",
              footerBackground: "var(--mercabrown)",
            });
          } else if (!displayMobile) {
            setNavbarColor("var(--mercabrown)");
            setHamburgerColor("var(--mercapink)");
            setbackgroundImage(gastronomyBackground);
            setBackgroundColor({
              itemColor: "var(--mercabrown)",
              footerColor: "var(--mercapink)",
              footerBackground: "var(--mercabrown)",
            });
          }
          setBackgroundSizeController("100vw 100vh");
          break;
        case 4:
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--skyblue)");
          setbackgroundImage(agendaBackground);
          setBackgroundColor({
            itemColor: "var(--mercagreen)",
            footerColor: "var(--skyblue)",
            footerBackground: "var(--mercagreen)",
          });
          setBackgroundSizeController("100vw 100vh");
          break;
        case 5:
          setNavbarColor("var(--mercapink)");
          setHamburgerColor("var(--mercared)");
          setbackgroundImage(contactBackground);
          setBackgroundColor({
            itemColor: "var(--mercapink)",
            footerColor: "var(--mercared)",
            footerBackground: "var(--mercapink)",
          });
          setBackgroundSizeController("100vw 100vh");
          break;
        case 6:
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");
          setbackgroundImage(productBackgroundDesktop);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
          setBackgroundSizeController("cover");
          break;
        case null:
          setbackgroundImage(menu);
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--skyblue)");
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercagreen)",
            footerBackground: "var(--skyblue)",
          });
          setBackgroundSizeController("100vw 100vh");
          break;
        default:
          break;
      }
    }
  }, [hoverController]);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        if (!displayMobile) {
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--skyblue)");
          setbackgroundImage(menu);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercagreen)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercagreen)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/home":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/naucentral":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/launio":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/lapuntual":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/escolarestauracio":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/botigues":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/districteantic":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/espaisubhasta":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/botiguesdistricte":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/barricasetes":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/botiguescasetes":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/spaces/rochebobois":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
          setbackgroundImage(spacesBackground);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercablue)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/markets":
        if (!displayMobile) {
          setNavbarColor("var(--mercared)");
          setHamburgerColor("var(--skyblue)");
          setbackgroundImage(marketsBackground);
          setBackgroundColor({
            itemColor: "var(--mercared)",
            footerColor: "var(--skyblue)",
            footerBackground: "var(--mercared)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercared)");

          setBackgroundColor({
            itemColor: "var(--mercared)",
            footerColor: "var(--skyblue)",
            footerBackground: "var(--mercared)",
          });
        }
        break;
      case "/gastronomy":
        if (!displayMobile) {
          setNavbarColor("var(--mercabrown)");
          setHamburgerColor("var(--mercapink)");
          setbackgroundImage(gastronomyBackground);
          setBackgroundColor({
            itemColor: "var(--mercabrown)",
            footerColor: "var(--mercapink)",
            footerBackground: "var(--mercabrown)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercabrown)");
          setHamburgerColor("var(--mercapink)");

          setBackgroundColor({
            itemColor: "var(--mercapink)",
            footerColor: "var(--mercabrown)",
            footerBackground: "var(--mercapink)",
          });
        }
        break;
      case "/workshops":
        if (!displayMobile) {
          setNavbarColor("var(--lightgreen)");
          setHamburgerColor("var(--mercagreen)");
          setbackgroundImage(workshopsBackground);
          setBackgroundColor({
            itemColor: "var(--mercagreen)",
            footerColor: "var(--lightgreen)",
            footerBackground: "var(--mercagreen)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--lightgreen)");
          setHamburgerColor("var(--mercagreen)");
          setBackgroundColor({
            itemColor: "var(--mercagreen)",
            footerColor: "var(--lightgreen)",
            footerBackground: "var(--mercagreen)",
          });
        }
        break;
      case "/contact":
        if (!displayMobile) {
          setNavbarColor("var(--mercapink)");
          setHamburgerColor("var(--mercared)");
          setbackgroundImage(contactBackground);
          setBackgroundColor({
            itemColor: "var(--mercapink)",
            footerColor: "var(--mercared)",
            footerBackground: "var(--mercapink)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercared)");
          setHamburgerColor("var(--mercapink)");

          setBackgroundColor({
            itemColor: "var(--mercapink)",
            footerColor: "var(--mercared)",
            footerBackground: "var(--mercapink)",
          });
        }
        break;
      case "/schedule":
        if (!displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--skyblue)");
          setbackgroundImage(agendaBackground);
          setBackgroundColor({
            itemColor: "var(--mercagreen)",
            footerColor: "var(--skyblue)",
            footerBackground: "var(--mercagreen)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercagreen)");

          setBackgroundColor({
            itemColor: "var(--mercagreen)",
            footerColor: "var(--skyblue)",
            footerBackground: "var(--mercagreen)",
          });
        }
        break;
      case "/event":
        if (!displayMobile) {
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--skyblue)");
          setbackgroundImage(menu);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercagreen)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercagreen)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/company":
        if (!displayMobile) {
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--skyblue)");
          setbackgroundImage(menu);
          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercagreen)",
            footerBackground: "var(--skyblue)",
          });
        } else if (displayMobile) {
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--skyblue)");

          setBackgroundColor({
            itemColor: "var(--skyblue)",
            footerColor: "var(--mercagreen)",
            footerBackground: "var(--skyblue)",
          });
        }
        break;
      case "/cookies":
        setNavbarColor("var(--mercagreen)");
        setHamburgerColor("var(--skyblue)");
        setBackgroundColor({
          itemColor: "var(--skyblue)",
          footerColor: "var(--mercagreen)",
          footerBackground: "var(--skyblue)",
        });
        break;
      default:
        break;
    }
  }, [location]);

  const handleLanguageClick = (key) => {
    switch (key) {
      case 1:
        setLanguageSelected({ catala: true, castella: false, angles: false });
        localStorage.setItem(
          "language",
          JSON.stringify({ catala: true, castella: false, angles: false })
        );
        break;
      case 0:
        setLanguageSelected({ catala: false, castella: true, angles: false });
        localStorage.setItem(
          "language",
          JSON.stringify({ catala: false, castella: true, angles: false })
        );
        break;
      case 2:
        setLanguageSelected({ catala: false, castella: false, angles: true });
        localStorage.setItem(
          "language",
          JSON.stringify({ catala: false, castella: false, angles: true })
        );
        break;
      default:
        setLanguageSelected({ catala: true, castella: false, angles: false });
        localStorage.setItem(
          "language",
          JSON.stringify({ catala: true, castella: false, angles: false })
        );
        break;
    }
  };
  useEffect(() => {
    setNavbarBackground("transparent");
  }, []);
  if (displayMobile) {
    return (
      <DropdownMenuPater color={navbarColor}>
        <Navbar />
        <DropdownWrapper backgroundImage={backgroundImage}>
          <DropdownContainer>
            <FirstSquaredRow>
              <FirstSquareFirstSquaredRow
                color={hamburgerColor}
              ></FirstSquareFirstSquaredRow>
            </FirstSquaredRow>

            <div className="dropdown-items">
              {data.dropdown !== undefined &&
                data.dropdown.items.map((item, index) => {
                  if (index !== 5) {
                    return (
                      <ItemParagraph
                        key={index}
                        color={backgroundColor.itemColor}
                        onMouseOver={() => {
                          setHoverController(item.key);
                        }}
                        onClick={() => {
                          setDropdownMenuSelected(false);
                          setEnableScrollToTop(true);
                          navigate(`/${item.route}`);
                        }}
                      >
                        {item.text}
                      </ItemParagraph>
                    );
                  } else if (index === 5) {
                    return (
                      <DropdownLink
                        key={index}
                        color={backgroundColor.itemColor}
                        onMouseOver={() => {
                          setHoverController(item.key);
                        }}
                        onClick={() => {
                          setDropdownMenuSelected(false);
                        }}
                        href="https://www.mercanticshop.com/"
                        target="_blank"
                      >
                        {item.text}
                      </DropdownLink>
                    );
                  }
                  return null;
                })}
            </div>

            <SecondSquaredRow>
              <FirstSquareSecondSquaredRow color={navbarColor}>
                <div className="dropdown-languages">
                  {data.dropdown !== undefined &&
                    data.dropdown.languages.map((item) => {
                      return (
                        <LanguageParagraph
                          color={backgroundColor.footerBackground}
                          key={item.key}
                          onClick={() => {
                            handleLanguageClick(item.key);
                            setDropdownMenuSelected(false);
                          }}
                        >
                          {item.text}
                        </LanguageParagraph>
                      );
                    })}
                </div>
              </FirstSquareSecondSquaredRow>
              <SecondSquareSecondSquaredRow
                color={hamburgerColor}
              ></SecondSquareSecondSquaredRow>
            </SecondSquaredRow>
          </DropdownContainer>
        </DropdownWrapper>
        <DropdownFooter backgroundColor={backgroundColor.footerBackground}>
          {data.dropdown !== undefined && (
            <>
              <FooterParagraph
                color={backgroundColor.footerColor}
                onClick={() => {
                  setDropdownMenuSelected(false);
                  navigate("/event");
                }}
                margin={true}
              >
                {data.dropdown.footer.footer1}
              </FooterParagraph>
              <FooterParagraph
                color={backgroundColor.footerColor}
                onClick={() => {
                  setDropdownMenuSelected(false);
                  navigate("/company");
                }}
                margin={false}
              >
                {data.dropdown.footer.footer2}
              </FooterParagraph>
            </>
          )}
        </DropdownFooter>
      </DropdownMenuPater>
    );
  } else if (!displayMobile) {
    return (
      <div style={{ position: "relative" }}>
        <DropdownMenuPater
          id="dropdown-pater"
          backgroundImage={backgroundImage}
          color={navbarColor}
          backgroundSize={backgroundSizeController}
        >
          <Navbar dropdown={false} />
          <DropdownContainer
            backgroundImage={backgroundImage}
            onMouseLeave={() => {
              setHoverController(null);
            }}
            id="dropdown-container"
          >
            <div className="dropdown-items">
              {data.dropdown !== undefined &&
                data.dropdown.items.map((item, key) => {
                  if (key !== 5) {
                    return (
                      <ItemParagraph
                        color={backgroundColor.itemColor}
                        onMouseOver={() => {
                          setHoverController(item.key);
                        }}
                        onClick={() => {
                          setDropdownMenuSelected(false);
                          navigate(`/${item.route}`);
                        }}
                        key={item.key}
                        opacity={
                          item.key === hoverController ||
                          hoverController === null
                            ? "1"
                            : "0.5"
                        }
                      >
                        {item.text}
                      </ItemParagraph>
                    );
                  } else if (key === 5) {
                    return (
                      <DropdownLink
                        color={backgroundColor.itemColor}
                        href="https://www.mercanticshop.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={() => {
                          setDropdownMenuSelected(false);
                        }}
                        onMouseOver={() => {
                          setHoverController(item.key);
                        }}
                        key={item.key}
                        opacity={
                          item.key === hoverController ||
                          hoverController === null
                            ? "1"
                            : "0.5"
                        }
                      >
                        {item.text}
                      </DropdownLink>
                    );
                  }
                  return null;
                })}
            </div>
          </DropdownContainer>
          <DropdownFooter backgroundColor={backgroundColor.footerBackground}>
            {data.dropdown !== undefined && (
              <div className="laptop-footer-wrapper">
                <FooterParagraph
                  color={backgroundColor.footerColor}
                  onClick={() => {
                    setDropdownMenuSelected(false);
                    navigate("/event");
                  }}
                >
                  {data.dropdown.footer.footer1}
                </FooterParagraph>
                <FooterParagraph
                  color={backgroundColor.footerColor}
                  onClick={() => {
                    setDropdownMenuSelected(false);
                    navigate("/company");
                  }}
                >
                  {data.dropdown.footer.footer2}
                </FooterParagraph>
              </div>
            )}
            <div className="dropdown-languages">
              {data.dropdown !== undefined &&
                data.dropdown.languages.map((item) => {
                  return (
                    <LanguageParagraph
                      onClick={() => {
                        handleLanguageClick(item.key);
                      }}
                      key={item.key}
                      color={backgroundColor.footerColor}
                    >
                      {item.text}
                    </LanguageParagraph>
                  );
                })}
            </div>
          </DropdownFooter>
        </DropdownMenuPater>
      </div>
    );
  }
};

export default Dropdownmenu;
