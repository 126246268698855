export const espaisData = {
  catala: {
    navbar: [
      "Nau Central",
      "El Siglo",
      "Districte Antic",
      "Barri de les Casetes",
      "Espai WEM",
    ],
    initialPage: {
      section: "Zones",
      select: "Selecciona la Zona",
      title: "Tot el què passa a Mercantic té lloc en aquestes tres zones.",

      laptop: [
        { name: "Nau Central", idName: "naucentral" },
        { name: "El Siglo", idName: "elsiglo" },
        { name: "Districte Antic", idName: "districteantic" },
        { name: "Barri de les Casetes", idName: "barricasetes" },
        { name: "Espai WEM", idName: "espaiwem" },
      ],
      link: ["Selecciona la Zona"],
    },
    sections: [
      {
        title: { br1: "Nau Central" },
        key: 0,
        verticalNavbar: [
          { text: "Botigues", key: 4 },
          { text: "Roche Bobois", key: 3 },
          { text: "Escola de Restauració", key: 2 },
          { text: "La Puntual", key: 1 },
          { text: "Shop", key: 0 },
        ],
        images: [
          "naucentral01",
          "naucentral02",
          "naucentral03",
          "naucentral04",
        ],
        espais: [
          {
            key: 0,
            title: "Nau Central",
            text: "És el mercat permanent d’antiguitats conformat per mig centenar de botigues, tallers i una galeria d’art. Una xarxa entrellaçada de carrerons al voltant dels forns de l’antiga fàbrica de ceràmica. Una proposta variada i a l’abast de tothom.",
            text2:
              "De dimarts a divendres de 10h a 19, dissabtes i diumenges de 10h a 15h.",
          },
          {
            key: 1,
            title: "Shop",
            text: "La Unió de Brocanters és un espai de més de 3.000 m2 on una trentena de professionals exposen i venen mobles i objectes de totes les èpoques, estils i preus. El gènere prové de fires i mercats nacionals i internacionals i està disponible de dimarts a diumenge.",
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Botigues", key: 5 },
            ],
            images: ["launio01", "launio02", "launio03", "launio04"],
            cta: "Venda online",
          },
          {
            key: 2,
            title: "La Puntual",
            text: "El nostre centre artístic ofereix residència a joves talents que exposen la seva obra als seus tallers. L'activitat artística de La Puntual es complementa amb una galeria d'exposicions i tallers oberts.",
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Botigues", key: 5 },
            ],
            images: [
              "lapuntual01",
              "lapuntual02",
              "lapuntual03",
              "lapuntual04",
            ],
          },
          {
            key: 3,
            title: "Escola de Restauració",
            text: "L’escola i taller de restauració de mobles de Mercantic ofereix classes pràctiques i personalitzades per iniciar-se en la restauració o per aprofundir en els diferents aspectes d’aquesta feina artesanal. Les classes van a càrrec de dos professors que es complementen en les seves especialitats per a oferir un ensenyament global. Amb un mínim de teoría de la restauració tradicional, cada alumne avança al seu ritme amb un moble propi sota la tutela dels professors.",
            taller: "Els nostres tallers",
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Botigues", key: 5 },
            ],
            images: ["escola01", "escolta02", "escola03", "escola04"],
            escola: true,
          },
          {
            key: 4,
            title: "Roche Bobois",
            text: "Estem emocionats d'anunciar l'obertura del pop-up Roche Bobois al nostre recinte.",
            text2:
              "Visita'ns a Casa Amparito, on Roche Bobois, la firma francesa d'alta gama, et convida a explorar i adquirir peces úniques a preus d'outlet.",
            text3:
              "Descobreix la fusió entre el luxe contemporani i Mercantic.",
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Botigues", key: 5 },
            ],
            cta: "Contacte",
            cta2: "639 279 144",
          },

          {
            key: 5,
            title: "Botigues",
            store: true,
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Botigues", key: 5 },
            ],
            images: [
              "ncbotigues01",
              "ncbotigues02",
              "ncbotigues03",
              "ncbotigues04",
              "ncbotigues05",
              "ncbotigues06",
              "ncbotigues07",
              "ncbotigues08",
              "ncbotigues09",
              "ncbotigues10",
            ],
            table: [
              {
                key: 0,
                name: "Tocaferro",
                service: "Mobles y creació a mida",
                contact: "colladol@hotmail.com",
              },
              {
                key: 200,
                name: "Mercantic Shop",
                service: "Antiguitats",
                contact: "shop@mercantic.com",
              },
              {
                key: 1,
                name: "Juan Carlos Buendia",
                service: "Antiguitats",
                contact: "juancarlos_buendia@hotmail.com ",
              },

              {
                key: 1,
                name: "Roche Bobois",
                service: "Mobles de disseny",
                contact: "l.cardona@roche-bobois.com",
              },

              {
                key: 2,
                name: "Cambalache ",
                service: "Vaixelles antigues",
                contact: "cambalache.c.42@gmail.com",
              },
              {
                key: 3,
                name: "Lámparas ANGEL",
                service: "Lámpades antigues",
                contact: "angelalvarez1950la@gmail.com",
              },
              {
                key: 4,
                name: "Metaphora Vitralls",
                service: "Restauració de vitralls/workshops",
                contact: "metaphoravitralls@gmail.com ",
              },
              {
                key: 4,
                name: "Premium Watches BCN",
                service: "Rellotges Vintage  ",
                contact: "artecasadevall@gmail.com",
              },
              {
                key: 6,
                name: "Kus",
                service: "Antiguitats",
                contact: "kus21furniture@gmail.com",
              },
              {
                key: 6,
                name: "Luzio Store",
                service: "Mobles de Disseny",
                contact: "arantxa@luzio.es",
              },
              {
                key: 8,
                name: "Vintage Industrial",
                service: "Mobles i creació a mida",
                contact: "aarantic@gmail.com ",
              },
              {
                key: 9,
                name: "Mas B Barcelona ",
                service: "Mobles restaurats",
                contact: " jcamposmonclus@gmail.com",
              },
              {
                key: 11,
                name: "Crea Recicla Decora",
                service: "Pintures per a la llar/workshops",
                contact: "creadecorarecicla@gmail.com",
              },
              {
                key: 12,
                name: "Verde Gris",
                service: "Mobles de cuina antics i restaurats",
                contact: "info@verdegris.es",
              },
              {
                key: 12,
                name: "Bones Idees",
                service: "Antiguetats i de decoració",
                contact: "info@bonesidees.es",
              },
              {
                key: 15,
                name: "Forn d'en Francesc",
                service: "Col·leccionisme",
              },
              {
                key: 16,
                name: "Rosa Rosae",
                service: "Objectes antics",
                contact: "rosazorrilladez@hotmail.com",
              },
              {
                key: 16,
                name: "Alta fidelidad en Estereofonia",
                service: "Altaveus i tocadiscos vintage",
                contact: "salvicam@gmail.com",
              },
              {
                key: 17,
                name: "Puro Design",
                service: "Mobles i decoració",
                contact: "hellopurodc@gmail.com",
              },
              {
                key: 18,
                name: "Tapicería de Mercantic",
                service: "Tapisseria",
                contact: "elboutiay@gmail.com; ",
              },
              {
                key: 20,
                name: "Capitan Patch",
                service: "Brocanteria",
                contact: "fcantin18@gmail.com",
              },
              {
                key: 21,
                name: "Last Century Art",
                service: "Antiguitats",
                contact: "info@lastcenturyart.com",
              },
              {
                key: 22,
                name: "Escola de Restauració",
                service: "Escola de Restauració",
                contact: "restauraciomobles13@gmail.com",
              },
              {
                key: 23,
                name: "A.M.A Props",
                service: "Prop Maker",
                contact: "annamembriveart@gmail.com",
              },
              {
                key: 23,
                name: "Trastu's",
                service: "Brocanteria",
                contact: "trastus1@gmail.com",
              },
              {
                key: 25,
                name: "By Laia",
                service: "Modisteria",
                contact: "sanchezlaia@hotmail.com",
              },
              {
                key: 31,
                name: "Llums",
                service: "Lámpades",
                contact: "ifernandez@solmad.com",
              },
              {
                key: 32,
                name: "Dejando Huella",
                service: "Interiorisme y complements",
                contact: "monika.rovira@gmail.com",
              },
              {
                key: 33,
                name: "Restauracions Palau",
                service: "Restauració de mobles",
                contact: "f.vallet@hotmail.com",
              },
              {
                key: 34,
                name: "Janien van lee",
                service: "Disseny de joies",
              },
              {
                key: 36,
                name: "Rocio Maldonado",
                service: "Pintor",
                contact: "rmaldonado.art@gmail.com",
              },
              {
                key: 37,
                name: "Inés Rojo",
                service: "Pintor",
                contact: "inesrojoart@gmail.com",
              },
              {
                key: 38,
                name: "Carles Azcón",
                service: "Pintor",
                contact: "helloazcon@gmail.com",
              },
              {
                key: 39,
                name: "David Barbero",
                service: "Pintor",
                contact: "d.bardo.dom@gmail.com",
              },
              {
                key: 40,
                name: "Victor Tarrago",
                service: "Pintor",
                contact: "vitavawi@gmail.com ",
              },
              {
                key: 41,
                name: "Laura Alonso",
                service: "Pintor",
                contact: "lauraalonso27@gmail.com ",
              },
            ],
          },
        ],
      },
      {
        title: { br1: "El Siglo" },
        key: 1,
        // verticalNavbar: [
        //   { text: "Botigues", key: 2, store: true },
        //   { text: "El Siglo", key: 1 },
        //   { text: "Espai Subhasta", key: 0 },
        // ],
        images: ["elsiglo01", "elsiglo02", "elsiglo03", "elsiglo04"],
        espais: [
          {
            key: 0,
            title: "El Siglo",
            text: "Llibreria de vell amb més de 150.000 volums de totes les temàtiques i gèneres. La llibreria comparteix espai amb una sala de concerts i una altra sala de petit format, ideal per a esdeveniments literaris, artístics o de microteatre.",
            text2:
              "Horari Llibreria: Dijous de 10h a 15h, Divendres i Dissabte de 10h a 20h, Diumenge de 10h a 15h",
          },
        ],
      },
      {
        title: { br1: "Districte", br2: "Antic" },
        key: 2,
        verticalNavbar: [
          { text: "Botigues", key: 1, store: true },
          { text: "Espai Subhasta", key: 0 },
        ],
        images: [
          "districteantic01",
          "districteantic02",
          "districteantic03",
          "districteantic04",
        ],
        espais: [
          {
            key: 0,
            title: "Districte Antic",
            text: "La zona més cultural i d'oci de Mercantic. Des de la llibreria El Siglo amb concert-vermuts tots els caps de setmana al migdia, i esdeveniments al vespre, passant per la Barberia de l'Angel, la botiga de vinils o el restaurant Casa Ñidro, ideal pel vermut. Sense deixar-nos la nau d’antiguitats del Burgueño i els dissabtes de Subhasta.",
          },
          {
            key: 1,
            title: "Espai Subhasta",
            text: "El dissabte és el moment de la subhasta. Comerciants de diferents llocs arriben aquest dia per subhastar peces úniques, lots i objectes al detall. Cada dissabte a les 11:30h.",
            verticalNavbar: [
              { name: "Espai Subhasta", key: 1 },
              { name: "Botigues", key: 2 },
            ],
            images: [
              "espaisubhasta01",
              "espaisubhasta02",
              "espaisubhasta03",
              "espaisubhasta04",
            ],
          },
          {
            key: 2,
            title: "Botigues",
            verticalNavbar: [
              { name: "Espai Subhasta", key: 1 },
              { name: "Botigues", key: 2 },
            ],
            images: [
              "dabotigues01",
              "dabotigues02",
              "dabotigues03",
              "dabotigues04",
              "dabotigues05",
              "dabotigues06",
              "dabotigues07",
            ],
            table: [
              {
                key: 0,
                name: "Sanjana & Me",
                service: "Roba",
                contact: "marissa.monturiol@gmail.com",
              },
              {
                key: 0,
                name: "Mirabosc",
                service: "Mobles i creació a mida",
                contact: "mirabosc@gmail.com",
              },
              {
                key: 3,
                name: "La Caixa de Ritmes",
                service: "Vinils ",
                contact: "quiquematallin@hotmail.com",
              },
              {
                key: 4,
                name: "Omotesando",
                service: "Plantes",
                contact: "info@omotesandoplants.com",
              },
              {
                key: 5,
                name: "Te Tiro los Trastos",
                service: "Brocanteria",
                contact: "esther6446@gmail.com",
              },
            ],
          },
        ],
      },
      {
        title: { br1: "Barri de les", br2: "Casetes" },
        key: 3,
        verticalNavbar: [{ text: "Botigues", key: 0, store: true }],
        images: [
          "barricasetes01",
          "barricasetes02",
          "barricasetes03",
          "barricasetes04",
        ],
        espais: [
          {
            key: 0,
            title: "Barri de les Casetes",
            text: "El barri pintoresc de Mercantic consta de diverses casetes de colors on pots trobar roba de segona mà, antiguitats, mobles a mida, ceràmica, flors i tallers. Aquest barri també té tres restaurants: La Taverna Catalana de cuina tradicional, Can Gula de menjar de mercat de temporada i el restaurant Greta de cuina vegana.",
            text2: "De divendres a diumenge de 10h a 15h.",
          },
          {
            key: 1,
            title: "Botigues",
            images: [
              "bcbotigues01",
              "bcbotigues02",
              "bcbotigues03",
              "bcbotigues04",
              "bcbotigues05",
              "bcbotigues06",
              "bcbotigues07",
              "bcbotigues08",
              "bcbotigues09",
              "bcbotigues10",
            ],
            table: [
              {
                name: "La Mochera",
                service: "Interiorisme i complements",
                contact: "informes@lamochera.com",
              },
              {
                key: 1,
                name: "Ana Vintage",
                service: "Mobles antics",
                contact: "ana.vintagemarket@gmail.com",
              },
              {
                key: 30,
                name: "The Knots Lab",
                service: "Llanes i tapissos/Workshops",
                contact: "hola@theknotslab.com",
              },
              {
                key: 3,
                name: "Rekup",
                service: "Mobles i creació a mida",
                contact: "ewagnon@hotmail.com",
              },
              {
                key: 3,
                name: "La Camu",
                service: "Roba Vintage i de Segona mà",
                contact: "Car_vi_78@hotmail.com",
              },
              {
                key: 4,
                name: "Kus Furniture",
                service: "Mobles",
                contact: "kus21furniture@gmail.com",
              },
              {
                key: 4,
                name: "Shuia shuia",
                service: "Sabates artesanes",
                contact: "info@shuiashuia.com",
              },
              {
                key: 5,
                name: "Terra Calenta",
                service: "Levi's de segona mà",
                contact: "terracalenta@gmail.com",
              },
              {
                key: 6,
                name: "Temps de Jocs",
                service: "Jocs Antics",
                contact: "terracalenta@gmail.com",
              },
              {
                key: 7,
                name: "Nomada",
                service: "Workshops",
                contact: "lyli@nomadaespai.com",
              },
              {
                key: 8,
                name: "Pepitablanca",
                service: "Interiorisme",
                contact: "hello@pepitablanca.com",
              },
              {
                key: 9,
                name: "Su Jardin Secreto",
                service: "Floristeria",
                contact: "info@sugarden.es",
              },
              {
                key: 9,
                name: "Robots",
                service: "Reparacions d’automates",
                contact: "mobimatinfo@gmail.com",
              },
              {
                key: 10,
                name: "La Casa Azul",
                service: "Brocanteria",
                contact: "fredericcasaazul@gmail.com",
              },
              {
                key: 10,
                name: "Tapisseria de Mercantic",
                service: "Mobles tapissats",
                contact: "elboutiay@gmail.com",
              },
              {
                key: 12,
                name: "Retro and Deco",
                service: "Brocanteria",
                contact: "gloriasarte@hotmail.com",
              },
              {
                key: 15,
                name: "Merm Studio",
                service: "Ceràmica/Workshops",
                contact: "imalbalopezmar@gmail.com",
              },
            ],
          },
        ],
      },
      {
        title: { br1: "Espai", br2: "WEM" },
        // verticalNavbar: [{ text: "Dossier Informatiu", key: 0 }],
        key: 4,
        espais: [
          {
            key: 0,
            title: "Espai Wem",
            text: "És un lloc de trobada per emprenedors/es. Oferim el lloguer d'aquest espai per a creatius, artistes, empresaris amb reptes i projectes a desenvolupar. És un espai on sorgeixen col·laboracions i sinergies amb l'objectiu d'estimular la producció artística i la cultura. Estem sempre a la recerca de nous perfils i és per això que us animem a posar-vos en contacte amb nosaltres: comunicacio@mercantic.com",
            dossier: "Dossier Informatiu",
          },
          {
            key: 1,
            title: "Espai Wem",
            text: "És un lloc de trobada per emprenedors/es. Oferim el lloguer d'aquest espai per a creatius, artistes, empresaris amb reptes i projectes a desenvolupar. És un espai on sorgeixen col·laboracions i sinergies amb l'objectiu d'estimular la producció artística i la cultura. Estem sempre a la recerca de nous perfils i és per això que us animem a posar-vos en contacte amb nosaltres: comunicacio@mercantic.com",
            dossier: "Dossier Informatiu",
          },
        ],

        // dossier: "Dossier Informatiu",
      },
    ],
  },
  castella: {
    navbar: [
      "Nau Central",
      "Districte Antic",
      "Barri de les Casetes",
      "Espacio WEM",
    ],
    initialPage: {
      section: "Zonas",
      select: "Selecciona la Zona",
      title: "Todo lo que sucede en Mercantic tiene lugar en estas tres zonas.",
      laptop: [
        { name: "Nau Central", idName: "naucentral" },
        { name: "El Siglo", idName: "elsiglo" },
        { name: "Districte Antic", idName: "districteantic" },
        { name: "Barri de les Casetes", idName: "barricasetes" },
        { name: "Espai WEM", idName: "espaiwem" },
      ],
      link: ["Selecciona la Zona"],
    },
    sections: [
      {
        title: { br1: "Nau Central" },
        key: 0,
        verticalNavbar: [
          { text: "Tiendas", key: 4, store: true },
          { text: "Roche Bobois", key: 3 },
          { text: "Escuela de Restauración", key: 2 },
          { text: "La Puntual", key: 1 },
          { text: "Shop", key: 0 },
        ],
        espais: [
          {
            key: 0,
            title: "Nau Central",
            text: "Es el mercado permanente de antigüedades conformado por medio centenar de tiendas, talleres y una galería de arte. Una red entrelazada de callejuelas alrededor de los hornos de la antigua fábrica de cerámica. Una propuesta variada y accesible para todos.",
            text2:
              "De martes a viernes de 10h a 19h y sábados y domingos de 10h a 15h.",
          },
          {
            key: 1,
            title: "Shop",
            text: "La Unió de Brocantes es un espacio de más de 3.000 m2 donde una treintena de profesionales exponen y venden muebles y objetos de todas las épocas, estilos y precios. El género proviene de ferias y mercados nacionales e internacionales y está disponible de martes a domingo.",
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Tiendas", key: 5 },
            ],
            cta: "Venta online",
          },
          {
            key: 2,
            title: "La Puntual",
            text: "Nuestro centro artístico ofrece residencia a jóvenes talentos que exhiben su obra en sus talleres. La actividad artística de La Puntual se complementa con una galería de exposiciones y talleres abiertos.",
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Tiendas", key: 5 },
            ],
          },
          {
            key: 3,
            title: "Escuela de Restauración",
            text: "La escuela y taller de restauración de muebles de Mercantic ofrece clases prácticas y personalizadas para iniciarse en la restauración o profundizar en los diferentes aspectos de este trabajo artesanal. Las clases son impartidas por dos profesores que se complementan en sus especialidades para ofrecer una enseñanza integral. Con un mínimo de teoría de la restauración tradicional, cada alumno avanza a su propio ritmo con un mueble propio bajo la tutela de los profesores.",
            taller: "Els nostres tallers",
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Tiendas", key: 5 },
            ],
            escola: true,
          },
          {
            key: 4,
            title: "Roche Bobois",
            text: "Estamos emocionados de anunciar la apertura de la pop-up Roche Bobois en nuestro recinto.",
            text2:
              "Visítanos en Casa Amparito, donde Roche Bobois, la firma francesa de alta gama, te invita a explorar y adquirir piezas únicas a precios de outlet.",
            text3:
              "Descubre la fusión entre el lujo contemporáneo y el Mercantic!",
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Tiendas", key: 5 },
            ],
            cta: "Contacto",
            cta2: "639 279 144",
          },
          {
            key: 5,
            title: "Tiendas",
            store: true,
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Tiendas", key: 5 },
            ],
            table: [
              {
                key: 0,
                name: "Mercantic Shop",
                service: "Antigüedades",
                contact: "shop@mercantic.com",
              },
              {
                key: 0,
                name: "Tocaferro",
                service: "Muebles y creación a medida",
                contact: "colladol@hotmail.com",
              },
              {
                key: 1,
                name: "Juan Carlos Buendia",
                service: "Antigüedades",
                contact: "juancarlos_buendia@hotmail.com ",
              },
              {
                key: 1,
                name: "Roche Bobois",
                service: "Muebles de diseño",
                contact: "l.cardona@roche-bobois.com",
              },
              {
                key: 1,
                name: "Luzio Store",
                service: "Muebles de Diseño",
                contact: "arantxa@luzio.es",
              },
              {
                key: 2,
                name: "Cambalache ",
                service: "Vajillas antiguas",
                contact: "cambalache.c.42@gmail.com",
              },
              {
                key: 3,
                name: "Lámparas ANGEL",
                service: "Lámparas antiguas",
                contact: "angelalvarez1950la@gmail.com",
              },
              {
                key: 3,
                name: "Premium Watches BCN",
                service: "Relojes vintage",
                contact: "artecasadevall@gmail.com",
              },
              {
                key: 4,
                name: "Metaphora Vitralls",
                service: "Restauración de vidrieras/workshops",
                contact: "metaphoravitralls@gmail.com ",
              },
              {
                key: 6,
                name: "Kus",
                service: "Antigüedades",
                contact: "kus21furniture@gmail.com",
              },
              {
                key: 8,
                name: "Vintage Industrial",
                service: "Muebles y creación a medida",
                contact: "aarantic@gmail.com ",
              },
              {
                key: 9,
                name: "Mas B Barcelona ",
                service: "Muebles restaurados",
                contact: " jcamposmonclus@gmail.com",
              },
              {
                key: 11,
                name: "Crea Recicla Decora",
                service: "Pinturas para el hogar/workshops",
                contact: "creadecorarecicla@gmail.com",
              },
              {
                key: 11,
                name: "Bones Idees",
                service: "Antigüedades y de decoración",
                contact: "info@bonesidees.es",
              },
              {
                key: 12,
                name: "Verde Gris",
                service: "Muebles de cocina antiguos y restaurados",
                contact: "info@verdegris.es",
              },
              {
                key: 15,
                name: "Alta fidelidad en Estereofonia",
                service: "Altavoces y tocadiscos vintage",
                contact: "salvicam@gmail.com",
              },
              {
                key: 15,
                name: "Forn d'en Francesc",
                service: "Coleccionismo",
              },
              {
                key: 16,
                name: "Rosa Rosae",
                service: "Objetos antiguos",
                contact: "rosazorrilladez@hotmail.com",
              },
              {
                key: 17,
                name: "Puro Design",
                service: "Muebles y decoración",
                contact: "hellopurodc@gmail.com",
              },
              {
                key: 18,
                name: "Tapicería de Mercantic",
                service: "Tapicería",
                contact: "elboutiay@gmail.com; ",
              },
              {
                key: 20,
                name: "Capitan Patch",
                service: "Brocantería",
                contact: "fcantin18@gmail.com",
              },
              {
                key: 20,
                name: "Trastu’s",
                service: "Brocantería",
                contact: "trastus1@gmail.com",
              },
              {
                key: 21,
                name: "Last Century Art",
                service: "Antigüedades",
                contact: "info@lastcenturyart.com",
              },
              {
                key: 22,
                name: "Escola de Restauració",
                service: "Escuela de Restauración",
                contact: "restauraciomobles13@gmail.com",
              },
              {
                key: 23,
                name: "A.M.A Props",
                service: "Prop Maker",
                contact: "annamembriveart@gmail.com",
              },
              {
                key: 25,
                name: "By Laia",
                service: "Modistería",
                contact: "sanchezlaia@hotmail.com",
              },
              {
                key: 31,
                name: "Llums",
                service: "Lámparas",
                contact: "ifernandez@solmad.com",
              },
              {
                key: 32,
                name: "Dejando Huella",
                service: "Interiorismo y complementos",
                contact: "monika.rovira@gmail.com",
              },
              {
                key: 33,
                name: "Restauracions Palau",
                service: "Restauración de muebles",
                contact: "f.vallet@hotmail.com",
              },
              {
                key: 34,
                name: "Janien van lee",
                service: "Diseño de joyas",
              },

              {
                key: 36,
                name: "Rocio Maldonado",
                service: "Pintor",
                contact: "rmaldonado.art@gmail.com",
              },
              {
                key: 37,
                name: "Inés Rojo",
                service: "Pintor",
                contact: "inesrojoart@gmail.com",
              },
              {
                key: 38,
                name: "Carles Azcón",
                service: "Pintor",
                contact: "helloazcon@gmail.com",
              },
              {
                key: 39,
                name: "David Barbero",
                service: "Pintor",
                contact: "d.bardo.dom@gmail.com",
              },
              {
                key: 40,
                name: "Victor Tarrago",
                service: "Pintor",
                contact: "vitavawi@gmail.com ",
              },
              {
                key: 41,
                name: "Laura Alonso",
                service: "Pintor",
                contact: "lauraalonso27@gmail.com ",
              },
            ],
          },
        ],
      },
      {
        title: { br1: "El Siglo" },
        key: 1,
        // verticalNavbar: [
        //   { text: "Botigues", key: 2, store: true },
        //   { text: "El Siglo", key: 1 },
        //   { text: "Espai Subhasta", key: 0 },
        // ],
        images: ["elsiglo01", "elsiglo02", "elsiglo03", "elsiglo04"],
        espais: [
          {
            key: 0,
            title: "El Siglo",
            text: "Librería de viejo con más de 150.000 volúmenes de todas las temáticas y géneros. La librería comparte espacio con una sala de conciertos y otra sala de formato pequeño, ideal para eventos literarios, artísticos o de microteatro.",
            text2:
              "Horario Libreria: Jueves de 10h a 15h, Viernes y Sábado de 10h a 20h, Domingo de 10h a 15h",
          },
        ],
      },
      {
        title: { br1: "Districte", br2: "Antic" },
        key: 2,
        verticalNavbar: [
          { text: "Tiendas", key: 1, store: true },
          { text: "Espacio Subhasta", key: 0 },
        ],

        espais: [
          {
            key: 0,
            title: "Districte Antic",
            text: "La zona más cultural y de ocio de Mercantic. Desde la librería El Siglo con conciertos-vermut todos los fines de semana al mediodía, y eventos por la noche, pasando por la Barbería de Angel, la tienda de vinilos o el restaurante Casa Ñidro, ideal para picar algo. Sin olvidar la nave de antigüedades de Burgueño y los sábados de subasta.",
          },
          {
            key: 1,
            title: "Espacio Subhasta",
            text: "El sábado es el momento de la subasta. Comerciantes de diferentes lugares llegan ese día para subastar piezas únicas, lotes y objetos al detalle. Cada sábado a las 11:30h.",
            verticalNavbar: [
              { name: "Espacio Subhasta", key: 1 },
              { name: "Tiendas", key: 2, store: true },
            ],
          },
          {
            key: 2,
            title: "Tiendas",
            verticalNavbar: [
              { name: "Espacio Subhasta", key: 1 },
              { name: "Tiendas", key: 2, store: true },
            ],
            table: [
              {
                key: 0,
                name: "Sanjana & Me",
                service: "Ropa",
                contact: "marissa.monturiol@gmail.com",
              },
              {
                key: 0,
                name: "Mirabosc",
                service: "Muebles y creación a medida",
                contact: "mirabosc@gmail.com",
              },
              {
                key: 3,
                name: "La Caixa de Ritmes",
                service: "Vinilos",
                contact: "quiquematallin@hotmail.com",
              },
              {
                key: 4,
                name: "Omotesando",
                service: "Plantas",
                contact: "info@omotesandoplants.com",
              },
              {
                key: 5,
                name: "Te Tiro los Trastos",
                service: "Brocantería",
                contact: "esther6446@gmail.com",
              },
            ],
          },
        ],
      },
      {
        title: { br1: "Barrio de les", br2: "Casetes" },
        key: 2,
        verticalNavbar: [{ text: "Tiendas", key: 0, store: true }],

        espais: [
          {
            key: 0,
            title: "Barri de les Casetes",
            text: "El pintoresco barrio de Mercantic consta de varias casitas de colores donde puedes encontrar ropa de segunda mano, antigüedades, muebles a medida, cerámica, flores y talleres. Este barrio también cuenta con tres restaurantes: La Taverna Catalana de cocina tradicional, Can Gula de comida de mercado de temporada y el restaurante Greta de cocina vegana.",
            text2: "De viernes a domingo de 10h a 15h.",
          },
          {
            key: 1,
            title: "Tiendas",
            store: true,
            // verticalNavbar: [{ name: "Tiendas", key: 0 }],
            table: [
              {
                name: "La Mochera",
                service: "Interiorismo y complementos",
                contact: "informes@lamochera.com",
              },
              {
                key: 30,
                name: "The Knots Lab",
                service: "Lanas y tapices/Workshops",
                contact: "hola@theknotslab.com",
              },
              {
                key: 1,
                name: "Ana Vintage",
                service: "Muebles antiguos",
                contact: "ana.vintagemarket@gmail.com",
              },
              {
                key: 1,
                name: "La Camu",
                service: "Ropa vintage y de segunda mano",
                contact: "Car_vi_78@hotmail.com",
              },
              {
                key: 3,
                name: "Rekup",
                service: "Muebles y creación a medida",
                contact: "ewagnon@hotmail.com",
              },
              {
                key: 3,
                name: "Shuia shuia",
                service: "Zapatos artesanos",
                contact: "info@shuiashuia.com",
              },
              {
                key: 4,
                name: "Kus Furniture",
                service: "Muebles",
                contact: "kus21furniture@gmail.com",
              },
              {
                key: 5,
                name: "Terra Calenta",
                service: "Levi's de segunda mano",
                contact: "terracalenta@gmail.com",
              },
              {
                key: 6,
                name: "Temps de Jocs",
                service: "Juegos Antiguos",
                contact: "terracalenta@gmail.com",
              },
              {
                key: 7,
                name: "Nomada",
                service: "Workshops",
                contact: "lyli@nomadaespai.com",
              },
              {
                key: 8,
                name: "Pepitablanca",
                service: "Interiorismo",
                contact: "hello@pepitablanca.com",
              },
              {
                key: 9,
                name: "Su Jardin Secreto",
                service: "Floristería",
                contact: "info@sugarden.es",
              },
              {
                key: 10,
                name: "La Casa Azul",
                service: "Brocantería",
                contact: "fredericcasaazul@gmail.com",
              },
              {
                key: 12,
                name: "Retro and Deco",
                service: "Brocantería",
                contact: "gloriasarte@hotmail.com",
              },
              {
                key: 12,
                name: "Robots",
                service: "Reparaciones de autómatas",
                contact: "mobimatinfo@gmail.com",
              },
              {
                key: 12,
                name: "Tapisseria de Mercantic",
                service: "Muebles tapizados",
                contact: "elboutiay@gmail.com",
              },
              {
                key: 15,
                name: "Merm Studio",
                service: "Cerámica/Workshops",
                contact: "imalbalopezmar@gmail.com",
              },
            ],
          },
        ],

        botigues: "",
      },
      {
        title: { br1: "Espacio", br2: "WEM" },
        // verticalNavbar: [{ text: "Dossier Informativo", key: 0 }],
        key: 3,
        espais: [
          {
            key: 0,
            title: "Espacio Wem",
            text: "Es un lugar de encuentro para emprendedores/as. Ofrecemos el alquiler de este espacio para creativos, artistas y empresarios con desafíos y proyectos por desarrollar. Es un espacio donde surgen colaboraciones y sinergias con el objetivo de estimular la producción artística y la cultura. Siempre estamos buscando nuevos perfiles y por eso os animamos a ponerse en contacto con nosotros: comunicacio@mercantic.com",
            dossier: "Dossier Informatiu",
          },
          {
            key: 1,
            title: "Espacio Wem",
            text: "Es un lugar de encuentro para emprendedores/as. Ofrecemos el alquiler de este espacio para creativos, artistas y empresarios con desafíos y proyectos por desarrollar. Es un espacio donde surgen colaboraciones y sinergias con el objetivo de estimular la producción artística y la cultura. Siempre estamos buscando nuevos perfiles y por eso os animamos a ponerse en contacto con nosotros: comunicacio@mercantic.com",
            dossier: "Dossier Informatiu",
          },
        ],

        // dossier: "Dossier Informativo",
      },
    ],
  },
  angles: {
    navbar: [
      "Nau Central",
      "Districte Antic",
      "Barri de les Casetes",
      "Nau WEM",
    ],
    initialPage: {
      section: "Zones",
      select: "Select the Zone",
      title:
        "Everything that happens in Mercantic takes place in these three zones.",

      laptop: [
        { name: "Nau Central", idName: "naucentral" },
        { name: "El Siglo", idName: "elsiglo" },
        { name: "Districte Antic", idName: "districteantic" },
        { name: "Barri de les Casetes", idName: "barricasetes" },
        { name: "Nau WEM", idName: "espaiwem" },
      ],
      link: ["Select the Zone"],
    },
    sections: [
      {
        title: { br1: "Nau Central" },
        key: 0,
        verticalNavbar: [
          { text: "Shops", key: 4, store: true },
          { text: "Roche Bobois", key: 3 },
          { text: "Restoration School", key: 2 },
          { text: "La Puntual", key: 1 },
          { text: "Shop", key: 0 },
        ],
        espais: [
          {
            key: 0,
            title: "Nau Central",
            text: "The permanent antique market is comprised of fifty shops, workshops, and an art gallery, which fill the alleys surrounding the former ovens of the ceramics factory. A varied proposal accessible to everyone.",
            text2:
              " From Tuesday to Friday from 10 am to 7 pm, Saturdays and Sundays from 10 am to 3 pm.",
          },
          {
            key: 1,
            title: "Shop",
            text: "La Unió de Brocanters is an exhibition space of over 3,000 m2 where approximately thirty professionals sell local and international furniture and objects from different eras, styles, and prices. Doors are open from Tuesday to Sunday",
            verticalNavbar: [
              {
                name: "Shop",
                key: 1,
              },
              { name: "La Puntual", key: 2 },
              { name: "Restoration School", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Shops", key: 5, store: true },
            ],
            cta: "Online sale",
          },
          {
            key: 2,
            title: "La Puntual",
            text: "Our art center provides residency to young talents who exhibit their work in their studios. The artistic activity of La Puntual is complemented by an exhibition gallery and open workshops.",
            verticalNavbar: [
              {
                name: "Shop",
                key: 1,
              },
              { name: "La Puntual", key: 2 },
              { name: "Restoration School", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Shops", key: 5, store: true },
            ],
          },
          {
            key: 3,
            title: "Restoration School",
            text: "The furniture restoration school and workshop at Mercantic offers practical and personalised classes to get students started in the craft of restoration or to deepen their knowledge in the subject. The classes are taught by two professors who combine their expertise in order to cover an extensive curriculum. After an initial instruction in traditional restoration theory, each student can progresses at their own pace with their own piece of furniture.",
            taller: "Our workshops",
            escola: true,
            verticalNavbar: [
              {
                name: "Shop",
                key: 1,
              },
              { name: "La Puntual", key: 2 },
              { name: "Restoration School", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Shops", key: 5, store: true },
            ],
          },
          {
            key: 4,
            title: "Roche Bobois",
            text: "We are excited to announce the opening of the pop-up store Roche Bobois on our premises.",
            text2:
              "Visit us at Casa Amparito, where Roche Bobois, the French high-end brand, invites you to explore and acquire unique pieces at outlet prices.",
            text3:
              "Discover the fusion between contemporary luxury and Mercantic!",
            verticalNavbar: [
              { name: "Shop", key: 1 },
              { name: "La Puntual", key: 2 },
              { name: "Escola de Restauració", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Tiendas", key: 5 },
            ],
            cta: "Contact",
            cta2: "639 279 144",
          },
          {
            key: 4,
            title: "Shops",
            store: true,
            verticalNavbar: [
              {
                name: "Shop",
                key: 1,
              },
              { name: "La Puntual", key: 2 },
              { name: "Restoration School", key: 3 },
              { name: "Roche Bobois", key: 4 },
              { name: "Shops", key: 5, store: true },
            ],
            table: [
              {
                key: 0,
                name: "Mercantic Shop",
                service: "Antiques",
                contact: "shop@mercantic.com",
              },
              {
                key: 0,
                name: "Tocaferro",
                service: "Furniture and Bespoke Design",
                contact: "colladol@hotmail.com",
              },
              {
                key: 1,
                name: "Juan Carlos Buendia",
                service: "Antiques",
                contact: "juancarlos_buendia@hotmail.com ",
              },
              {
                key: 1,
                name: "Roche Bobois",
                service: "Design Furniture",
                contact: "l.cardona@roche-bobois.com",
              },
              {
                key: 2,
                name: "Cambalache ",
                service: "Antique Crockery",
                contact: "cambalache.c.42@gmail.com",
              },
              {
                key: 3,
                name: "Lámparas ANGEL",
                service: "Antique Lighting",
                contact: "angelalvarez1950la@gmail.com",
              },
              {
                key: 3,
                name: "Luzio Store",
                service: "Design Furniture",
                contact: "arantxa@luzio.es",
              },
              {
                key: 4,
                name: "Premium Watches BCN",
                service: "Vintage watches",
                contact: "artecasadevall@gmail.com",
              },
              {
                key: 4,
                name: "Metaphora Vitralls",
                service: "Stained Glass Restoration and Workshops",
                contact: "metaphoravitralls@gmail.com ",
              },
              {
                key: 6,
                name: "Kus",
                service: "Antiques",
                contact: "kus21furniture@gmail.com",
              },
              {
                key: 8,
                name: "Vintage Industrial",
                service: "Furniture and Bespoke Design",
                contact: "aarantic@gmail.com ",
              },
              {
                key: 9,
                name: "Mas B Barcelona ",
                service: "Furniture Restoration",
                contact: " jcamposmonclus@gmail.com",
              },
              {
                key: 11,
                name: "Crea Recicla Decora",
                service: "Art and Workshops",
                contact: "creadecorarecicla@gmail.com",
              },
              {
                key: 12,
                name: "Verde Gris",
                service: "Antique Kitchen Furniture",
                contact: "info@verdegris.es",
              },
              {
                key: 12,
                name: "Bones Idees",
                service: "Antiques",
                contact: "info@bonesidees.es",
              },
              {
                key: 15,
                name: "Forn d'en Francesc",
                service: "Collector Goods",
              },
              {
                key: 16,
                name: "Rosa Rosae",
                service: "Antiques",
                contact: "rosazorrilladez@hotmail.com",
              },
              {
                key: 17,
                name: "Puro Design",
                service: "Furniture and Decoration",
                contact: "hellopurodc@gmail.com",
              },
              {
                key: 17,
                name: "Alta fidelidad en Estereofonia",
                service: "Vintage speakers and record players",
                contact: "salvicam@gmail.com",
              },
              {
                key: 18,
                name: "Tapicería de Mercantic",
                service: "Upholstery",
                contact: "elboutiay@gmail.com; ",
              },
              {
                key: 20,
                name: "Capitan Patch",
                service: "Second Hand Market",
                contact: "fcantin18@gmail.com",
              },
              {
                key: 21,
                name: "Last Century Art",
                service: "Antiques",
                contact: "info@lastcenturyart.com",
              },
              {
                key: 22,
                name: "Escola de Restauració",
                service: "Restoration School",
                contact: "restauraciomobles13@gmail.com",
              },
              {
                key: 23,
                name: "A.M.A Props",
                service: "Prop Maker",
                contact: "annamembriveart@gmail.com",
              },
              {
                key: 23,
                name: "Trastu’s",
                service: "Brochantry",
                contact: "trastus1@gmail.com",
              },
              {
                key: 25,
                name: "By Laia",
                service: "Hatmaking",
                contact: "sanchezlaia@hotmail.com",
              },
              {
                key: 31,
                name: "Llums",
                service: "Lighting",
                contact: "ifernandez@solmad.com",
              },
              {
                key: 32,
                name: "Dejando Huella",
                service: "Interior Design and Accessories",
                contact: "monika.rovira@gmail.com",
              },
              {
                key: 33,
                name: "Restauracions Palau",
                service: "Furniture Restoration",
                contact: "f.vallet@hotmail.com",
              },
              {
                key: 34,
                name: "Janien van lee",
                service: "Jewelry design",
              },

              {
                key: 36,
                name: "Rocio Maldonado",
                service: "Painter",
                contact: "rmaldonado.art@gmail.com",
              },
              {
                key: 37,
                name: "Inés Rojo",
                service: "Painter",
                contact: "inesrojoart@gmail.com",
              },
              {
                key: 38,
                name: "Carles Azcón",
                service: "Painter",
                contact: "helloazcon@gmail.com",
              },
              {
                key: 39,
                name: "David Barbero",
                service: "Painter",
                contact: "d.bardo.dom@gmail.com",
              },
              {
                key: 40,
                name: "Victor Tarrago",
                service: "Painter",
                contact: "vitavawi@gmail.com ",
              },
              {
                key: 41,
                name: "Laura Alonso",
                service: "Painter",
                contact: "lauraalonso27@gmail.com ",
              },
            ],
          },
        ],
      },
      {
        title: { br1: "El Siglo" },
        key: 1,
        // verticalNavbar: [
        //   { text: "Botigues", key: 2, store: true },
        //   { text: "El Siglo", key: 1 },
        //   { text: "Espai Subhasta", key: 0 },
        // ],
        images: ["elsiglo01", "elsiglo02", "elsiglo03", "elsiglo04"],
        espais: [
          {
            key: 0,
            title: "El Siglo",
            text: "A secondhand bookstore with over 150,000 volumes covering all themes and genres. The bookstore shares a space with two venues of different sizes, suited to literary, artistic, or microtheater events.",
            text2:
              "Library: Thursday from 10 am to 3 pm. Fridays and Saturdays from 10am to 8 pm. Sunday from 10 am to 3 pm",
          },
        ],
      },
      {
        title: { br1: "Districte", br2: "Antic" },
        key: 2,
        verticalNavbar: [
          { text: "Shops", key: 1, store: true },
          { text: "Auction Space", key: 0 },
        ],
        espais: [
          {
            key: 0,
            title: "Districte Antic",
            text: "The cultural and leisure heart of Mercantic. From the El Siglo bookstore with vermouth and live music every weekend to the Barberia de l'Angel, the vinyl store, and the Casa Ñidro restaurant, Unmissable is the Burgueño's old hall and the Saturday auction.",
          },

          {
            key: 1,
            title: "Auction Space",
            text: "The auction takes place every Saturday at 11:30 a.m. Traders from different places come on this day to auction unique pieces, lots, and individual items. Every Saturday at 11:30 a.m.",
            verticalNavbar: [
              { name: "Auction Space", key: 1 },

              { name: "Shops", key: 2, store: true },
            ],
          },
          {
            key: 2,
            title: "Shops",
            verticalNavbar: [
              { name: "Auction Space", key: 1 },
              { name: "Shops", key: 2, store: true },
            ],
            store: true,
            table: [
              {
                key: 0,
                name: "Sanjana & Me",
                service: "Clothes",
                contact: "marissa.monturiol@gmail.com",
              },
              {
                key: 0,
                name: "Mirabosc",
                service: "Furniture and Bespoke Design",
                contact: "mirabosc@gmail.com",
              },
              {
                key: 3,
                name: "La Caixa de Ritmes",
                service: "Vinyl Records",
                contact: "quiquematallin@hotmail.com",
              },
              {
                key: 4,
                name: "Omotesando",
                service: "Plants",
                contact: "info@omotesandoplants.com",
              },
              {
                key: 5,
                name: "Te Tiro los Trastos",
                service: "Second Hand Market",
                contact: "esther6446@gmail.com",
              },
            ],
          },
        ],
      },
      {
        title: { br1: "Barri de les Casetes" },
        key: 2,
        verticalNavbar: [{ text: "Shops", key: 0, store: true }],

        espais: [
          {
            key: 0,
            title: "Barri de les Casetes",
            text: "The picturesque neighborhood of Mercantic consists of various colourful houses where you can find second-hand clothes, antiques, custom furniture, ceramics, flowers, and workshops. Casetes has three restaurants: La Taverna Catalana with traditional cuisine, Can Gula with seasonal market food, and the Greta restaurant with vegan cuisine.",
            text2: "Doors are open from Friday to Sunday from 10 am to 3 pm",
          },
          {
            key: 1,
            title: "Shops",
            store: true,
            // verticalNavbar: [{ name: "Shops", key: 0 }],
            table: [
              {
                name: "La Mochera",
                service: "Interiorism and complements",
                contact: "informes@lamochera.com",
              },
              {
                key: 30,
                name: "The Knots Lab",
                service: "Rugs and Tapestries / Workshops",
                contact: "hola@theknotslab.com",
              },
              {
                key: 1,
                name: "Ana Vintage",
                service: "Antique Furniture",
                contact: "ana.vintagemarket@gmail.com",
              },
              {
                key: 2,
                name: "La Camu",
                service: "Vintage and second hand clothes",
                contact: "Car_vi_78@hotmail.com",
              },
              {
                key: 3,
                name: "Rekup",
                service: "Furniture and Bespoke Design",
                contact: "ewagnon@hotmail.com",
              },
              {
                key: 3,
                name: "Shuia shuia",
                service: "Artesan shoes",
                contact: "info@shuiashuia.com",
              },
              {
                key: 4,
                name: "Kus Furniture",
                service: "Furniture",
                contact: "kus21furniture@gmail.com",
              },
              {
                key: 5,
                name: "Terra Calenta",
                service: "Second-hand Levi Trousers",
                contact: "terracalenta@gmail.com",
              },
              {
                key: 6,
                name: "Temps de Jocs",
                service: "Vintage Games",
                contact: "terracalenta@gmail.com",
              },
              {
                key: 7,
                name: "Nomada",
                service: "Workshops",
                contact: "lyli@nomadaespai.com",
              },
              {
                key: 8,
                name: "Pepitablanca",
                service: "Interior design",
                contact: "hello@pepitablanca.com",
              },
              {
                key: 9,
                name: "Su Jardin Secreto",
                service: "Florist",
                contact: "info@sugarden.es",
              },
              {
                key: 10,
                name: "La Casa Azul",
                service: "Second Hand Market",
                contact: "fredericcasaazul@gmail.com",
              },
              {
                key: 12,
                name: "Retro and Deco",
                service: "Second Hand Market",
                contact: "gloriasarte@hotmail.com",
              },
              {
                key: 13,
                name: "Robots",
                service: "Automaton repairs",
                contact: "mobimatinfo@gmail.com",
              },
              {
                key: 13,
                name: "Tapisseria de Mercantic",
                service: "Upholstered furniture",
                contact: "elboutiay@gmail.com",
              },
              {
                key: 15,
                name: "Merm Studio",
                service: "Ceramics/Workshops",
                contact: "imalbalopezmar@gmail.com",
              },
            ],
          },
        ],
      },
      {
        title: { br1: "Nau", br2: "WEM" },
        // verticalNavbar: [{ text: "More Information", key: 0 }],
        key: 3,
        espais: [
          {
            key: 0,
            title: "Nau WEM",
            text: "A meeting place for entrepreneurs. We offer this space to creatives, artists, entrepreneurs who wish to develop their projects. The space promotes collaboration and synergy between artists. We are always looking for new profiles, so we encourage you to contact us: comunicacio@mercantic.com",
            // dossier: "More information",
          },
          {
            key: 1,
            title: "Nau WEM",
            text: "A meeting place for entrepreneurs. We offer this space to creatives, artists, entrepreneurs who wish to develop their projects. The space promotes collaboration and synergy between artists. We are always looking for new profiles, so we encourage you to contact us: comunicacio@mercantic.com",
            // dossier: "More information",
          },
        ],

        // dossier: "More information",
      },
    ],
  },
};
