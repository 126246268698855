import styled from "styled-components";

export const Container = styled.div`
  height: 80vh;
  background-color: var(--skyblue);
  position: relative;
`;

export const Card = styled.div`
  padding-top: ${(props) => props.paddingTop};
  min-width: 100%;
  height: calc(100% - 2rem);
  background-color: ${(props) => props.background};
`;

export const Title = styled.p`
  font-size: 38px;
  margin-top: 0;
  margin-bottom: ${(props) => props.marginBottom};
  color: ${(props) => props.color};
  text-align: center;
  line-height: 110%;
  letter-spacing: -0.005em;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.color};
  padding-left: 40px;
  padding-right: 40px;
`;
