import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Newsletter from "./Newsletter";
const Mailchimp = () => {
  const public_mailchimp_url =
    "https://mercantic.us21.list-manage.com/subscribe/post?u=628977ecd577f99bb9f2d7d17&amp;id=1199f6ef38&amp;f_id=00ccb0e1f0";

  return (
    <MailchimpSubscribe
      url={public_mailchimp_url}
      render={({ subscribe, status, message }) => (
        <Newsletter
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  );
};

export default Mailchimp;
