import styled from "styled-components";

export const DropdownMenuPater = styled.div`
  height: 100svh;
  width: 100%;
  min-height: -webkit-fill-available;
  position: fixed;
  z-index: 1000;
  overflow: hidden;
  background-color: ${(props) => props.color};
  @media only screen and (min-width: 700px) {
    height: 100vh;
    background: url(${(props) => props.backgroundImage}) no-repeat center center
      fixed;
    background-size: ${(props) => props.backgroundSize};
    display: flex;

    flex-direction: column;
  }
`;

export const FirstSquaredRow = styled.div`
  height: 15%;
  width: 100%;
`;
export const SecondSquaredRow = styled.div`
  height: 15%;
  width: 100%;
  display: flex;
`;

export const FirstSquareFirstSquaredRow = styled.div`
  width: calc(100% - (100% / 3));
  height: 100%;
  background-color: ${(props) => props.color};
`;
export const FirstSquareSecondSquaredRow = styled.div`
  width: calc(100% - (100% / 3));
  height: 100%;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: flex-end;
`;

export const SecondSquareSecondSquaredRow = styled.div`
  width: calc(100% / 3);
  height: 100%;
  background-color: ${(props) => props.color};
`;

export const DropdownWrapper = styled.div`
  background-size: cover;
  height: 87%;
  width: 100%;
`;

export const DropdownContainer = styled.div`
  height: 100%;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;

  @media only screen and (min-width: 700px) {
    height: 100%;
    padding-top: 0;
    background-image: none;
  }
`;

export const ItemParagraph = styled.p`
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  font-size: 38px;
  line-height: 49px;
  margin: 0;

  :hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 700px) {
    font-size: 60px;
    width: 100%;
    text-align: center;
  }
`;
export const LanguageParagraph = styled.p`
  color: ${(props) => props.color};
  margin-top: 3rem;
`;
export const FooterParagraph = styled.p`
  color: ${(props) => props.color};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: ${(props) => (props.margin === true ? "auto" : "4px")};
  margin-bottom: ${(props) => (props.margin === false ? "auto" : "4px")};
  @media only screen and (min-width: 700px) {
    margin-right: 70px;
    font-size: 16px;
    line-height: 27px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const DropdownFooter = styled.div`
  height: 13%;
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: calc(100% - 2em);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-left: 1em;
  padding-right: 1em;
  background-color: ${(props) => props.backgroundColor};
  @media only screen and (min-width: 700px) {
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

export const DropdownLink = styled.a`
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  font-size: 38px;
  line-height: 49px;
  margin: 0;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 700px) {
    font-size: 60px;
    width: 100%;
    text-align: center;
  }
`;
