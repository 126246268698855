//REACT
import React, { useContext } from "react";
import { useWindowSize } from "../../../../custom-hooks/useWindowSize";
import { LanguageActivity } from "../../../../context/LanguageActivity";
//STYLES
import "./TallersDefaultPage.css";

const TallersDefaultPage = ({
  place,
  title,
  text,
  moreInfo,
  list,
  email,
  id,
  counter,
}) => {
  const displayMobile = useWindowSize();
  const { languageSelected } = useContext(LanguageActivity);
  if (displayMobile) {
    return (
      <div className="tallerDefaultPage-container" id={id}>
        <div className="taller-top-wrapper">
          <p className="top-wrapper-place">{place}</p>
          <p className="top-wrapper-title">
            {title.br1}
            {title.br2 !== undefined && <br />}
            {title.br2 !== undefined && title.br2}
            {title.br3 !== undefined && <br />}
            {title.br3 !== undefined && title.br3}
          </p>
        </div>
        <div className="middle-wrapper">
          <p className="middle-wrapper-text">{text}</p>
        </div>
        <div className="list-wrapper">
          {list.map((item, index) => {
            return (
              <div className="item-list-wrapper" key={index}>
                <div className="bullet-point"></div>
                {item}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (!displayMobile) {
    return (
      <>
        <div className="tallerDefaultPage-container">
          <div className="taller-top-wrapper">
            <p className="top-wrapper-place">{place}</p>
            <p className="top-wrapper-title">
              {title.br1}
              {title.br2 !== undefined && <br />}
              {title.br2 !== undefined && title.br2}
            </p>
          </div>
          <div className="middle-wrapper">
            <p className="middle-wrapper-text">{text}</p>
            {!displayMobile && (
              <a
                className={
                  counter === 0 && languageSelected.angles === true
                    ? "middle-wrapper-moreInfo counter"
                    : "middle-wrapper-moreInfo"
                }
                href={`mailto:${email}`}
              >
                {moreInfo}
              </a>
            )}
          </div>
          <div className="list-wrapper">
            {list.map((item, index) => {
              return (
                <div className="item-list-wrapper" key={index}>
                  <div className="bullet-point"></div>
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
};

export default TallersDefaultPage;
