import React, { createContext, useState } from "react";
export const NavbarActivity = createContext();

export const NavbarActivityProvider = ({ children }) => {
  const [navbarColor, setNavbarColor] = useState("var(--mercagreen)");
  const [hamburgerColor, setHamburgerColor] = useState("var(--skyblue)");
  const [dynamicBackgroundColor, setDynamicBackgroundColor] = useState("");
  const [navbarBackground, setNavbarBackground] = useState("transparent");
  const [navbarOpacity, setNavbarOpacity] = useState(1);

  return (
    <NavbarActivity.Provider
      value={{
        navbarColor,
        setNavbarColor,
        dynamicBackgroundColor,
        setDynamicBackgroundColor,
        hamburgerColor,
        setHamburgerColor,
        navbarBackground,
        setNavbarBackground,
        navbarOpacity,
        setNavbarOpacity,
      }}
    >
      {children}
    </NavbarActivity.Provider>
  );
};
