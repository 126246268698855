import React from "react";
import "./BotiguesDefaultPage.css";
import { TfiClose } from "react-icons/tfi";
const BotiguesDefaultPage = ({ info, section, setController, section2 }) => {
  return (
    <div className="stores__container">
      {section2 !== undefined ? (
        <p className="stores__section">{`${section} ${section2}`}</p>
      ) : (
        <p className="stores__section">{`${section}`}</p>
      )}
      <div className="stores__list">
        {info !== undefined &&
          info.map((item, index) => {
            return (
              <div className="stores__item-wrapper" key={index}>
                <p>{item.name}</p>
                <p>{item.service}</p>
                <p>
                  <a href={`mailto:${item.contact}`} className="botigues-link">
                    {item.contact}
                  </a>
                </p>
              </div>
            );
          })}
      </div>
      <div style={{ alignSelf: "center" }}>
        <TfiClose
          color="var(--skyblue)"
          size={45}
          onClick={() => {
            setController({ boolean: false });
          }}
          className="close-button"
        />
      </div>
    </div>
  );
};

export default BotiguesDefaultPage;
