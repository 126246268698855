export const cookiesData = {
  catala: {
    title1: "Política de cookies",
    p1: "Aquest lloc web utilitza cookies per a millorar l'experiència de l'usuari i garantir el seu correcte funcionament.",
    title2: "Com pots gestionar les cookies?",
    p2: "Pots acceptar o rebutjar l'ús de cookies en aquest lloc web en qualsevol moment modificant la configuració del teu navegador.",
  },
  castella: {
    title1: "Política de cookies",
    p1: "Este sitio web utiliza cookies para mejorar la experiencia del usuario y garantizar su correcto funcionamiento.",
    title2: "¿Cómo puedes gestionar las cookies?",
    p2: "Puedes aceptar o rechazar el uso de cookies en este sitio web en cualquier momento modificando la configuración de tu navegador.",
  },
  angles: {
    title1: "Cookie Policy",
    p1: "Este sitio web utiliza cookies para mejorar la experiencia del usuario y garantizar su correcto funcionamiento.",
    title2: "¿Cómo puedes gestionar las cookies?",
    p2: "Puedes aceptar o rechazar el uso de cookies en este sitio web en cualquier momento modificando la configuración de tu navegador.",
  },
};
