import React, { createContext, useEffect, useState } from "react";

export const UserActivity = createContext();

export const UserActivityProvider = ({ children }) => {
  const getInitialDisplayCookiesBanner = () => {
    const storedValue = localStorage.getItem("displayCookiesBanner");
    return storedValue !== null ? JSON.parse(storedValue) : true;
  };
  const getInitialDisplayNewsletter = () => {
    const storedValue = localStorage.getItem("displayNewsletter");
    return storedValue !== null ? JSON.parse(storedValue) : true;
  };

  const [dropdownMenuSelected, setDropdownMenuSelected] = useState(false);
  const [mobileSectionMenuSelected, setMobileSectionMenuSelected] =
    useState(false);
  const [scheduleSelected, setScheduleSelected] = useState(false);
  const [filterSelected, setFilterSelected] = useState("all");
  const [espaiController, setEspaiController] = useState(0);
  const [scheduleToSpacesController, setScheduleToSpacesController] =
    useState(false);
  const [displayCookiesBanner, setDisplayCookiesBanner] = useState(
    getInitialDisplayCookiesBanner
  );
  //eslint-disable-next-line
  const [displayNewsletter, setDisplayNewsletter] = useState(
    getInitialDisplayNewsletter
  );
  const [enableScrollToTop, setEnableScrollToTop] = useState(true);
  useEffect(() => {
    if (dropdownMenuSelected) {
      document.getElementById("root").style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else if (dropdownMenuSelected === false) {
      document.getElementById("root").style.overflow = "visible";
      document.body.style.overflow = "visible";
    }
  }, [dropdownMenuSelected]);

  useEffect(() => {
    localStorage.setItem(
      "displayCookiesBanner",
      JSON.stringify(displayCookiesBanner)
    );
  }, [displayCookiesBanner]);
  useEffect(() => {
    localStorage.setItem(
      "displayNewsletter",
      JSON.stringify(displayNewsletter)
    );
  }, [displayNewsletter]);
  return (
    <UserActivity.Provider
      value={{
        dropdownMenuSelected,
        setDropdownMenuSelected,
        mobileSectionMenuSelected,
        setMobileSectionMenuSelected,
        scheduleSelected,
        setScheduleSelected,
        filterSelected,
        setFilterSelected,
        espaiController,
        setEspaiController,
        scheduleToSpacesController,
        setScheduleToSpacesController,
        displayCookiesBanner,
        setDisplayCookiesBanner,
        displayNewsletter,
        setDisplayNewsletter,
        enableScrollToTop,
        setEnableScrollToTop,
      }}
    >
      {children}
    </UserActivity.Provider>
  );
};
