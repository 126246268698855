//REACT
import React from "react";
import { useContext } from "react";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
//STYLES
import "./Footer.css";
import {
  Container,
  FooterLinksWrapper,
  FooterLinksWrapperPara,
  FooterInfoPara,
  FooterText,
  Links,
} from "./FooterStyles.js";

const Footer = ({ backgroundColor, color, logo }) => {
  const { data } = useContext(LanguageActivity);

  const navigate = useNavigate();
  const handleClick = (id) => {
    if (id === 0) {
    } else if (id === 1) {
      navigate("/schedule");
    } else if (id === 2) {
      navigate("/contact");
    }
  };
  const displayMobile = useWindowSize();

  if (displayMobile) {
    return (
      <Container backgroundColor={backgroundColor}>
        <div className="footer-links">
          <FooterLinksWrapper color={color} justifyContent={"space-between"}>
            {data.footer !== undefined &&
              data.footer.linksRow1.map((item, index) => {
                return (
                  <Links color={color} key={index}>
                    <p
                      onClick={() => {
                        handleClick(item.key);
                      }}
                    >
                      {item.item}
                    </p>
                  </Links>
                );
              })}
          </FooterLinksWrapper>
          <FooterLinksWrapper
            className="footer-links-wrapper"
            color={color}
            justifyContent={"space-between"}
          >
            <Links
              href="https://www.instagram.com/mercanticsantcugat/"
              target="_blank"
              rel="noopener noreferrer"
              color={color}
            >
              <p>Instagram</p>
            </Links>
            {/*eslint-disable-next-line*/}
            <Links
              href="https://www.facebook.com/Mercantic/?locale=ca_ES"
              target="_blank"
              rel="noopener noreferrer"
              color={color}
            >
              <p>Facebook</p>
            </Links>
            {/*eslint-disable-next-line*/}
            <Links
              href="https://www.linkedin.com/company/mercanticsantcugat/about/"
              target="_blank"
              rel="noopener noreferrer"
              color={color}
            >
              <p>Linkedin</p>
            </Links>
          </FooterLinksWrapper>
        </div>
        {data.footer !== undefined && (
          <div style={{ padding: "1em", paddingTop: "0", paddingBottom: "0" }}>
            <FooterLinksWrapper color={color} justifyContent={"center"}>
              <Links color={color}>
                <p
                  onClick={() => {
                    window.location = "mailto:comunicacio@mercantic.com";
                  }}
                >
                  {data.footer.footerWork}
                </p>
              </Links>
            </FooterLinksWrapper>
          </div>
        )}

        <div className="footer-info">
          <div className="footer-info-address">
            {data.footer !== undefined &&
              data.footer.footerInfoUpper.map((item, index) => {
                return (
                  <FooterInfoPara color={color} key={index}>
                    {item.p}
                  </FooterInfoPara>
                );
              })}
          </div>
          <div className="footer-info-schedule">
            {data.footer !== undefined &&
              data.footer.footerInfoLower.map((item, index) => {
                return (
                  <FooterInfoPara color={color} key={index}>
                    {item.p}
                  </FooterInfoPara>
                );
              })}
          </div>
        </div>
        <div>
          <FooterText className="footer-text" color={color}>
            <p> {data.footer !== undefined && data.footer.footerText}</p>
          </FooterText>
        </div>
        <div className="footer__logo">
          <img src={logo} alt="" />
        </div>
      </Container>
    );
  } else if (!displayMobile) {
    return (
      <Container className="footer-container" backgroundColor={backgroundColor}>
        <div className="footer-laptop-container">
          <div className="footer-links">
            <div
              style={{
                width: "60%",
                height: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <FooterLinksWrapper
                style={{ width: "100%", height: "180px" }}
                justifyContent={"space-between"}
              >
                {data.footer !== undefined &&
                  data.footer.linksRow1.map((item, index) => {
                    return (
                      <FooterLinksWrapperPara
                        color={color}
                        marginTop={item.key === 0 ? "0" : "1em"}
                        key={index}
                        onClick={() => {
                          handleClick(index);
                        }}
                        width={"100%"}
                      >
                        {item.item}
                      </FooterLinksWrapperPara>
                    );
                  })}
              </FooterLinksWrapper>
              <FooterLinksWrapperPara
                color={color}
                justifyContent={"space-between"}
                width={"100%"}
                style={{
                  paddingTop: "0.5em",
                  // maxHeight: "25%",
                  lineHeight: "auto",
                }}
                onClick={() => {
                  window.location = "mailto:comunicacio@mercantic.com";
                }}
              >
                {data.footer !== undefined && data.footer.footerWork}
              </FooterLinksWrapperPara>
            </div>
            <FooterLinksWrapper width={"50%"} justifyContent={"space-between"}>
              <Links
                href="https://www.instagram.com/mercanticsantcugat/"
                target="_blank"
                rel="noopener noreferrer"
                color={color}
              >
                Instagram
              </Links>
              {/*eslint-disable-next-line*/}
              <Links
                href="https://www.facebook.com/Mercantic/?locale=ca_ES"
                target="_blank"
                rel="noopener noreferrer"
                color={color}
              >
                Facebook
              </Links>
              {/*eslint-disable-next-line*/}
              <Links
                href="https://www.linkedin.com/company/mercanticsantcugat/about/"
                target="_blank"
                rel="noopener noreferrer"
                color={color}
              >
                Linkedin
              </Links>
            </FooterLinksWrapper>
          </div>
          <div className="footer-info">
            <div className="footer-info-address">
              {data.footer !== undefined &&
                data.footer.footerInfoUpper.map((item, index) => {
                  return (
                    <FooterInfoPara color={color} key={index}>
                      {item.p}
                    </FooterInfoPara>
                  );
                })}
            </div>
            <br />
            <div className="footer-info-schedule">
              {data.footer !== undefined &&
                data.footer.footerInfoLower.map((item, index) => {
                  return (
                    <FooterInfoPara color={color} key={index}>
                      {item.p}
                    </FooterInfoPara>
                  );
                })}
            </div>
          </div>
          <FooterText className="footer-text" color={color}>
            <p> {data.footer !== undefined && data.footer.footerText}</p>
          </FooterText>
        </div>
        <div className="footer__logo">
          <img src={logo} alt="" />
        </div>
      </Container>
    );
  }
};

export default Footer;
