import React from "react";

import "./HomePageCarousel2.css";

import { carouselImages } from "../../../data/ImagesData";

const HomePageCarousel2 = ({ background }) => {
  return (
    <div
      className="carousel2-container"
      style={{ backgroundColor: background }}
    >
      <div className="carousel-slides">
        {carouselImages.map((item, index) => {
          return <img src={item.img} key={index} alt="" />;
        })}
      </div>
      <div className="carousel-slides">
        {carouselImages.map((item, index) => {
          return <img src={item.img} key={index} alt="" />;
        })}
        {/* {carruselImages.map((item) => {
          return (
            <IKImage
              key={item.path}
              src={item.path}
              lqip={{ active: true, quality: 20, blur: 10 }}
            />
          );
        })} */}
      </div>
    </div>
  );
};
export default HomePageCarousel2;
