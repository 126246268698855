//REACT
import React, { useContext } from "react";
import { UserActivity } from "./context/UserActivity";

//COMPONENTS
import RoutesComponent from "./components/Routes/Routes";
import Navbar from "./components/Individual/Navbar/Navbar";
import DropdownMenu from "./components/Individual/DropdownMenu/DropdownMenu";
function App() {
  const { dropdownMenuSelected } = useContext(UserActivity);

  return (
    <>
      {!dropdownMenuSelected && <Navbar />}
      {dropdownMenuSelected && <DropdownMenu />}
      <RoutesComponent />
    </>
  );
}

export default App;
//// && echo '/* /index.html  200' | cat >build/_redirects
