import React from "react";
import { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import "./Newsletter.css";
import { EmailContainer, InputSubmit, Input } from "./NewsletterStyles";

const CustomForm = ({ onValidated, status, message }) => {
  const { data } = useContext(LanguageActivity);
  const [email, setEmail] = useState("");
  const { navbarColor, dynamicBackgroundColor } = useContext(NavbarActivity);
  const location = useLocation();
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setEmail("");
  };

  return (
    <form
      className={
        location.pathname === "/contact"
          ? "newsletter-container contact__laptop"
          : "newsletter-container"
      }
      onSubmit={(e) => handleSubmit(e)}
    >
      {data.newsletter !== undefined && (
        <p
          style={
            location.pathname === "/contact"
              ? { color: "var(--mercared)" }
              : { color: navbarColor }
          }
        >
          {data.newsletter.text}
        </p>
      )}
      <EmailContainer
        navbarColor={
          location.pathname === "/contact" ? "var(--mercared)" : navbarColor
        }
        dynamicBackground={
          location.pathname === "/contact"
            ? "var(--mercapink)"
            : dynamicBackgroundColor
        }
      >
        <Input
          type="email"
          name="email"
          onChange={handleChange}
          value={email}
          style={
            location.pathname === "/contact"
              ? { color: "var(--mercared)" }
              : { color: navbarColor }
          }
        />

        <InputSubmit
          type="submit"
          dynamicBackground={
            location.pathname === "/contact"
              ? "var(--mercapink)"
              : dynamicBackgroundColor
          }
          contactArrow={location.pathname === "/contact" ? true : false}
        ></InputSubmit>
      </EmailContainer>
    </form>
  );
};

export default CustomForm;
