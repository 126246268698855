//REACT
import React from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import HomePage from "../Screens/HomePage/HomePage";
import GastronomiaPage from "../Screens/GastronomiaPage/GastronomiaPage";
import MercatsPage from "../Screens/MercatsPage/MercatsPage";
import TallersPage from "../Screens/TallersPage/TallersPage";
import EspaisPage from "../Screens/EspaisPage/EspaisPage";
// import SubEspaisDefaultPage from "../Individual/DefaultPages/SubEspaisDefaultPage/SubEspaisDefaultPage";
import AgendaPage from "../Screens/AgendaPage/AgendaPage.jsx";
import ContactPage from "../Screens/ContactPage/ContactPage";
import EsdevenimentPage from "../Screens/EsdevenimentPage/EsdevenimentPage";
import EmpresaPage from "../Screens/EmpresaPage/EmpresaPage.jsx";
import ScrollToTop from "../Individual/ScrollToTop/ScrollToTop";
import Cookies from "../Screens/Cookies/Cookies.jsx";
const RoutesComponent = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gastronomy" element={<GastronomiaPage />} />
        <Route path="/markets" element={<MercatsPage />} />
        <Route path="/workshops" element={<TallersPage />} />
        <Route path="/spaces/:section" element={<EspaisPage />} />
        <Route path="/schedule" element={<AgendaPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/event" element={<EsdevenimentPage />} />
        <Route path="/company" element={<EmpresaPage />} />
        <Route path="cookies" element={<Cookies />} />
      </Routes>
    </ScrollToTop>
  );
};

export default RoutesComponent;
