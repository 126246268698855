//REACT
import React, { useContext, useEffect } from "react";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { UserActivity } from "../../../context/UserActivity";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { useScroll } from "../../../custom-hooks/useScroll";

//STYLES
import "./TallersPage.css";
import { LinkContainer, Link } from "./TallersStyles";
//IMAGES
import footerLogo from "../../../assets/TallersPage/footerLogo.svg";
import {
  ceramicaImages,
  extraescolarsImages,
  floristeriaImages,
  llanesImages,
  llumImages,
  restauracioMoblesImages,
} from "../../../data/workshopsImages";
//COMPONENTS
import InitialPage from "../../Individual/InitialPage/InitialPage";
import TallersDefaultPage from "../../Individual/DefaultPages/TallersDeffaultPage/TallersDefaultPage";
import Footer from "../../Individual/Footer/Footer";
import DefaultCarousel from "../../Individual/DefaultPages/DefaultCarousel/DefaultCarousel";
import { IKImage } from "imagekitio-react";

const TallersPage = () => {
  const { data } = useContext(LanguageActivity);
  const { setFilterSelected, dropdownMenuSelected, setEnableScrollToTop } =
    useContext(UserActivity);
  const displayMobile = useWindowSize();
  const { setNavbarColor, setHamburgerColor } = useContext(NavbarActivity);
  const navigate = useNavigate();
  const { scrollPercent } = useScroll();
  const handleAgendaMenu = () => {
    setFilterSelected("workshops");
    navigate("/schedule");
  };

  const handleOpacity = (index) => {
    if (index === 0) {
      if (scrollPercent <= 150) {
        return 1;
      } else return 0.5;
    }
    if (index === 1) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 150 && scrollPercent < 250) {
        return 1;
      } else return 0.5;
    }
    if (index === 2) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 250 && scrollPercent < 350) {
        return 1;
      } else return 0.5;
    }
    if (index === 3) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 350 && scrollPercent < 450) {
        return 1;
      } else return 0.5;
    }
    if (index === 4) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 450 && scrollPercent < 550) {
        return 1;
      } else return 0.5;
    }
    if (index === 5) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 550) {
        return 1;
      } else return 0.5;
    }
  };
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--pagination-color",
      "var(--lightgreen)"
    );
    document.documentElement.style.setProperty("--swiper-wrapper-padding", "0");
    if (displayMobile) {
      setNavbarColor("var(--mercagreen)");
      setHamburgerColor("var(--mercagreen)");
    } else if (!displayMobile) {
      setNavbarColor("var(--lightgreen)");
      setHamburgerColor("var(--mercagreen)");
    }
  }, []);

  useEffect(() => {
    if (!displayMobile) {
      setNavbarColor("var(--lightgreen)");
      setHamburgerColor("var(--mercagreen)");
    } else if (displayMobile) {
      if (!dropdownMenuSelected) {
        if (scrollPercent >= 0 && scrollPercent > 103) {
          setNavbarColor("var(--lightgreen)");
          setHamburgerColor("var(--lightgreen)");
        } else if (scrollPercent >= 0 && scrollPercent < 103) {
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--mercagreen)");
        }
      }
    }
  }, [scrollPercent, dropdownMenuSelected]);
  if (displayMobile) {
    return (
      <>
        {data.tallers !== undefined && (
          <>
            <InitialPage
              section={data.tallers.initialPage.section}
              color={"var(--mercagreen)"}
              backgroundColor={"var(--lightgreen)"}
              text={data.tallers.initialPage.text}
              map={data.tallers.initialPage.laptop}
              link={data.tallers.initialPage.link}
              image1={
                "https://ik.imagekit.io/gwms6thri/InitialPage/initialPageTallers.jpg?updatedAt=1690296180707"
              }
              image2={
                "https://ik.imagekit.io/gwms6thri/InitialPage/TALLERES.svg?updatedAt=1690294493593"
              }
            />
            <div>
              {data.tallers.defaultPage.map((item, index) => {
                return (
                  <>
                    <div
                      style={{ position: "sticky", zIndex: 200, opacity: 0.99 }}
                    >
                      <TallersDefaultPage
                        key={item.key}
                        place={item.place}
                        title={item.title}
                        text={item.text}
                        moreInfo={item.moreInfo}
                        list={item.list}
                        id={data.tallers.initialPage.laptop[item.key].idName}
                      />
                    </div>

                    {item.key === 0 && (
                      <DefaultCarousel images={llanesImages} market={true} />
                    )}
                    {item.key === 1 && (
                      <DefaultCarousel images={ceramicaImages} market={true} />
                    )}
                    {item.key === 2 && (
                      <DefaultCarousel
                        images={restauracioMoblesImages}
                        market={true}
                      />
                    )}
                    {item.key === 3 && (
                      <DefaultCarousel
                        images={extraescolarsImages}
                        market={true}
                      />
                    )}
                    {item.key === 4 && (
                      <DefaultCarousel images={llumImages} market={true} />
                    )}
                    {item.key === 5 && (
                      <DefaultCarousel
                        images={floristeriaImages}
                        market={true}
                      />
                    )}
                  </>
                );
              })}
              <Footer
                backgroundColor={"var(--lightgreen)"}
                color={"var(--mercagreen)"}
                logo={footerLogo}
              />
            </div>
          </>
        )}
        <div className="agenda-banner-container">
          <p
            onClick={() => {
              navigate("/schedule");
            }}
          >
            Agenda
          </p>
        </div>
      </>
    );
  } else if (!displayMobile) {
    return (
      <div className="tallersPage-container">
        {data.tallers !== undefined && (
          <>
            <div className="laptop-wrapper laptop-wrapper-initialPage">
              <InitialPage
                section={data.tallers.initialPage.section}
                color={"var(--mercagreen)"}
                backgroundColor={"var(--lightgreen)"}
                text={data.tallers.initialPage.text}
                map={data.tallers.initialPage.laptop}
                link={data.tallers.initialPage.link}
                page={data.tallers.initialPage.page}
                firstSquareWidth={"40%"}
                secondSquareWidth={"70%"}
                firstSquareHeight={16}
                secondSquareHeight={33}
              />
              <div style={{ width: "50%" }}>
                <IKImage
                  src={
                    "https://ik.imagekit.io/gwms6thri/InitialPage/initialPageTallers.jpg?updatedAt=1690296180707"
                  }
                  lqip={{ active: true, quality: 5, blur: 10 }}
                  alt="llanes"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <div className="laptop-defaultPage-wrapper">
                  {data.tallers.defaultPage.map((item, index) => {
                    return (
                      <div
                        className="laptop-wrapper"
                        id={data.tallers.initialPage.laptop[item.key].idName}
                        key={index}
                      >
                        <TallersDefaultPage
                          key={item.key}
                          place={item.place}
                          title={item.title}
                          text={item.text}
                          moreInfo={data.tallers.moreInfo}
                          list={item.list}
                          email={item.email}
                          counter={item.key}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <DefaultCarousel images={llanesImages} />
                <DefaultCarousel images={ceramicaImages} />
                <DefaultCarousel images={restauracioMoblesImages} />
                <DefaultCarousel images={extraescolarsImages} />
                <DefaultCarousel images={llumImages} />
                <DefaultCarousel images={floristeriaImages} />
              </div>
            </div>
          </>
        )}
        <div style={{ height: "100%", display: "flex" }}>
          <Footer
            color={"var(--mercagreen)"}
            backgroundColor={"var(--lightgreen)"}
            logo={footerLogo}
          />
        </div>
        {data.tallers !== undefined && (
          <LinkContainer backgroundColor={"var(--lightgreen)"}>
            {data.tallers.initialPage.laptop.map((item, index) => {
              return (
                <HashLink
                  to={`/workshops#${item.idName}`}
                  scroll={(el) =>
                    el.scrollIntoView({ behavior: "smooth", block: "end" })
                  }
                  key={index}
                  onClick={() => {
                    setEnableScrollToTop(false);
                  }}
                >
                  <Link
                    color={"var(--mercagreen)"}
                    opacity={handleOpacity(index)}
                  >
                    {item.name}
                  </Link>
                </HashLink>
              );
            })}
          </LinkContainer>
        )}
        <div className="tallersPage-agenda-fixed-menu-container">
          <p
            className="tallersPage-agenda-fixed-menu"
            onClick={() => {
              handleAgendaMenu();
            }}
          >
            Agenda
          </p>
        </div>
      </div>
    );
  }
};

export default TallersPage;
