import styled from "styled-components";
import arrow from "../../../assets/Newsletter/arrow.svg";
import arrowContact from "../../../assets/Newsletter/arrowContact.svg";
export const EmailContainer = styled.div`
  border-radius: 50px;
  height: 40px;
  border: 2px solid ${(props) => props.navbarColor};
  background-color: ${(props) => props.dynamicBackground};
  max-width: calc(100% - 0.25em);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
`;

export const InputSubmit = styled.button`
  border: none;
  background-color: ${(props) => props.dynamicBackground};
  width: 21px;
  height: 21px;
  padding: 0;
  background-image: url(${(props) =>
    props.contactArrow === true ? arrowContact : arrow});
  background-position: center center;
  max-width: 100%;
  background-size:cover;
  :hover{
    cursor:pointer
  }
}
`;

export const Input = styled.input`
  letter-spacing: 0.03em;
  color: #193f28;
  border: none;
  background-color: transparent;
  position: relative;
  outline: none;
  width: 85%;
`;
