import ñidro01 from "../assets/GastronomiaPage/Casa Ñidro/casañidro01.jpg";
import ñidro01Placeholder from "../assets/GastronomiaPage/Casa Ñidro/casañidro01Placeholder.jpg";
import ñidro02Placeholder from "../assets/GastronomiaPage/Casa Ñidro/casañidro02Placeholder.jpg";
import ñidro03Placeholder from "../assets/GastronomiaPage/Casa Ñidro/casañidro03Placeholder.jpg";
import ñidro04Placeholder from "../assets/GastronomiaPage/Casa Ñidro/casañidro04Placeholder.jpg";
import ñidro05Placeholder from "../assets/GastronomiaPage/Casa Ñidro/casañidro05Placeholder.jpg";
import ñidro06Placeholder from "../assets/GastronomiaPage/Casa Ñidro/casañidro06Placeholder.jpg";
import ñidro07Placeholder from "../assets/GastronomiaPage/Casa Ñidro/casañidro07Placeholder.jpg";
import ñidro08Placeholder from "../assets/GastronomiaPage/Casa Ñidro/casañidro08Placeholder.jpg";
import ñidro02 from "../assets/GastronomiaPage/Casa Ñidro/casañidro02.jpg";
import ñidro03 from "../assets/GastronomiaPage/Casa Ñidro/casañidro03.jpeg";
import ñidro04 from "../assets/GastronomiaPage/Casa Ñidro/casañidro04.jpg";
import ñidro05 from "../assets/GastronomiaPage/Casa Ñidro/casañidro05.jpeg";
import ñidro06 from "../assets/GastronomiaPage/Casa Ñidro/casañidro06.jpg";
import ñidro07 from "../assets/GastronomiaPage/Casa Ñidro/casañidro07.jpg";
import ñidro08 from "../assets/GastronomiaPage/Casa Ñidro/casañidro08.jpg";

import taverna01 from "../assets/GastronomiaPage/Taverna Catalana/taverna01.jpg";
import taverna01Placeholder from "../assets/GastronomiaPage/Taverna Catalana/taverna01Placeholder.jpg";
import taverna02Placeholder from "../assets/GastronomiaPage/Taverna Catalana/taverna02Placeholder.jpg";
import taverna02 from "../assets/GastronomiaPage/Taverna Catalana/taverna02.jpg";

import greta01 from "../assets/GastronomiaPage/Greta/greta01.jpg";
import greta01Placeholder from "../assets/GastronomiaPage/Greta/greta01Placeholder.jpg";
import greta02Placeholder from "../assets/GastronomiaPage/Greta/greta02Placeholder.jpg";
import greta03Placeholder from "../assets/GastronomiaPage/Greta/greta03Placeholder.jpg";
import greta04Placeholder from "../assets/GastronomiaPage/Greta/greta04Placeholder.jpg";
import greta02 from "../assets/GastronomiaPage/Greta/greta02.jpg";
import greta03 from "../assets/GastronomiaPage/Greta/greta03.jpg";
import greta04 from "../assets/GastronomiaPage/Greta/greta04.jpg";

import cangula01 from "../assets/GastronomiaPage/Can Gula/cangula01.jpg";
import cangula01Placeholder from "../assets/GastronomiaPage/Can Gula/cangula01Placeholder.jpg";
import cangula02Placeholder from "../assets/GastronomiaPage/Can Gula/cangula02Placeholder.jpg";
import cangula03Placeholder from "../assets/GastronomiaPage/Can Gula/cangula03Placeholder.jpg";
import cangula04Placeholder from "../assets/GastronomiaPage/Can Gula/cangula04Placeholder.jpg";
import cangula02 from "../assets/GastronomiaPage/Can Gula/cangula02.jpg";
import cangula03 from "../assets/GastronomiaPage/Can Gula/cangula03.jpg";
import cangula04 from "../assets/GastronomiaPage/Can Gula/cangula04.jpg";

import cinquieta01 from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta01.jpg";
import cinquieta01Placeholder from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta01Placeholder.jpg";
import cinquieta02Placeholder from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta02Placeholder.jpg";
import cinquieta03Placeholder from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta03Placeholder.jpg";
import cinquieta04Placeholder from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta04Placeholder.jpg";
import cinquieta05Placeholder from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta05Placeholder.jpg";
import cinquieta02 from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta02.jpg";
import cinquieta03 from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta03.jpg";
import cinquieta04 from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta04.jpg";
import cinquieta05 from "../assets/GastronomiaPage/Cocina Inquieta/cinquieta05.jpg";

export const casaÑidroImages = [
  {
    img: ñidro01,
    placeholder: ñidro01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Casa%20%C3%91idro/casa%C3%B1idro01.jpg?updatedAt=1690185297577",
  },
  {
    img: ñidro02,
    placeholder: ñidro02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Casa%20%C3%91idro/casa%C3%B1idro02.jpg?updatedAt=1690185301206",
  },
  {
    img: ñidro03,
    placeholder: ñidro03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Casa%20%C3%91idro/casa%C3%B1idro03.jpeg?updatedAt=1690185301675",
  },
  {
    img: ñidro04,
    placeholder: ñidro04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Casa%20%C3%91idro/casa%C3%B1idro04.jpg?updatedAt=1690185301590",
  },
  {
    img: ñidro05,
    placeholder: ñidro05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Casa%20%C3%91idro/casa%C3%B1idro05.jpeg?updatedAt=1690185301381",
  },
  {
    img: ñidro06,
    placeholder: ñidro06Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Casa%20%C3%91idro/casa%C3%B1idro06.jpg?updatedAt=1690185301607",
  },
  {
    img: ñidro07,
    placeholder: ñidro07Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Casa%20%C3%91idro/casa%C3%B1idro07.jpg?updatedAt=1690185306444",
  },
  {
    img: ñidro08,
    placeholder: ñidro08Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Casa%20%C3%91idro/casa%C3%B1idro08.jpg?updatedAt=1690185306747",
  },
];

export const tavernaImages = [
  {
    img: taverna01,
    placeholder: taverna01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Taverna%20Catalana/taverna01.jpg?updatedAt=1690185287753",
  },
  {
    img: taverna02,
    placeholder: taverna02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Taverna%20Catalana/taverna02.jpg?updatedAt=1690185288827",
  },
];

export const canGulaImages = [
  {
    img: cangula01,
    placeholder: cangula01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Can%20Gula/cangula01.jpg?updatedAt=1690185305649",
  },
  {
    img: cangula02,
    placeholder: cangula02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Can%20Gula/cangula02.jpg?updatedAt=1690185305623",
  },
  {
    img: cangula03,
    placeholder: cangula03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Can%20Gula/cangula03.jpg?updatedAt=1690185305727",
  },
  {
    img: cangula04,
    placeholder: cangula04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Can%20Gula/cangula04.jpg?updatedAt=1690185308574",
  },
];

export const cocinaInquietaImages = [
  {
    img: cinquieta01,
    placeholder: cinquieta01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Cocina%20Inquieta/cinquieta01.jpg?updatedAt=1690185295178",
  },
  {
    img: cinquieta02,
    placeholder: cinquieta02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Cocina%20Inquieta/cinquieta02.jpg?updatedAt=1690185296824",
  },
  {
    img: cinquieta03,
    placeholder: cinquieta03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Cocina%20Inquieta/cinquieta03.jpg?updatedAt=1690185296826",
  },
  {
    img: cinquieta04,
    placeholder: cinquieta04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Cocina%20Inquieta/cinquieta04.jpg?updatedAt=1690185296885",
  },
  {
    img: cinquieta05,
    placeholder: cinquieta05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Cocina%20Inquieta/cinquieta05.jpg?updatedAt=1690185297343",
  },
];

export const gretaImages = [
  {
    img: greta01,
    placeholder: greta01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Greta/greta01.jpg?updatedAt=1690185289431",
  },
  {
    img: greta02,
    placeholder: greta02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Greta/greta02.jpg?updatedAt=1690185292307",
  },
  {
    img: greta03,
    placeholder: greta03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Greta/greta03.jpg?updatedAt=1690185292716",
  },
  {
    img: greta04,
    placeholder: greta04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/GastronomiaPage/Greta/greta04.jpg?updatedAt=1690185292564",
  },
];
