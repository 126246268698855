export const gastronomiaData = {
  catala: {
    navbar: [
      "Casa Ñidro",
      "Can Gula",
      "Taverna Catalana",
      "Restaurant Greta",
      "Cinc",
    ],
    initialPage: {
      section: "Gastronomia",
      text: "Una àmplia oferta gastronòmica amb menjar local i de temporada",
      link: "Restaurants",
      laptop: [
        { name: "Casa Ñidro", idName: "casañidro" },
        { name: "Can Gula", idName: "cangula" },
        { name: "Taverna Catalana", idName: "tavernacatalana" },
        { name: "Restaurant Greta", idName: "restaurantgreta" },
        { name: "Cinc", idName: "cocinainquieta" },
      ],
      page: "gastronomy",
    },
    frame2: {
      title: {
        br1: "Casa Ñidro",
      },
      text: "Espai gastronòmic amb productes de proximitat i temporada. Proposen una cuina familiar amb una combinació de plats clàssics i familiars. És un lloc on els clients se senten com a casa. Proveu el seu pa de massa mare que es cou cada dia.",
    },
    frame4: {
      title: {
        br1: "Can Gula",
      },
      text: "Xiringuito gastronòmic que ofereix opcions de brasa, productes locals de temporada i una aposta per la cuina de mercat. Compta amb una gran terrassa on hi dona el sol desde l’hora del vermut fins l’hora del café. ",
    },
    frame6: {
      title: {
        br1: "Taverna",
        br2: "Catalana",
      },
      text: "Aquesta acollidora taverna gastronòmica catalana ofereix una experiència culinària autèntica, amb plats tradicionals elaborats amb ingredients locals d'alta qualitat. L'ambient rústic i la decoració típica creen un espai acollidor i autèntic, perfecte per gaudir d'una bona taula en companyia d'amics o familiars.",
    },
    frame8: {
      title: {
        br1: "Restaurant",
        br2: "Greta",
      },
      text: "El restaurant Greta és completament vegà, elaborat amb ingredients frescos i d'origen local. Els plats són creatius i estan plens de sabor, amb opcions que van des d'amanides i tacos fins a formatges vegans, i el no steak tartar inspirats en la cuina internacional. A més del menjar, Greta ofereix una àmplia selecció de còctels i vins naturals.",
    },
    frame10: {
      title: {
        br1: "Cinc",
        br2: "",
      },
      text: "La Foodtruck de Mercantic, on podràs gaudir d’un brunch, cafè, sandwiches i pastisseria. Tots els plats són elaborats amb ingredients frescos i d’alta qualitat. Cinc és l'opció ideal per a aquells que busquen un lloc on menjar bé, en un ambient urbà a l’exterior.",
      schedule: {
        br1: "Dilluns: Tancat",
        br2: "De dimarts a diumenge: 9:00-16:00",
      },
    },
  },
  castella: {
    navbar: [
      "Casa Ñidro",
      "Can Gula",
      "Taverna Catalana",
      "Restaurant Greta",
      "Cinc",
    ],
    initialPage: {
      section: "Gastronomía",
      text: "Una amplia oferta gastronómica con comida local y de temporada",

      link: "Restaurantes",
      laptop: [
        { name: "Casa Ñidro", idName: "casañidro" },
        { name: "Can Gula", idName: "cangula" },
        { name: "Taverna Catalana", idName: "tavernacatalana" },
        { name: "Restaurante Greta", idName: "restaurantgreta" },
        { name: "Cinc", idName: "cocinainquieta" },
      ],
      page: "gastronomy",
    },
    frame2: {
      title: {
        br1: "Casa Ñidro",
      },
      text: "Espacio gastronómico con productos locales y de temporada. Ofrecen una cocina familiar con una combinación de platos clásicos y caseros. Es un lugar donde los clientes se sienten como en casa. Prueba su pan de masa madre que se hornea todos los días.",
    },
    frame4: {
      title: {
        br1: "Can Gula",
      },
      text: "Un chiringuito gastronómico que ofrece opciones de brasa, productos locales de temporada y apuesta por la cocina de mercado. Cuenta con una gran terraza donde da el sol desde la hora del vermut hasta la hora del café",
    },
    frame6: {
      title: {
        br1: "Taverna",
        br2: "Catalana",
      },
      text: "Esta acogedora taberna gastronómica catalana ofrece una experiencia culinaria auténtica, con platos tradicionales elaborados con ingredientes locales de alta calidad. El ambiente rústico y la decoración típica crean un espacio acogedor y auténtico, perfecto para disfrutar de una buena mesa en compañía de amigos o familiares.",
    },
    frame8: {
      title: {
        br1: "Restaurante",
        br2: "Greta",
      },
      text: "El restaurante Greta es completamente vegano, elaborado con ingredientes frescos y de origen local. Los platos son creativos y están llenos de sabor, con opciones que van desde ensaladas y tacos hasta quesos veganos y el no steak tartar inspirados en la cocina internacional. Además de la comida, Greta ofrece una amplia selección de cócteles y vinos naturales.",
    },
    frame10: {
      title: {
        br1: "Cinc",
        br2: "",
      },
      text: "La Foodtruck de Mercantic, donde podrás disfrutar de un brunch, café, sándwiches y repostería. Todos los platos son elaborados con ingredientes frescos y de alta calidad. Cinc es la opción ideal para aquellos que buscan un lugar donde comer bien, en un ambiente urbano al aire libre.",
    },
  },
  angles: {
    navbar: [
      "Casa Ñidro",
      "Can Gula",
      "Taverna Catalana",
      "Restaurant Greta",
      "Cinc",
    ],
    initialPage: {
      section: "Gastronomy",
      text: "A rich selection of local and seasonal food",

      link: "Restaurants",
      laptop: [
        { name: "Casa Ñidro", idName: "casañidro" },
        { name: "Can Gula", idName: "cangula" },
        { name: "Taverna Catalana", idName: "tavernacatalana" },
        { name: "Restaurant Greta", idName: "restaurantgreta" },
        { name: "Cinc", idName: "cocinainquieta" },
      ],
      page: "gastronomy",
    },
    frame2: {
      title: {
        br1: "Casa Ñidro",
      },
      text: "A space offering local and seasonal produce. They offer a family-style cuisine with a combination of classic catalan and home-cooked dishes. It's a place where customers feel at home. Casa Ñidro bakes their sourdough fresh every day.",
    },
    frame4: {
      title: {
        br1: "Can Gula",
      },
      text: "A gastro - beach bar committed to local cuisine that offers a barbecued menu as well as seasonal produce. Its large terrace offers sunshine from midday into the late afternoon. ",
    },
    frame6: {
      title: {
        br1: "Taverna",
        br2: "Catalana",
      },
      text: "This cozy Catalan tavern offers an authentic culinary experience with traditional dishes made from high-quality local ingredients. The rustic atmosphere and typical decoration create a welcoming and authentic space, perfect for enjoying an intimate meal with friends or family.",
    },
    frame8: {
      title: {
        br1: "Restaurant",
        br2: "Greta",
      },
      text: "Restaurant Greta offers a fully vegan menu, made from fresh and locally sourced ingredients and inspired by international cuisine. The dishes are creative and flavourful, with options ranging from salads and tacos to vegan cheeses and no-steak tartare. Greta also offers a wide selection of cocktails and natural wines",
    },
    frame10: {
      title: {
        br1: "Cinc",
        br2: "",
      },
      text: "Mercantic’s very own Foodtruck offering brunch, coffee, sandwiches, and pastries. All dishes are made with fresh, high-quality ingredients. Cinc is the perfect option for those looking for a place to eat well in an urban outdoor setting.",
    },
  },
};
