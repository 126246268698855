import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  z-index: 998;
  position: sticky;
  top: 0;
  @media only screen and (min-width: 700px) {
    justify-content: space-between;
    height: calc(100vh - 40px);
    padding-bottom: 40px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  border-bottom: 1.5px solid ${(props) => props.color};
  justify-content: ${(props) => props.justifyContent};

  @media only screen and (min-width: 700px) {
    flex-direction: column;
    width: 50%;
    border: none;
    justify-content: flex-start;
    align-items: flex-end;
  }
`;
export const FooterLinksWrapperPara = styled.p`
  color: ${(props) => props.color};
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  @media only screen and (min-width: 700px) {
    font-size: 16px;
    line-height: 31px;
    text-align: right;
    margin: 0;
    width: ${(props) => props.width};
  }
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const FooterInfoPara = styled.p`
  color: ${(props) => props.color};
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  margin: 0;
`;

export const FooterText = styled.div`
  font-size: 14px;
  line-height: 27px;
  color: ${(props) => props.color};
  padding: 1em;
  padding-top: 0;
  padding-right: 0.2em;
  @media only screen and (min-width: 700px) {
    width: calc(100% / 3);
  }
`;

export const Links = styled.a`
  color: ${(props) => props.color};
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  text-decoration: none;

  @media only screen and (min-width: 700px) {
    font-size: 16px;
    line-height: 31px;
    text-align: right;
    display: block;
    height: fit-content;
  }
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
