//REACT
import React from "react";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
//STYLES
import "./AgendaColumn.css";

import AgendaCard from "../AgendaCard/AgendaCard";

const AgendaColumn = ({ column, display, number, date, holidays }) => {
  const displayMobile = useWindowSize();

  const convertTimeToMinutes = (timeString) => {
    const [startTime] = timeString.split(" - ")[0].trim().split("h")[0].trim();
    let [hours, minutes = 0] = startTime.split(":").map(Number);

    // Comprovem si el temps inclou "AM" o "PM"
    console.log(`Processing time: ${startTime}`); // Log del temps original
    if (startTime.includes("PM") && hours < 12) {
      hours += 12; // Converteix a format 24 hores
    } else if (startTime.includes("AM") && hours === 12) {
      hours = 0; // 12 AM és 0 hores
    }

    console.log(`Converted to minutes: ${hours * 60 + minutes}`); // Log del resultat
    return hours * 60 + minutes; // Retorna el total de minuts
  };

  // Ordenar column per l'hora d'inici

  // Ordenar column per l'hora d'inici
  const sortedColumn = column
    ? [...column].sort((a, b) => {
        const startTimeA = convertTimeToMinutes(a.time);
        const startTimeB = convertTimeToMinutes(b.time);
        console.log(
          `Comparing: ${a.time} (${startTimeA}) vs ${b.time} (${startTimeB})`
        ); // Log per comparar
        return startTimeA - startTimeB;
      })
    : [];

  console.log("Sorted Column:", sortedColumn);
  if (!displayMobile) {
    return (
      <div className="agenda-column__container">
        <div className="agenda-column__day__wrapper">
          {number !== undefined && (
            <p className="agenda-column__day__number">{number}</p>
          )}
        </div>
        <div className="agenda-column__wrapper">
          {display &&
            column !== undefined &&
            sortedColumn.map((item, index) => {
              return (
                <AgendaCard
                  section={item.section}
                  subsection={item.subsection}
                  time={item.time}
                  book={item.book}
                  info={item.info}
                  type={item.type}
                  filter={item.filter}
                  filter2={item.filter2}
                  key={index}
                  route={item.route}
                  holidays={holidays}
                  bookAddressMail={item.bookAddressMail}
                  bookAddressRoute={item.bookAddressRoute}
                  externalBookAddress={item.externalBookAddress}
                  externalInfo={item.externalInfo}
                  sell={item.sell}
                />
              );
            })}
          {display && holidays !== undefined && (
            <AgendaCard holidays={holidays} />
          )}
        </div>
      </div>
    );
  } else if (displayMobile) {
    return null;
  }
};

export default AgendaColumn;
