import React, { useContext, useEffect } from "react";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import image from "../../../assets/Cookies/cookiesImage.jpg";
import "./Cookies.css";
import { UserActivity } from "../../../context/UserActivity";
const Cookies = () => {
  const { data } = useContext(LanguageActivity);
  const {
    setNavbarColor,
    setHamburgerColor,
    setNavbarBackground,
    setNavbarOpacity,
  } = useContext(NavbarActivity);
  const displayMobile = useWindowSize();
  const { dropdownMenuSelected } = useContext(UserActivity);
  useEffect(() => {
    setNavbarColor("var(--mercagreen)");
    setHamburgerColor("var(--mercagreen)");
    setNavbarBackground("transparent");
    setNavbarOpacity(1);
  }, []);

  useEffect(() => {
    if (!dropdownMenuSelected) {
      setHamburgerColor("var(--mercagreen)");
    }
  }, [dropdownMenuSelected]);
  if (displayMobile) {
    return (
      <div className="cookies__container">
        {data.cookies !== undefined && (
          <div className="cookies__section-wrapper">
            <div className="cookies__section">
              <h3>{data.cookies.title1}</h3>
              <p>{data.cookies.p1}</p>
            </div>
            <div className="cookies__section">
              <h3>{data.cookies.title2}</h3>
              <p>{data.cookies.p2}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
  if (!displayMobile) {
    return (
      <div className="cookies__container">
        {data.cookies !== undefined && (
          <div className="cookies__section-wrapper">
            <div className="cookies__section">
              <h3>{data.cookies.title1}</h3>
              <p>{data.cookies.p1}</p>
            </div>
            <div className="cookies__section">
              <h3>{data.cookies.title2}</h3>
              <p>{data.cookies.p2}</p>
            </div>
          </div>
        )}
        <img src={image} className="cookies__image" alt="solar" />
      </div>
    );
  }
};

export default Cookies;
