export const dropdownData = {
  catala: {
    items: [
      {
        text: "Zones",
        key: 0,
        route: "spaces/home",
      },
      {
        text: "Mercats",
        key: 1,
        route: "markets",
      },
      {
        text: "Tallers",
        key: 2,
        route: "workshops",
      },
      {
        text: "Gastronomia",
        key: 3,
        route: "gastronomy",
      },
      {
        text: "Agenda",
        key: 4,
        route: "schedule",
      },
      {
        text: "Productes",
        key: 6,
        route: "contact",
      },
      {
        text: "Contacte",
        key: 5,
        route: "contact",
      },
    ],
    languages: [
      { key: 0, text: "ES" },
      { key: 1, text: "CAT" },
      { key: 2, text: "EN" },
    ],
    footer: {
      footer1: "Vols celebrar un esdeveniment?",
      footer2: "Vols llogar un espai?",
    },
  },
  castella: {
    items: [
      {
        text: "Zonas",
        key: 0,
        route: "spaces/home",
      },
      {
        text: "Mercados",
        key: 1,
        route: "markets",
      },
      {
        text: "Talleres",
        key: 2,
        route: "workshops",
      },
      {
        text: "Gastronomía",
        key: 3,
        route: "gastronomy",
      },
      {
        text: "Agenda",
        key: 4,
        route: "schedule",
      },
      {
        text: "Productos",
        key: 6,
        route: "contact",
      },
      {
        text: "Contacto",
        key: 5,
        route: "contact",
      },
    ],
    languages: [
      { key: 0, text: "ES" },
      { key: 1, text: "CAT" },
      { key: 2, text: "EN" },
    ],
    footer: {
      footer1: "¿Quieres celebrar un evento?",
      footer2: "Quieres alquilar un espacio?",
    },
  },
  angles: {
    items: [
      {
        text: "Zones",
        key: 0,
        route: "spaces/home",
      },
      {
        text: "Markets",
        key: 1,
        route: "markets",
      },
      {
        text: "Workshops",
        key: 2,
        route: "workshops",
      },
      {
        text: "Gastronomy",
        key: 3,
        route: "gastronomy",
      },
      {
        text: "Agenda",
        key: 4,
        route: "schedule",
      },
      {
        text: "Products",
        key: 6,
        route: "contact",
      },
      {
        text: "Contact",
        key: 5,
        route: "contact",
      },
    ],
    languages: [
      { key: 0, text: "ES" },
      { key: 1, text: "CAT" },
      { key: 2, text: "EN" },
    ],
    footer: {
      footer1: "¿Want to host an event?",
      footer2: "Do you want to rent an space?",
    },
  },
};
