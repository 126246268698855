export const agendaFilterData = {
  catala: {
    permanent: "Taller Permanent",
    filter: [
      { name: "Mercats", filter: "markets" },
      { name: "Tallers", filter: "workshops" },
      { name: "Concerts", filter: "concerts" },
      { name: "Esdeveniments", filter: "events" },
    ],
  },
  castella: {
    permanent: "Taller Permanente",
    filter: [
      { name: "Mercados", filter: "markets" },
      { name: "Talleres", filter: "workshops" },
      { name: "Conciertos", filter: "concerts" },
      { name: "Eventos", filter: "events" },
    ],
  },
  angles: {
    permanent: "Permanent Workshop",
    filter: [
      { name: "Markets", filter: "markets" },
      { name: "Workshops", filter: "workshops" },
      { name: "Concerts", filter: "concerts" },
      { name: "Events", filter: "events" },
    ],
  },
};
