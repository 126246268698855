export const esdevenimentData = {
  catala: {
    title: "Vols celebrar un esdeveniment?",
    text: "Amb l'ambició de continuar inspirant els visitants, emprenedors i empreses, Mercantic posa a disposició les seves sales per organitzar esdeveniments. La singularitat dels espais contribueix a fer únic qualsevol mena d'acte: des de festes i grans celebracions, fins a sessions de treball, reunions d'empresa o presentacions de llibres. És també un escenari perfecte per a espectacles, sessions de fotos i rodatges de cinema, televisió o publicitat. Mercantic ofereix els serveis necessaris per materialitzar les idees.",
    footer: {
      item1: "Contacte",
      item2: "Dossier Informatiu",
    },
  },
  castella: {
    title: "Quieres celebrar un evento?",
    text: "Con la ambición de seguir inspirando a visitantes, emprendedores y empresas, Mercantic pone a disposición sus salas para la organización de eventos. La singularidad de los espacios contribuye a hacer único cualquier tipo de acto: desde fiestas y grandes celebraciones, hasta sesiones de trabajo, reuniones empresariales o presentaciones de libros. También es un escenario perfecto para espectáculos, sesiones de fotos y rodajes de cine, televisión o publicidad. Mercantic ofrece los servicios necesarios para materializar las ideas.",
    footer: {
      item1: "Contact",
      item2: "Dossier Informativo",
    },
  },
  angles: {
    title: "Want to celebrate an event?",
    text: "With the aim of inspiring visitors, entrepreneurs, and businesses, Mercantic offers its spaces for event hire. The uniqueness of its venues makes any type of event special; from parties and large scale celebrations to work sessions, company meetings, or book presentations. Mercantic offers a perfect setting for performances, photo shoots, and film, television, or advertising shoots, and provides the necessary service to bring your ideas to life.",
    footer: {
      item1: "Contact Information",
      item2: "More Information",
    },
  },
};
