import styled, { keyframes } from "styled-components";
import "./HomePage.css";

export const Span = styled.span`
  display: inline-block;
`;
export const ItemWrapper = styled.div`
  opacity: ${(props) => props.opacity} !important;
  font-size: 50px;
  color: var(--mercagreen);
  text-align: center;
  line-height: 50px;
  :hover {
    cursor: pointer;
  }
`;
export const NavbarAnimationImg = styled.img.attrs((props) => ({
  style: {
    width: `${props.width}%`,

    height: `${(props) => props.height}%`,
  },
}))`
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  z-index: 1;
  opacity: ${(props) => props.opacity};
  transition: opacity 0.5s;
  // width: ${(props) => props.width}%;

  height: auto;
  @media only screen and (min-width: 700px) {
    // height: ${(props) => props.height}%;
  }
`;
export const HomePageFrame5 = styled.div`
  background-color: var(--skyblue);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 5%;
  padding-top: 5%;
  @media only screen and (min-width: 700px) {
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

export const HomePageFrame5Paragraph = styled.p`
  font-size: 12.5px;
  line-height: 24px;
  letter-spacing: 0.05em;
  padding-left: 1em;
  color: var(--mercagreen);
  width: 50%;
  @media only screen and (min-width: 700px) {
    font-size: 20px;
    line-height: normal;
  }
`;

export const HomePageFrame6 = styled.div`
  background-color: ${(props) => props.dynamicBackground};
  padding-top: 60px;
`;
export const ControllerBlock = styled.div`
  background-color: ${(props) => props.dynamicBackground};
  height: 120px;
  width: 100%;
  @media only screen and (max-width: 700px) {
    height: 170px;
  }
`;
export const HomePageFrame8 = styled.div`
  height: 30vh;
  background-color: ${(props) => props.dynamicBackground};
  width: 100%;
  display: flex;
  align-items: center;
`;

export const HomePageFrame2 = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--mercagreen);
  background-color: var(--skyblue);
  @media only screen and (min-width: 700px) {
    padding-bottom: 50px;
  }
  img {
    height: 500px;
  }
`;

export const HomePageFrame2Div1 = styled.div`
  letter-spacing: -0.005em;
  font-size: 28px;
  padding-left: 0.5em;
  padding-right: 2em;
  line-height: 125%;
  overflow: hidden;

  color: var(--mercagreen));
  @media only screen and (min-width: 700px) {
    font-size: 50px;
    line-height: 66px;
    padding-left: 1em;
    padding-right: 0;
    width: calc(50% + 166px);
    align-self: flex-start;
  }
`;
export const HomePageFrame2Div2 = styled.div`
  letter-spacing: -0.005em;
  font-size: 28px;
  padding-left: 0.5em;
  line-height: 125%;
  color: ${(props) => props.color};
  @media only screen and (min-width: 700px) {
    font-size: 50px;
    line-height: 66px;
    width: calc(50% + 166px);
    align-self: flex-end;
    margin-bottom: 0;
  }
`;

export const HomePageFrame2Img = styled.img`
  width: 200px;
  align-self: center;
  @media only screen and (min-width: 700px) {
    width: auto;
  }
`;

export const ScrollSpan = keyframes`
scrollSpan {
  0% {
    height: 0px;
    overflow: hidden;
  }
  2% {
    height: 1px;
    overflow: hidden;
  }
  4% {
    height: 2px;
    overflow: hidden;
  }
  6% {
    height: 3px;
    overflow: hidden;
  }
  8% {
    height: 4px;
    overflow: hidden;
  }
  10% {
    height: 5px;
    overflow: hidden;
  }
  12% {
    height: 6px;
    overflow: hidden;
  }
  14% {
    height: 7px;
    overflow: hidden;
  }
  16% {
    height: 8px;
    overflow: hidden;
  }
  18% {
    height: 9px;
    overflow: hidden;
  }
  20% {
    height: 10px;
    overflow: hidden;
  }
  22% {
    height: 11px;
    overflow: hidden;
  }
  24% {
    height: 12px;
    overflow: hidden;
  }
  26% {
    height: 13px;
    overflow: hidden;
  }
  28% {
    height: 14px;
    overflow: hidden;
  }
  30% {
    height: 15px;
    overflow: hidden;
  }
  32% {
    height: 16px;
    overflow: hidden;
  }
  34% {
    height: 17px;
    overflow: hidden;
  }
  36% {
    height: 18px;
    overflow: hidden;
  }
  38% {
    height: 19px;
    overflow: hidden;
  }
  40% {
    height: 20px;
    overflow: hidden;
  }
  42% {
    height: 21px;
    overflow: hidden;
  }
  44% {
    height: 22px;
    overflow: hidden;
  }
  46% {
    height: 23px;
    overflow: hidden;
  }
  48% {
    height: 24px;
    overflow: hidden;
  }
  50% {
    height: 25px;
    overflow: hidden;
  }
  52% {
    height: 26px;
    overflow: hidden;
  }
  54% {
    height: 27px;
    overflow: hidden;
  }
  56% {
    height: 28px;
    overflow: hidden;
  }
  58% {
    height: 29px;
    overflow: hidden;
  }
  60% {
    height: 30px;
    overflow: hidden;
  }
  62% {
    height: 31px;
    overflow: hidden;
  }
  64% {
    height: 32px;
    overflow: hidden;
  }
  66% {
    height: 33px;
    overflow: hidden;
  }
  68% {
    height: 34px;
    overflow: hidden;
  }
  70% {
    height: 35px;
    overflow: hidden;
  }
  72% {
    height: 36px;
    overflow: hidden;
  }
  74% {
    height: 37px;
    overflow: hidden;
  }
  76% {
    height: 38px;
    overflow: hidden;
  }
  78% {
    height: 39px;
    overflow: hidden;
  }
  80% {
    height: 40px;
    overflow: hidden;
  }
  82% {
    height: 41px;
    overflow: hidden;
  }
  84% {
    height: 42px;
    overflow: hidden;
  }
  86% {
    height: 43px;
    overflow: hidden;
  }
  88% {
    height: 44px;
    overflow: hidden;
  }
  90% {
    height: 45px;
    overflow: hidden;
  }
  92% {
    height: 46px;
    overflow: hidden;
  }
  94% {
    height: 47px;
    overflow: hidden;
  }
  96% {
    height: 48px;
    overflow: hidden;
  }
  99% {
    height: 50px;
    overflow: hidden;
  }
  100% {
    height: auto;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  0% {
    height: 0px;
    overflow: hidden;
  }
  100% {
    height: auto;
  }
}
`;
