import React, { useContext, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation, Link as Link2 } from "react-router-dom";
import { UserActivity } from "../../../context/UserActivity";
import {
  Container,
  ItemContainer,
  Paragraph,
  CrossContainer,
} from "./MobileSectionMenuStyles";
import { TfiClose } from "react-icons/tfi";
import { useEffect } from "react";
const MobileSectionMenu = ({
  items,
  backgroundColor,
  color,
  filter,
  setDisplayFilterMenu,
  section,
}) => {
  const {
    setMobileSectionMenuSelected,
    setFilterSelected,
    filterSelected,
    setEnableScrollToTop,
  } = useContext(UserActivity);
  const location = useLocation();
  const [isSpacesPage, setIsSpacesPage] = useState(
    location.pathname.includes("spaces")
  );
  useEffect(() => {
    if (location.pathname.includes("spaces")) {
      setIsSpacesPage(true);
    }
  }, [Location.pathname]);
  const handleFilter = (filter) => {
    if (filterSelected === filter) {
      setFilterSelected("all");
    } else if (filterSelected !== filter) {
      setFilterSelected(filter);
    }
    setDisplayFilterMenu(false);
  };

  if (filter) {
    return (
      <Container backgroundColor={backgroundColor}>
        <CrossContainer borderColor={color}>
          <TfiClose
            size={25}
            color={color}
            onClick={() => {
              setDisplayFilterMenu(false);
            }}
          />
        </CrossContainer>
        {items !== undefined &&
          items.map((item, index) => {
            console.log(item.filter);
            return (
              <ItemContainer
                borderColor={color}
                onClick={() => {
                  handleFilter(item.filter);
                }}
                key={index}
              >
                <Paragraph color={color}>{item.name}</Paragraph>
              </ItemContainer>
            );
          })}
      </Container>
    );
  } else if (filter !== true && isSpacesPage === false) {
    return (
      <Container backgroundColor={backgroundColor}>
        <CrossContainer borderColor={color}>
          <TfiClose
            size={25}
            color={color}
            onClick={() => {
              setMobileSectionMenuSelected(false);
              setEnableScrollToTop(true);
            }}
          />
        </CrossContainer>
        {items.map((item, index) => {
          return (
            <ItemContainer borderColor={color} key={index}>
              <Link
                to={`/${section}/#${item.idName}`}
                onClick={() => {
                  setMobileSectionMenuSelected(false);
                  setEnableScrollToTop(false);
                  console.log(isSpacesPage);
                }}
              >
                <Paragraph color={color}>{item.name}</Paragraph>
              </Link>
            </ItemContainer>
          );
        })}
      </Container>
    );
  } else if (filter !== true && isSpacesPage === true) {
    return (
      <Container backgroundColor={backgroundColor}>
        <CrossContainer borderColor={color}>
          <TfiClose
            size={25}
            color={color}
            onClick={() => {
              setMobileSectionMenuSelected(false);
              setEnableScrollToTop(true);
            }}
          />
        </CrossContainer>
        {items.map((item, index) => {
          return (
            <ItemContainer borderColor={color} key={index}>
              <Link2
                to={`/spaces/${item.idName}`}
                onClick={() => {
                  setMobileSectionMenuSelected(false);
                  setEnableScrollToTop(false);
                }}
              >
                <Paragraph color={color}>{item.name}</Paragraph>
              </Link2>
            </ItemContainer>
          );
        })}
      </Container>
    );
  }
};

export default MobileSectionMenu;
