import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import esdevenimentImage from "../../../assets/EsdevenimentPage/esdevenimentImage.jpg";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";

import "./EsdevenimentPage.css";
const EsdevenimentPage = () => {
  const { data } = useContext(LanguageActivity);
  //eslint-disable-next-line
  const navigate = useNavigate();
  const { setNavbarColor, setHamburgerColor } = useContext(NavbarActivity);

  useEffect(() => {
    setNavbarColor("var(--mercagreen)");
    setHamburgerColor("var(--mercagreen)");
  }, []);

  const displayMobile = useWindowSize();
  if (displayMobile) {
    return (
      <div className="esdevenimentPage-container">
        {data.esdeveniment !== undefined && (
          <div className="esdevenimentPage-content-container">
            <div className="esdevenimentPage-content-title">
              {data.esdeveniment.title}
            </div>
            <div className="esdevenimentPage-content-text">
              {data.esdeveniment.text}
            </div>
            <div className="esdevenimentPage-content-footer">
              <a
                href="mailto:events@mercantic.com"
                style={{ color: "var(--mercagreen)" }}
              >
                events@mercantic.com
              </a>
              {/* <p
                onClick={() => {
                  navigate("/contact");
                }}
              >
                {data.esdeveniment.footer.item1}
              </p>
              <p>{data.esdeveniment.footer.item2}</p> */}
            </div>
          </div>
        )}
        <img
          src={esdevenimentImage}
          alt="event"
          className="esdevenimentPage-img"
        />
      </div>
    );
  } else if (!displayMobile) {
    return (
      <div className="esdevenimentPage-container">
        {data.esdeveniment !== undefined && (
          <div className="esdevenimentPage-content-container">
            <div className="esdevenimentPage-content-title">
              {data.esdeveniment.title}
            </div>
            <div className="esdevenimentPage-content-text">
              {data.esdeveniment.text}
            </div>
            <div className="esdevenimentPage-content-footer">
              <a
                href="mailto:events@mercantic.com"
                style={{ color: "var(--mercagreen)" }}
              >
                events@mercantic.com
              </a>
              {/* <p
                onClick={() => {
                  navigate("/contact");
                }}
              >
                {data.esdeveniment.footer.item1}
              </p>
              <p>{data.esdeveniment.footer.item2}</p> */}
            </div>
          </div>
        )}
        <div style={{ width: "50%" }}>
          <img
            src={esdevenimentImage}
            alt="event"
            className="esdevenimentPage-img"
          />
        </div>
      </div>
    );
  }
};

export default EsdevenimentPage;
