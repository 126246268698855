import styled from "styled-components";

export const Link = styled.p`
  color: ${(props) => props.color};
  align-self: center;
  margin: 0;
  opacity: ${(props) => props.opacity};
  text-align: center;
  padding-bottom: 13px;
  :hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 700px) {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    line-height: 27px;

    letter-spacing: 0.05em;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  background-color: ${(props) => props.backgroundColor};
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 50;
`;

export const MobileLink = styled.p`
  color: ${(props) => props.color};
  align-self: center;
  margin: 0;
  text-align: center;
  :hover {
    cursor: pointer;
  }
`;

export const MobileLinkContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.backgroundColor};
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
`;
