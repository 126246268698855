export const empresaData = {
  catala: {
    title: "Vols llogar un espai? ",
    text: "Si ets una empresa o freelance t’oferim els següents serveis: Lloguer de localització per shootings o rodatges, lloguer d'espais per treballar el dia a dia, impartir els teus tallers... Lloguer de mobles i antiguitats per produccions i esdeveniments. Si tens alguna proposta ens encantaria escoltar-te!",
    footer: {
      item1: "comunicacio@mercantic.com",
    },
  },
  castella: {
    title: "¿Quieres alquilar un espacio? ",
    text: "Si eres una empresa o freelance, te ofrecemos los siguientes servicios: Alquiler de localizaciones para sesiones de fotos o rodajes, alquiler de espacios para trabajar en el día a día, impartir tus talleres, etc. Alquiler de mobiliario y antigüedades para producciones y eventos. Y si tienes alguna propuesta, ¡nos encantaría escucharte!",
    footer: {
      item1: "comunicacio@mercantic.com.",
    },
  },
  angles: {
    title: "Do you want to rent an space?",
    text: "We offer location hire and additional services. Get in touch if you are a business or individual looking for: Location hire for photoshoots or filming, office space for daily work or conducting workshops. Furniture and antique hire for productions and events. Any other proposal - we would love to hear from you!",
    footer: {
      item1: "comunicacio@mercantic.com.",
    },
  },
};
