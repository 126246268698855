//REACT
import React, { useEffect } from "react";
//STYLES
import "./EspaisDefaultPage.css";

//COMPONENTS

const EspaisDefaultPage = ({
  title,
  text,
  verticalNavbar,
  setController,
  id,
  paramsSection,
  text2,
}) => {
  const handleClick = (key) => {
    setController({
      boolean: true,
      key: key + 1,
    });
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      const scrollElementIntoView = (element) => {
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      };

      const element = document.getElementById(paramsSection);
      scrollElementIntoView(element);
    });
  }, []);

  return (
    <div className={"espaisDefaultPage-container"} id={id}>
      <div className="top-wrapper">
        <p className="top-wrapper-title">
          {title.br1}
          {title.br2 !== undefined && (
            <>
              <br />
              {title.br2}
            </>
          )}
        </p>
      </div>
      <div className="middle-wrapper">
        <p className="middle-wrapper-text">{text}</p>
        <br />
        <br />
        {text2 !== undefined && <p className="middle-wrapper-text">{text2}</p>}
      </div>
      {verticalNavbar !== undefined && (
        <div className="vertical-navbar">
          {verticalNavbar.map((item) => {
            return (
              <p
                onClick={() => {
                  handleClick(item.key);
                }}
                style={{ fontSize: "14px" }}
              >
                {item.text}
              </p>
              // </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default EspaisDefaultPage;
