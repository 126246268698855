export const tallerData = {
  catala: {
    moreInfo: "Més informació",
    initialPage: {
      section: "Tallers",
      text: "Ceràmica, tapís, restauració de mobles, floristeria...",

      link: "Agenda",
      laptop: [
        { name: "Llanes, cordes i fils", idName: "llanes" },
        { name: "Ceràmica", idName: "ceramica" },
        { name: "Restauració de mobles", idName: "restauraciomobles" },
        { name: "Extraescolars per a nens", idName: "extraescolars" },
        { name: "Vitralls", idName: "vitralls" },
        { name: "Floristeria", idName: "floristeria" },
      ],
      page: "workshops",
    },
    defaultPage: [
      {
        key: 0,
        email: "athenea.harrington@gmail.com",
        place: "The Knots Lab",
        title: {
          br1: "Llanes,",
          br2: "cordes i fils",
        },
        text: "Per a fer peces d’artesania textil, llibres i peces d’art, The Knots Lab col·labora amb diversos artistes que imparteixen els tallers amb gran dedicació i passió. Disponibles els següents tallers, segons agenda.",

        list: [
          "Tapis",
          "Knitting",
          "Macramé",
          "Agulles Circulars",
          "Mini Tapis",
          "Macramé",
          "Ganxet",
          "Màgia amb cordes (per a nens)",
          "Màgia amb cordes, per a adults",
          "Scrapbook Mix Media",
        ],
        photo: "theknotstudioPhoto.svg",
      },
      {
        key: 1,
        place: "Mërm Studio",
        email: "imalbalopezmar@gmail.com",
        title: {
          br1: "Ceràmica",
        },
        text: "Crea les teves peces de ceràmica. Tasses, gerros, plats, inclús el teu set d’esmorzar. Aquests cursos poden adaptar-se a diferents nivells. Disponibles els següents tallers, segons agenda.",
        list: [
          "Introducció al torn",
          "Crea la teva tassa",
          "Crea el teu gerro",
          "Crea el teu set d'esmorzar",
          "Pottery & Wine",
        ],
        photo: "mërmstudioPhoto.svg",
      },
      {
        key: 2,
        place: "Escola de Restauració",
        email: "restauraciomobles13@gmail.com",
        title: {
          br1: "Restauració ",
          br2: "de mobles",
        },
        text: "Classes pràctiques i personalitzades per iniciar-te en la restauració o per aprofundir en els diferents aspectes d’aquesta feina artesanal. Disponible segons agenda. ",
        list: ["Restauració de mobles", "Iniciació a la pintura de mobles"],
        photo: "escoladerestauracioPhoto.svg",
      },
      {
        key: 3,
        place: "Petit Taché",
        email: "clara.tache@gmail.com",
        title: {
          br1: "Extraescolars ",
          br2: "per a nens",
        },
        text: "L'activitat extraescolar d'anglès i art és una activitat íntegrament en anglès impartida per professors nadius/bilingües especialitzats en l'ensenyament de l'anglès i de l'art. Disponible segons agenda i amb un màxim de vuit alumnes per activitat.",
        list: ["Extraescolars d'art en anglès", "Casal d'estiu"],
        photo: "petittachePhoto.svg",
      },
      {
        key: 4,
        place: "Metaphora Vitralls",
        email: "metaphoravitralls@gmail.com",
        title: {
          br1: "Llums, miralls ",
          br2: "i vidrieres",
        },
        text: "El vitrall  tradicionalment s’utilitzava antigament per a l’elaboració de finestres, però en l’actualitat les possibilitats de realització de diferents objectes s’han multiplicat. Aquest curs va dirigit a tot tipus de persones: principiants, intermedis i avançats. Disponible segons agenda.",
        list: ["Iniciació al Vitrall"],
        photo: "metaphoravitrallsPhoto.svg",
      },
      {
        key: 5,
        place: "Su jardin secreto",
        email: "info@sugarden.es",
        title: {
          br1: "Floristeria",
        },
        text: "Al Barri de les Casetes, hi ha la floristería de la Susana. Un espai on desconectar per unes hores i aprendre les tècniques del treball amb flors preservades i fresques. Els tallers venen acompanyats d’un agradable esmorzar/berenar a la fresca al seu jardinet.",
        list: [
          "Taller centre Floral",
          "Taller cúpula floral de vidre",
          "Taller garden",
          "Taller dia de la mare",
        ],
        photo: "sujardinsecretoPhoto.svg",
      },
    ],
  },
  castella: {
    moreInfo: "Más información",
    initialPage: {
      section: "Talleres",
      text: "Cerámica, tapices, restauración de muebles, floristería...",
      link: "Agenda",
      laptop: [
        { name: "Lanas, cuerdas e hilos", idName: "llanes" },
        { name: "Cerámica", idName: "ceramica" },
        { name: "Restauración de muebles", idName: "restauraciomobles" },
        { name: "Extraescolares para niños", idName: "extraescolars" },
        { name: "Vitrales", idName: "vitralls" },
        { name: "Floristería", idName: "floristeria" },
      ],
      page: "workshops",
    },
    defaultPage: [
      {
        key: 0,
        place: "The Knots Lab",
        email: "athenea.harrington@gmail.com",
        title: {
          br1: "Lanas,",
          br2: "cuerdas e hilos",
        },
        text: "Para hacer piezas de artesanía textil, libros y obras de arte, The Knots Lab colabora con diversos artistas que imparten los talleres con gran dedicación y pasión. Disponibles los siguientes talleres, según agenda.",

        list: [
          "Tapices",
          "Knitting",
          "Macramé",
          "Agujas Circulares",
          "Mini Tapices",
          "Macramé",
          "Ganchillo",
          "Magia con cuerdas (para niños)",
          "Magia con cuerdas (para adultos)",
          "Scrapbook Mix Media",
        ],
        photo: "theknotstudioPhoto.svg",
      },
      {
        key: 1,
        place: "Mërm Studio",
        email: "imalbalopezmar@gmail.com",
        title: {
          br1: "Cerámica",
        },
        text: "Crea tus propias piezas de cerámica. Tazas, jarrones, platos, incluso tu set de desayuno. Estos cursos pueden adaptarse a diferentes niveles. Disponibles los siguientes talleres, según agenda.",
        list: [
          "Introducción al torno",
          "Crea tu taza",
          "Crea tu jarrón",
          "Crea tu set de desayuno",
          "Pottery & Wine",
        ],
        photo: "mërmstudioPhoto.svg",
      },
      {
        key: 2,
        place: "Escuela de Restauración",
        email: "restauraciomobles13@gmail.com",
        title: {
          br1: "Restauración ",
          br2: "de muebles",
        },
        text: "Clases prácticas y personalizadas para iniciarte en la restauración o profundizar en los diferentes aspectos de este trabajo artesanal. Disponible según agenda.",
        list: ["Restauración de muebles", "Iniciación a la pintura de muebles"],
        photo: "escoladerestauracioPhoto.svg",
      },
      {
        key: 3,
        place: "Petit Taché",
        email: "clara.tache@gmail.com",
        title: {
          br1: "Extraescolares ",
          br2: "para niños",
        },
        text: "La actividad extraescolar de inglés y arte es una actividad íntegramente en inglés impartida por profesores nativos/bilingües especializados en la enseñanza del inglés y el arte. Disponible según agenda y con un máximo de ocho alumnos por actividad.",
        list: ["Extraescolar de arte en inglès", "Casal de verano"],
        photo: "petittachePhoto.svg",
      },
      {
        key: 4,
        place: "Metaphora Vitralls",
        email: "metaphoravitralls@gmail.com",
        title: {
          br1: "Luces, espejos  ",
          br2: "y vitrales",
        },
        text: "El vitral tradicionalmente se utilizaba antiguamente para la elaboración de ventanas, pero en la actualidad las posibilidades de realizar diferentes objetos se han multiplicado. Este curso está dirigido a todo tipo de personas: principiantes, intermedios y avanzados. Disponible según agenda.",
        list: ["Iniciación al vitral"],
        photo: "metaphoravitrallsPhoto.svg",
      },
      {
        key: 5,
        place: "Su jardín secreto",
        email: "info@sugarden.es",
        title: {
          br1: "Floristería",
        },
        text: "En el Barri de les Casetes, se encuentra la floristería de Susana. Un espacio donde desconectar durante unas horas y aprender las técnicas del trabajo con flores preservadas y frescas. Los talleres vienen acompañados de un agradable desayuno/merienda al aire libre en su jardín.",
        list: [
          "Taller centro Floral",
          "Taller cúpula floral de vidrio",
          "Taller garden",
          "Taller día de la madre",
        ],
        photo: "sujardinsecretoPhoto.svg",
      },
    ],
  },
  angles: {
    moreInfo: "More information",
    initialPage: {
      section: "Workshops",
      text: "Ceramics, tapestry, furniture restoration floristry... ",
      link: "Agenda",
      laptop: [
        { name: "Wool fabric, cords & threads", idName: "llanes" },
        { name: "Ceramics", idName: "ceramica" },
        { name: "Furniture Restoration", idName: "restauraciomobles" },
        {
          name: "After-school activities for children",
          idName: "extraescolars",
        },
        { name: "Stained Glasswork", idName: "vitralls" },
        { name: "Floristry", idName: "floristeria" },
      ],
      page: "workshops",
    },
    defaultPage: [
      {
        key: 0,
        place: "The Knots Lab",
        email: "athenea.harrington@gmail.com",
        title: {
          br1: "Wool,",
          br2: "Cords & Threads",
        },
        text: "To create textile crafts, books, and art pieces, The Knots Lab offers workshops hosted by various artists in the subjects of textile manufacturing, book-making, and art production. Please see what’s currently on:",

        list: [
          "Tapestry",
          "Knitting",
          "Macramé",
          "Circular Needles",
          "Miniature Tapestry",
          "Macramé",
          "Crochet",
          "Magic with Ropes (for children)",
          "Magic with Ropes (for adults)",
          "Scrapbook Mix Media",
        ],
        photo: "theknotstudioPhoto.svg",
      },
      {
        key: 1,
        place: "Mërm Studio Ceramics",
        email: "imalbalopezmar@gmail.com",
        title: {
          br1: "Ceramics",
        },
        text: "Create your own ceramic cups, jugs, plates, and even your breakfast set. These courses can be adapted to different levels. The following workshops are available subject to scheduling. ",
        list: [
          "Introduction to the Potter's Wheel",
          "Create your own cup",
          "Create your own jug",
          "Create your own breakfast set",
          "● Pottery & Wine",
        ],
        photo: "mërmstudioPhoto.svg",
      },
      {
        key: 2,
        place: "School of Furniture Restoration",
        email: "restauraciomobles13@gmail.com",
        title: {
          br1: "Furniture ",
          br2: "Restoration",
        },
        text: "Hands-on introductory classes in furniture restoration or deeper dives into this artesanal craft. For availability of classes see the schedule.",
        list: ["Furniture Restoration", "Introduction to Furniture Painting"],
        photo: "escoladerestauracioPhoto.svg",
      },
      {
        key: 3,
        place: "Petit Taché",
        email: "clara.tache@gmail.com",
        title: {
          br1: "After-school activities",
          br2: "for children",
        },
        text: "The english and art after-school program is taught entirely in English by native/bilingual teachers specialised in english and arts education. Subject to scheduling, each activity has a maximum of eight students.",
        list: ["After-school art program in English", "Summer camp "],
        photo: "petittachePhoto.svg",
      },
      {
        key: 4,
        place: "Metaphora Vitralls (Stained Glass)",
        email: "metaphoravitralls@gmail.com",
        title: {
          br1: "Lamps, mirrors  ",
          br2: "and stained",
          br3: "glass",
        },
        text: "Stained glass has traditionally been used for construction of windows. Nowadays the possibilities for creating different objects have multiplied. This course is aimed for students of all experience levels, from beginners to advanced. See the schedule for course availability. ",
        list: ["Introduction to Stained Glass"],
        photo: "metaphoravitrallsPhoto.svg",
      },
      {
        key: 5,
        place: "Su jardín secreto",
        email: "info@sugarden.es",
        title: {
          br1: "Flowershop",
        },
        text: "Susana’s flower shop is located in the Casetes Neighbourhood. It's a space where you can disconnect for a few hours and learn techniques in arranging preserved and fresh flowers. Complementary to the workshop is a pleasant appetiser in her garden.",
        list: [
          "Floral Centerpiece Workshop",
          "Glass Floral Dome Workshop",
          "Garden Workshop",
          "Mother's Day Workshop",
        ],
        photo: "sujardinsecretoPhoto.svg",
      },
    ],
  },
};
