export const newsletterData = {
  catala: {
    text: "Inscriu-te a la nostra Newsletter",
    placeholder: "",
  },
  castella: {
    text: "Inscríbete a nuestra Newsletter",
    placeholder: "",
  },
  angles: {
    text: "Subscribe to our Newsletter",
    placeholder: "",
  },
};
