import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.backgroundColor};
  background-image: url(${(props) => props.backgroundImage});
  height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media only screen and (min-width: 700px) {
    height: 100vh;
    width: 50%;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.zIndex};
  position: absolute;
`;
export const Wrapper = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: 31;
  @media only screen and (min-width: 700px) {
    height: ${(props) => props.height}%;
    padding-left: 20px;
    padding-top: 20px;
  }
`;
export const FirstSquaredRow = styled.div`
  height: ${(props) => props.height}%;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
`;

export const SquareSquaredRow = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  background-color: ${(props) => props.color};
`;

export const SquareSquaredRow2 = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  background-color: ${(props) => props.color};
`;

export const Paragraph = styled.p`
  color: ${(props) => props.color};
  font-size: 35px;
  line-height: 41px;
  letter-spacing: -0.005em;
  margin: 0;
  padding-top: 15px;
  @media only screen and (min-width: 700px) {
    padding-top: 0;
    // padding-left: 25px;
    font-size: 60px;
    line-height: 66px;
    letter-spacing: -0.005em;
  }
`;

export const Section = styled.p`
  color: ${(props) => props.color};
  margin: 0;
  @media only screen and (min-width: 700px) {
    // padding-left: 25px;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.05em;
  }
`;

export const Link = styled.p`
  color: ${(props) => props.color};
  align-self: center;
  margin: 0;
  text-align: center;
  padding-bottom: 13px;
  :hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 700px) {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.05em;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  background-color: ${(props) => props.backgroundColor};
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
`;
