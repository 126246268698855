import React from "react";
import "./MercatsDefaultPage.css";
const MercatsDefaultPage = ({ title, text, contact, id }) => {
  return (
      <div className="mercatsDefaultPage-container" id={id}>
        <div className="top-wrapper">
          <p className="top-wrapper-title">
            {title.br1}
            <br />
            {title.br2}
          </p>
        </div>
        <div className="middle-wrapper">
          <p className="middle-wrapper-text">{text}</p>
        </div>
        {contact !== null ? (
          <div className="mercatsPage-contact-wrapper">
            <p>
              {contact.br1}
              <a href="mailto:market@mercantic.com" className="market-a">
                {contact.email}
              </a>
            </p>
          </div>
        ) : null}
      </div>
 
  );
};

export default MercatsDefaultPage;
