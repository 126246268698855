export const footerData = {
  catala: {
    linksRow1: [
      {
        item: "Cookies",
        key: 0,
      },
      {
        item: "Agenda",
        key: 1,
      },
      {
        item: "Contacte",
        key: 2,
      },
    ],
    footerWork: "Vols treballar amb nosaltres?",
    footerInfoUpper: [
      {
        p: "AV RIUS I TAULET 120",
      },
      {
        p: "08173, SANT CUGAT DEL VALLÈS",
      },
      {
        p: "info@mercantic.com",
      },
      {
        p: "T: +34 93 674 49 50",
      },
    ],
    footerInfoLower: [
      {
        p: "De dimarts a divendres de 10h a 20h",
      },
      {
        p: "Dissabtes i Diumenges de 10h a 15h.",
      },
    ],
    footerText:
      "Mercat permanent d'antiquaris i brocanters. Entrada gratuïta de dimarts a dissabte. Els diumenges l'entrada té un cost de 2 € d'11 h a 14 h. Infants fins a 12 anys i persones amb diversitat funcional més acompanyant, entrada gratuïta.",
  },
  castella: {
    linksRow1: [
      {
        item: "Cookies",
        key: 0,
      },
      {
        item: "Agenda",
        key: 1,
      },
      {
        item: "Contacto",
        key: 2,
      },
    ],
    footerWork: "Quieres trabajar con nosotros?",
    footerInfoUpper: [
      {
        p: "AV RIUS I TAULET 120",
      },
      {
        p: "08173, SANT CUGAT DEL VALLÈS",
      },
      {
        p: "info@mercantic.com",
      },
      {
        p: "T: +34 93 674 49 50",
      },
    ],
    footerInfoLower: [
      {
        p: "De Martes a Viernes de 10h a 20h.",
      },
      {
        p: "Sábados y Domingos de 10h a 15h.",
      },
    ],
    footerText:
      "Mercado permanente de anticuarios y brocantes. Entrada gratuita de martes a sábado. Los domingos la entrada tiene un precio de 2 € de 11 h a 14 h. Entrada gratuita para niños hasta 12 años y personas con diversidad funcional más acompañante.",
  },
  angles: {
    linksRow1: [
      {
        item: "Cookies",
        key: 0,
      },
      {
        item: "Agenda",
        key: 1,
      },
      {
        item: "Contact",
        key: 2,
      },
    ],
    footerWork: "Do you want to work with us?",
    footerInfoUpper: [
      {
        p: "AV RIUS I TAULET 120",
      },
      {
        p: "08173, SANT CUGAT DEL VALLÈS",
      },
      {
        p: "info@mercantic.com",
      },
      {
        p: "T: +34 93 674 49 50",
      },
    ],
    footerInfoLower: [
      {
        p: "Tuesday to Friday, from 10 am to 8 pm.",
      },
      {
        p: "Saturdays and Sundays from 10 am to 3 pm.",
      },
    ],
    footerText:
      "Antique furniture and flea market. Free admission from Tuesday to Saturday. Sunday admission costs €2 between 11 am and 2 pm. Free admission for children 12 years or younger and persons of disability and their guardian.",
  },
};
