import styled from "styled-components";

export const Link = styled.p`
  color: ${(props) => props.color};
  align-self: center;
  margin: 0;
  text-align: center;
  padding-bottom: 13px;
  :hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 700px) {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    line-height: 27px;
    opacity: ${(props) => props.opacity};
    letter-spacing: 0.05em;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  background-color: ${(props) => props.backgroundColor};
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 45;
`;
