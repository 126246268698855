//REACT
import React, { useContext, useEffect } from "react";
import Footer from "../../Individual/Footer/Footer";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { HashLink } from "react-router-hash-link";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { useScroll } from "../../../custom-hooks/useScroll";

//STYLES
import "./MercatsPage.css";
import { LinkContainer, Link } from "./MercatsStyles";
//IMAGES
import footerLogo from "../../../assets/Footer/MercatsFooter.svg";
//COMPONENTS
import InitialPage from "../../Individual/InitialPage/InitialPage";
import MercatsDefaultPage from "../../Individual/DefaultPages/MercatsDefaultPage/MercatsDefaultPage";
import DefaultCarousel from "../../Individual/DefaultPages/DefaultCarousel/DefaultCarousel";
import { permanentImages, setmanalImages } from "../../../data/ImagesData";
import { UserActivity } from "../../../context/UserActivity";
import { IKImage } from "imagekitio-react";

const MercatsPage = () => {
  const { data } = useContext(LanguageActivity);
  const { dropdownMenuSelected, setEnableScrollToTop } =
    useContext(UserActivity);
  const displayMobile = useWindowSize();
  const { setNavbarColor, setHamburgerColor } = useContext(NavbarActivity);
  const { scrollPercent } = useScroll();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--pagination-color",
      "var(--skyblue)"
    );
    setNavbarColor("var(--mercared)");
    setHamburgerColor("var(--mercared)");
    document.documentElement.style.setProperty("--swiper-wrapper-padding", "0");
  }, []);

  useEffect(() => {
    if (!displayMobile) {
      if (scrollPercent > 103) {
        setNavbarColor("var(--skyblue)");
        setHamburgerColor("var(--mercared)");
      } else if (scrollPercent < 103) {
        setNavbarColor("var(--mercared)");
        setHamburgerColor("var(--mercared)");
      }
    } else if (displayMobile) {
      if (!dropdownMenuSelected) {
        if (scrollPercent > 103) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--skyblue)");
        } else if (scrollPercent < 103) {
          setNavbarColor("var(--mercared)");
          setHamburgerColor("var(--mercared)");
        }
      }
    }
  }, [scrollPercent, dropdownMenuSelected]);

  const handleOpacity = (index) => {
    if (index === 0) {
      if (scrollPercent <= 150) {
        return 1;
      } else return 0.5;
    }
    if (index === 1) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 150) {
        return 1;
      } else return 0.5;
    }
  };
  if (displayMobile) {
    return (
      <>
        {data.mercats !== undefined && (
          <>
            <InitialPage
              color={"var(--mercared)"}
              backgroundColor={"var(--skyblue)"}
              section={data.mercats.initialPage.section}
              text={data.mercats.initialPage.text}
              map={data.mercats.initialPage.laptop}
              image1={
                "https://ik.imagekit.io/gwms6thri/InitialPage/backgroundImage.jpg?updatedAt=1690295947158"
              }
              image2={
                "https://ik.imagekit.io/gwms6thri/InitialPage/MERCATS.svg?updatedAt=1690294498995"
              }
            />
            <div style={{ position: "sticky", zIndex: 2, opacity: 0.99 }}>
              <MercatsDefaultPage
                text={data.mercats.defaultPage[0].text}
                title={data.mercats.defaultPage[0].title}
                contact={null}
                id={"permanent"}
              />
            </div>
            <DefaultCarousel images={permanentImages} />
            <div style={{ position: "sticky", zIndex: 2, opacity: 0.99 }}>
              <MercatsDefaultPage
                text={data.mercats.defaultPage[1].text}
                title={data.mercats.defaultPage[1].title}
                contact={data.mercats.defaultPage[1].contact}
                id={"setmanal"}
              />
            </div>
            <DefaultCarousel images={setmanalImages} market={true} />
          </>
        )}
        <Footer
          backgroundColor={"var(--skyblue)"}
          color={"var(--mercared)"}
          logo={footerLogo}
        />
      </>
    );
  } else if (!displayMobile) {
    return (
      <div className="mercatsPage-container">
        {data.mercats !== undefined && (
          <>
            <div className="laptop-wrapper-initialPage">
              <InitialPage
                color={"var(--mercared)"}
                backgroundColor={"var(--skyblue)"}
                section={data.mercats.initialPage.section}
                text={data.mercats.initialPage.text}
                map={data.mercats.initialPage.laptop}
                page={data.mercats.initialPage.page}
                firstSquareWidth={"40%"}
                secondSquareWidth={"60%"}
                firstSquareHeight={16}
                secondSquareHeight={16}
              />
              <IKImage
                src={
                  "https://ik.imagekit.io/gwms6thri/InitialPage/backgroundImage.jpg?updatedAt=1690295947158"
                }
                lqip={{ active: true, quality: 20, blur: 10 }}
                alt="initial"
              />
            </div>
            <div style={{ display: "flex" }}>
              <div className="laptop-defaultPage-wrapper">
                <MercatsDefaultPage
                  text={data.mercats.defaultPage[0].text}
                  title={data.mercats.defaultPage[0].title}
                  contact={null}
                  id={data.mercats.initialPage.laptop[0].idName}
                />
                <MercatsDefaultPage
                  text={data.mercats.defaultPage[1].text}
                  title={data.mercats.defaultPage[1].title}
                  contact={data.mercats.defaultPage[1].contact}
                  id={data.mercats.initialPage.laptop[1].idName}
                />
              </div>
              <div style={{ width: "50%" }}>
                <DefaultCarousel images={permanentImages} />
                <DefaultCarousel images={setmanalImages} />
              </div>
            </div>
            <div style={{ height: "100%", display: "flex" }}>
              <Footer
                color={"var(--mercared)"}
                backgroundColor={"var(--skyblue)"}
                logo={footerLogo}
              />
            </div>
          </>
        )}
        {data.mercats !== undefined && (
          <LinkContainer backgroundColor={"var(--skyblue)"}>
            {data.mercats.initialPage.laptop.map((item, index) => {
              return (
                <HashLink
                  to={`/markets#${item.idName}`}
                  scroll={(el) =>
                    el.scrollIntoView({ behavior: "smooth", block: "end" })
                  }
                  key={index}
                  onClick={() => {
                    setEnableScrollToTop(false);
                  }}
                >
                  <Link
                    color={"var(--mercared)"}
                    opacity={handleOpacity(index)}
                  >
                    {item.name}
                  </Link>
                </HashLink>
              );
            })}
          </LinkContainer>
        )}
      </div>
    );
  }
};

export default MercatsPage;
