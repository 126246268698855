//REACT
import React from "react";
import { useWindowSize } from "../../../custom-hooks/useWindowSize.js";

import { IKImage } from "imagekitio-react";
//STYLES
import {
  Container,
  Paragraph,
  Wrapper,
  Section,
  FirstSquaredRow,
  SquareSquaredRow,
} from "./InitialPageStyles.js";

const InitialPage = ({
  section,
  text,
  backgroundColor,
  color,
  image1,
  image2,
  firstSquareWidth,
  secondSquareWidth,
  firstSquareHeight,
  secondSquareHeight,
  gastronomy,
}) => {
  const displayMobile = useWindowSize();
  if (!displayMobile) {
    return (
      <Container backgroundColor={backgroundColor}>
        <FirstSquaredRow
          backgroundColor={gastronomy === true ? color : backgroundColor}
          height={firstSquareHeight}
        >
          <SquareSquaredRow
            color={gastronomy === true ? backgroundColor : color}
            width={firstSquareWidth}
          ></SquareSquaredRow>
        </FirstSquaredRow>
        <Wrapper height={100 - (firstSquareHeight + secondSquareHeight)}>
          <div>
            <Section color={color}> {section}</Section>
            <Paragraph color={color}>{text}</Paragraph>
          </div>
        </Wrapper>
        <FirstSquaredRow
          backgroundColor={gastronomy === true ? backgroundColor : color}
          height={secondSquareHeight}
        >
          <SquareSquaredRow
            color={gastronomy === true ? color : backgroundColor}
            width={secondSquareWidth}
          ></SquareSquaredRow>
        </FirstSquaredRow>
      </Container>
    );
  } else if (displayMobile) {
    return (
      <Container backgroundColor={backgroundColor}>
        <IKImage
          src={image1}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: "20",
            objectFit: "cover",
          }}
          lqip={{ active: true, quality: 20, blur: 10 }}
        />
        <IKImage
          src={image2}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: "30",
            objectFit: "cover",
          }}
          lqip={{ active: true, quality: 20, blur: 10 }}
        />
        <Wrapper>
          <div>
            <Section color={color}> {section}</Section>
            <Paragraph color={color}>{text}</Paragraph>
          </div>
        </Wrapper>
      </Container>
    );
  }
};

export default InitialPage;
