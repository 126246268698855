export const mercatsData = {
  catala: {
    initialPage: {
      section: "Mercats",
      text: "A Mercantic oferim dos tipus de mercats, el mercat permanent i el mercat d'hivern.",

      laptop: [
        { name: "Centre d'Antiguitats permanent", idName: "permanent" },
        { name: "Mercat de diumenge", idName: "setmanal" },
      ],
      page: "markets",
    },
    defaultPage: [
      {
        key: 0,
        title: {
          br1: "Centre",
          br2: "d'Antiguitats permanent",
        },
        text: "Fa més de 30 anys, Mercantic ofereix un mercat permanent d'antiguitats. La iniciativa va començar a prendre forma dins de la Nau Central de l'antiga fàbrica, que es va transformar en mig centenar de botigues. Posteriorment, es va ampliar l'espai amb naus, locals i casetes.",
      },
      {
        key: 1,
        title: {
          br1: "Mercat",
          br2: "de diumenge",
        },
        text: "Els diumenges, a part del mercat permanent, se sumen més de 50 parades de brocanteria, articles de segona mà i col·leccionisme a l’aire lliure. Cada diumenge de 10h a 15h al pati d'entrada. ",
        contact: {
          br1: "Si hi vols participar, envia un mail a ",
          email: "market@mercantic.com",
        },
      },
    ],
  },
  castella: {
    initialPage: {
      section: "Mercados",
      text: "En Mercantic ofrecemos dos tipos de mercados, el mercado permanente y el mercado de invierno.",
      laptop: [
        { name: "Centro de Antigüedades permanente", idName: "permanent" },
        { name: "Mercado de domingo", idName: "setmanal" },
      ],
      page: "markets",
    },
    defaultPage: [
      {
        key: 0,
        title: {
          br1: "Centro de",
          br2: "Antigüedades Permanente",
        },
        text: "Hace más de 30 años, Mercantic ofrece un mercado permanente de antigüedades. La iniciativa comenzó a tomar forma dentro de la Nau Central de la antigua fábrica, que se transformó en medio centenar de tiendas. Posteriormente, se amplió el espacio con naves, locales y casitas.",
      },
      {
        key: 1,
        title: {
          br1: "Mercado",
          br2: "de domingo",
        },
        text: "Los domingos, además del mercado permanente, se suman más de 50 puestos de brocante, artículos de segunda mano y coleccionismo al aire libre. Cada domingo de 10h a 15h en el patio de entrada.",
        contact: {
          br1: "Si quieres participar, envía un correo electrónico a ",
          email: "market@mercantic.com",
        },
      },
    ],
  },
  angles: {
    initialPage: {
      section: "Markets",
      text: "At Mercantic we offer two types of markets: the permanent market and the winter market.",

      laptop: [
        { name: "Permanent Antiquities Centre", idName: "permanent" },
        { name: "Sunday Market", idName: "setmanal" },
      ],
      page: "markets",
    },
    defaultPage: [
      {
        key: 0,
        title: {
          br1: "Permanent",
          br2: "Antiquities Centre",
        },
        text: "For over 30 years, Mercantic has been offering a permanent antique market. The initiative started taking shape inside the Central Hall of the factory, which transformed into fifty shops. Later on, the space was expanded with warehouses, premises, and small houses.",
      },
      {
        key: 1,
        title: {
          br1: "Sunday",
          br2: "Market",
        },
        text: "On Sundays, in addition to the permanent market, over 50 stalls offer second-hand goods, bric-abrac, and collectibles in an open air setting. Every Sunday from 10 a.m. to 3 p.m. at the entrance courtyard.",
        contact: {
          br1: "To participate, send an email to ",
          email: "market@mercantic.com",
        },
      },
    ],
  },
};
