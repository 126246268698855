import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LanguageActivityProvider } from "./context/LanguageActivity";
import { UserActivityProvider } from "./context/UserActivity";
import { NavbarActivityProvider } from "./context/NavbarActivity";
import { IKContext } from "imagekitio-react";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LanguageActivityProvider>
      <IKContext
        publicKey="public_AD0oHm/Yd02HfaHdN6AJrfy7K8U="
        urlEndpoint="https://ik.imagekit.io/gwms6thri"
        transformationPosition="path"
      >
        <NavbarActivityProvider>
          <UserActivityProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </UserActivityProvider>
        </NavbarActivityProvider>
      </IKContext>
    </LanguageActivityProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
