import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [displayMobile, setDisplayMobile] = useState(window.innerWidth < 568);

  const handleResize = () => {
    setDisplayMobile(window.innerWidth < 568);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, [window.innerWidth]);

  return displayMobile;
};
