//REACT
import React, { useContext, useEffect, useState } from "react";
import { UserActivity } from "../../../context/UserActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";

import { HashLink } from "react-router-hash-link";

//STYLES
import "./AgendaCard.css";
import { NavbarActivity } from "../../../context/NavbarActivity";

const AgendaCard = ({
  month,
  day,
  number,
  section,
  subsection,
  type,
  time,
  book,
  bookAddressMail,
  bookAddressRoute,
  externalBookAddress,
  filter,
  filter2,
  activities,
  route,
  date,
  holidays,
  externalInfo,
}) => {
  const {
    setScheduleSelected,
    filterSelected,
    setEspaiController,
    setScheduleToSpacesController,
  } = useContext(UserActivity);
  const displayMobile = useWindowSize();
  const [display, setDisplay] = useState(true);
  const { setNavbarBackground } = useContext(NavbarActivity);

  useEffect(() => {
    setScheduleSelected(true);
    setDisplay(true);
  }, []);

  useEffect(() => {
    if (
      filter !== filterSelected &&
      filterSelected !== "all" &&
      filter2 !== filterSelected
    ) {
      setDisplay(false);
    } else if (
      filter === filterSelected ||
      filterSelected === "all" ||
      filter2 === filterSelected
    ) {
      setDisplay(true);
    } else if (filterSelected !== "all" && activities === undefined) {
      setDisplay(false);
    }
  }, [filterSelected]);

  if (!displayMobile) {
    return (
      <div
        className={
          display ? "agenda-card__container" : "agenda-card__container display"
        }
      >
        {holidays === undefined && (
          <div className="agenda-card__info">
            <div className="agenda-card__info__left">
              <div className="agenda-card__info__left__upper">
                <div className="agenda-card__info__left__upper__section">
                  <p>{section}</p>
                </div>
                <div className="agenda-card__info__left__upper__subsection">
                  <p>{subsection}</p>
                </div>
              </div>
              <div className="agenda-card__info__left__lower">
                <div className="agenda-card__info__left__lower__time">
                  <p>{time}</p>
                </div>
              </div>
            </div>
            <div className="agenda-card__info__right">
              <div className="agenda-card__info__right__upper">
                <div className="agenda-card__info__right__upper__info">
                  {route !== "" && externalInfo === "" && (
                    <HashLink
                      smooth
                      to={`/${route}`}
                      style={{
                        textDecoration: "none",
                        fontFamily: "Affairs",
                        color: "var(--mercagreen)",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        if (filter === "concerts") {
                          setEspaiController(1);
                        }
                        setNavbarBackground("transparent");
                        setScheduleToSpacesController(true);
                      }}
                    >
                      Info
                    </HashLink>
                  )}
                  {externalInfo !== "" && route === "" && (
                    <a
                      href={externalInfo}
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        fontFamily: "Affairs",
                        color: "var(--mercagreen)",
                        fontSize: "14px",
                      }}
                    >
                      Info
                    </a>
                  )}
                </div>
                <div className="agenda-card__info__right__upper__book">
                  {bookAddressMail !== "" &&
                    externalBookAddress === "" &&
                    bookAddressRoute === "" && (
                      <a
                        href={`mailto:${bookAddressMail}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          fontFamily: "Affairs",
                          color: "var(--mercagreen)",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {book}
                      </a>
                    )}
                  {bookAddressRoute !== "" &&
                    externalBookAddress === "" &&
                    bookAddressMail === "" && (
                      <HashLink
                        smooth
                        to={`/${bookAddressRoute}`}
                        style={{
                          textDecoration: "none",
                          fontFamily: "Affairs",
                          color: "var(--mercagreen)",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {book}
                      </HashLink>
                    )}
                  {externalBookAddress !== "" &&
                    bookAddressMail === "" &&
                    bookAddressRoute === "" && (
                      <a
                        href={`http://${externalBookAddress}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          fontFamily: "Affairs",
                          color: "var(--mercagreen)",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {book}
                      </a>
                    )}
                  {externalBookAddress === "" &&
                    bookAddressMail === "" &&
                    bookAddressRoute === "" &&
                    book !== "" && (
                      <p
                        style={{
                          textDecoration: "none",
                          fontFamily: "Affairs",
                          color: "var(--mercagreen)",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {book}
                      </p>
                    )}
                </div>
              </div>
              <div className="agenda-card__info__right__lower">
                {type !== "" && (
                  <>
                    {displayMobile && (
                      <div className="agenda-card__info__right__lower__additional">
                        <p>{type}</p>
                      </div>
                    )}
                    <div className="type-ball"></div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {holidays !== undefined && (
          <div className="agenda-card__info">
            <p className="holidays-paragraph">{holidays}</p>
          </div>
        )}
      </div>
    );
  } else if (displayMobile) {
    return (
      <div className="agenda-card__container" id="agenda-card__container">
        <div className="agenda-card__month-wraper">{month}</div>
        {activities !== undefined && holidays === undefined && (
          <div className="agenda-card__day">
            <p
              style={number === date ? { borderBottom: "1px solid" } : null}
            >{`${day} ${number}`}</p>
          </div>
        )}
        {holidays !== undefined && (
          <div className="agenda-card__day">
            <p
              style={number === date ? { borderBottom: "1px solid" } : null}
            >{`${day} ${number}`}</p>
          </div>
        )}
        {holidays !== undefined && (
          <div
            className={
              filterSelected !== "all" ? "activityDisplay" : "agenda-card__info"
            }
          >
            <p className="holidays-paragraph">{holidays}</p>
          </div>
        )}
        {activities &&
          activities.map((item, index) => {
            return (
              <div
                className={
                  item.filter === filterSelected ||
                  filterSelected === "all" ||
                  item.filter2 === filterSelected
                    ? "agenda-card__info"
                    : "agenda-card__info activityDisplay"
                }
                key={index}
              >
                <div className="agenda-card__info__left">
                  <div className="agenda-card__info__left__upper">
                    <div className="agenda-card__info__left__upper__section">
                      <p>{item.section}</p>
                    </div>
                    <div className="agenda-card__info__left__upper__subsection">
                      <p>{item.subsection}</p>
                    </div>
                  </div>
                  <div className="agenda-card__info__left__lower">
                    <div className="agenda-card__info__left__lower__time">
                      <p>{item.time}</p>
                    </div>
                  </div>
                </div>
                <div className="agenda-card__info__right">
                  <div className="agenda-card__info__right__upper">
                    <div className="agenda-card__info__right__upper__info">
                      {item.route !== "" &&
                        filter !== "spaces" &&
                        item.externalInfo === "" && (
                          <HashLink
                            smooth
                            to={`/${item.route}`}
                            style={{
                              textDecoration: "none",
                              fontFamily: "Affairs",
                              color: "var(--mercagreen)",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setNavbarBackground("transparent");
                            }}
                          >
                            Info
                          </HashLink>
                        )}
                      {item.route !== "" &&
                        filter === "spaces" &&
                        item.externalInfo === "" && (
                          <HashLink
                            smooth
                            to={`${item.route}`}
                            style={{
                              textDecoration: "none",
                              fontFamily: "Affairs",
                              color: "var(--mercagreen)",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setNavbarBackground("transparent");
                              setScheduleToSpacesController(true);
                            }}
                          >
                            Info
                          </HashLink>
                        )}
                      {item.externalInfo !== "" && item.route === "" && (
                        <a
                          href={`http://${item.externalInfo}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            fontFamily: "Affairs",
                            color: "var(--mercagreen)",
                            fontSize: "14px",
                          }}
                        >
                          Info
                        </a>
                      )}
                    </div>
                    <div className="agenda-card__info__right__upper__book">
                      {item.bookAddressMail !== "" &&
                        item.bookAddressRoute === "" &&
                        item.externalBookAddress === "" && (
                          <a
                            href={`mailto:${item.bookAddress}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{
                              textDecoration: "none",
                              fontFamily: "Affairs",
                              color: "var(--mercagreen)",
                              fontSize: "14px",
                            }}
                          >
                            {item.book}
                          </a>
                        )}
                      {item.bookAddressMail === "" &&
                        item.bookAddressRoute !== "" &&
                        item.externalBookAddress === "" && (
                          <HashLink
                            to={`/${item.bookAddressRoute}`}
                            style={{
                              textDecoration: "none",
                              fontFamily: "Affairs",
                              color: "var(--mercagreen)",
                              fontSize: "14px",
                            }}
                          >
                            {item.book}
                          </HashLink>
                        )}
                      {item.bookAddressMail === "" &&
                        item.bookAddressRoute === "" &&
                        item.externalBookAddress !== "" && (
                          <a
                            href={`http://${item.externalBookAddress}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{
                              textDecoration: "none",
                              fontFamily: "Affairs",
                              color: "var(--mercagreen)",
                              fontSize: "14px",
                            }}
                          >
                            {item.book}
                          </a>
                        )}
                      {item.bookAddressMail === "" &&
                        item.bookAddressRoute === "" &&
                        item.externalBookAddress === "" && (
                          <p
                            style={{
                              textDecoration: "none",
                              fontFamily: "Affairs",
                              color: "var(--mercagreen)",
                              fontSize: "14px",
                            }}
                          >
                            {item.book}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="agenda-card__info__right__lower">
                    {type !== undefined && (
                      <>
                        {displayMobile && (
                          <div className="agenda-card__info__right__lower__additional">
                            <p>{item.type}</p>
                          </div>
                        )}
                        <div className="type-ball"></div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
};

export default AgendaCard;
