import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  position: fixed;
  bottom: 0;
  z-index: 999;
`;

export const ItemContainer = styled.div`
  border-top: 1px solid ${(props) => props.borderColor};
  height: max-content;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.color};
  margin: 0;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
`;

export const CrossContainer = styled.div`
  border-top: 1px solid ${(props) => props.borderColor};
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
