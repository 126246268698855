import React from "react";
import EspaisCarousel from "../EspaisCarousel/EspaisCarousel";
import DefaultCarousel from "../DefaultPages/DefaultCarousel/DefaultCarousel";
import {
  barricasetesImages,
  districteanticImages,
  naucentralImages,
  elsigloImages,
  espaiWemImages,
} from "../../../data/ImagesData";
import "./EspaisPageDefaultContainer.css";

const EspaisPageDefaultContainer = ({
  id,
  info,
  itemKey,
  counter,
  cta,
  info2,
  cta2,
  cta3,
  paramsSection,
}) => {
  return (
    <>
      <EspaisCarousel
        info={info}
        itemKey={itemKey}
        id={id}
        counter={counter}
        cta={cta}
        info2={info2}
        cta2={cta2}
        cta3={cta3}
        paramsSection={paramsSection}
      />

      {counter === 0 && <DefaultCarousel images={naucentralImages} />}
      {counter === 1 && <DefaultCarousel images={elsigloImages} />}
      {counter === 2 && <DefaultCarousel images={districteanticImages} />}
      {counter === 3 && <DefaultCarousel images={barricasetesImages} />}
      {counter === 4 && <DefaultCarousel images={espaiWemImages} />}
    </>
  );
};

export default EspaisPageDefaultContainer;
