import styled from "styled-components";

export const ItemsWrapper = styled.div`
  width: calc(100% - 2em);
  height: 50%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  background-color: ${(props) => props.backgroundColor};
  @media only screen and (min-width: 700px) {
    height: 100%;
    position: relative;
  }
`;

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${(props) => props.backgroundColor};
  :hover {
    cursor: pointer;
  }
  align-items: center;
  border-top: ${(props) =>
    props.border ? `2px solid ${props.backgroundColor}` : `none`};
  @media only screen and (min-width: 700px) {
    align-items: flex-start;
  }
`;

export const ItemH3 = styled.div`
  font-size: 36px;
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  line-height: 110%;
  letter-spacing: -0.005em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  @media only screen and (min-width: 700px) {
    font-size: 58px;
    line-height: 58px;
  }
`;

export const CrossContainer = styled.div`
  position: relative;
  height: 60px;
  width: 60px;
  align-self: center;
  @media only screen and (min-width: 700px) {
    align-self: auto;
  }
`;

export const Cross = styled.div`
  position: absolute;

  bottom: 0;
  left: 0;
  width: 32px;
  height: 32px;
  :hover {
    cursor: pointer;
  }
  :before {
    position: absolute;
    right: 20px;
    content: " ";
    height: 60px;
    width: 2px;
    background-color: ${(props) => props.color};
    transform: rotate(90deg);
  }
  :after {
    position: absolute;
    right: 20px;
    content: " ";
    height: 60px;
    width: 3px;
    background-color: ${(props) => props.color};
  }
`;

export const Img = styled.img`
  position: absolute;
  opacity: ${(props) => props.display};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  height: ${(props) => (props.heightController === true ? "auto" : "350px")};
  width: 300px;
  pointer-events: none;
`;
