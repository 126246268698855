const tallerLlana = "workshops#llanes";
const tallerCeramica = "workshops#ceramica";
const tallerFloristeria = "workshops#floristeria";
// const tallerExtraescolars = "workshops#extraescolars";
const tallerVitralls = "workshops#vitralls";
const tallerRestauracio = "workshops#restauraciomobles";

const Markets = "markets#setmanal";

const Event = "event";

const Concerts = "spaces/home#elsiglo";
export const agendaData = {
  catala: {
    filterMenu: [
      { id: 0, name: "Taller Permanent" },
      { id: 1, name: "Mercats", filter: "markets" },
      { id: 2, name: "Tallers", filter: "workshops" },
      { id: 3, name: "Concerts", filter: "concerts" },
      { id: 4, name: "Esdeveniments", filter: "events" },
    ],
    days: [
      "Dimarts",
      "Dimecres",
      "Dijous",
      "Divendres",
      "Dissabte",
      "Diumenge",
    ],
    filter: "Filtres",
    month: {
      october: {
        name: "Octubre",
        //eslint-disable-next-line
        row1: [
          // {
          //   day: "Dilluns",
          //   number: "28/08",
          //   key: 0,
          //   display: false,
          // },
          {
            day: "Dimarts",
            number: "03/10",
            key: 0,
            display: false,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de mobles",
                time: "10:00h - 13:30h o 15:30h - 19:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerRestauracio,
                bookAdress: "restauraciomobles13@gmail.com",
              },
            ],
          },
          {
            day: "Dimecres",
            number: "04/10",
            key: 1,
            display: false,
            activities: [
              {
                filter: "events",
                section: "Esdeveniment",
                subsection: "Presentació Llibre: Encara maten els cavalls",
                time: "19:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: Event,
                bookAdress: "info@mercantic.com",
              },
            ],
          },
          {
            day: "Dijous",
            number: "05/10",
            key: 2,
            display: false,
          },
          ,
          {
            day: "Divendres",
            number: "06/10",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Iniciació al vitrall",
                time: "17:30h a 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerVitralls,
                bookAdress: "metaphoravitralls@gmail.com",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut",
                time: "12:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Event",
                subsection: "Sun Cugat: (H)Ard Live Set",
                time: "18:00h a 22:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Please U2",
                time: "22:00h a 02:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
              },
            ],
          },
          ,
          {
            day: "Dissabte",
            number: "07/10",
            key: 4,
            display: false,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Taller d'espelmes i flors",
                time: "11:00h a 13:00h",
                info: "Info",
                book: "Reservar",
                route: tallerFloristeria,
                bookAdress: "info@sugarden.es",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut",
                time: "12:00h",
                info: "Info",
                book: "Reservar",

                route: Concerts,
              },

              {
                filter: "events",
                filter2: "concerts",
                section: "Event",
                subsection: "Sun Cugat: Nahuel Cosmico (Funky)",
                time: "18:00h a 22:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
              },
            ],
          },
          ,
          {
            day: "Diumenge",
            number: "08/10",
            key: 5,
            display: true,
            activities: [
              {
                section: "Mercat",
                subsection: "Sunday Market",
                time: "10h a 15h",
                info: "Info",
                // book: "Reservar",
                route: "markets#setmanal",
                filter: "markets",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Workshop de Brodat amb Aitor Saraiba",
                time: "10:00h a 14:00h / 16:00h a 20:00h",
                info: "Info",
                book: "Reservar",
                bookAdress: "hola@theknotslab.com",
                route: tallerLlana,
              },
              {
                filter: "events",
                section: "Esdeveniment",
                subsection: "Fira de Perfums: Mercaperfums",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                bookAdress: "mermobjects@gmail.com",
                route: Markets,
                filter2: "markets",
              },
            ],
          },
          ,
        ],

        row2: [
          // {
          //   day: "Dilluns",
          //   number: "04/09",
          //   display: true,
          //   activities: [
          //     {
          //       filter: "workshops",
          //       section: "Taller",
          //       subsection: "Brodat",
          //       time: "16:00h a 19:00h",
          //       type: "Taller Permanent",
          //       info: "Info",
          //       bookAdress: "hola@theknotslab.com",
          //       book: "Reservar",
          //       route: tallerLlana,
          //     },
          //   ],
          // },
          {
            day: "Dimarts",
            number: "10/10",
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de mobles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ram de flors per secar",
                time: "17:00h a 19:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "events",
                section: "Esdeveniment",
                subsection:
                  "Presentació Llibre: Allí donde los árboles tocan el cielo",
                time: "19:00h",
                bookAdress: "info@mercantic.com",
                info: "Info",
                book: "Reservar",
                route: Concerts,
              },
            ],
          },

          {
            day: "Dimecres",
            number: "11/10",
            display: false,
          },
          {
            day: "Dijous",
            number: "12/10",
            key: 2,
            display: true,
            activities: [
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Stereo Star Band (entrada lliure)",
                time: "21:00h a 00:00h",
                bookAdress: "info@mercantic.com",
                info: "Info",
                // book: "Entrada lliure",
                route: Concerts,
                externalInfo:
                  "https://www.salaelsiglo.com/event-details/stereo-star-band-live",
              },
            ],
          },
          {
            day: "Divendres",
            number: "13/10",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Iniciació al vitrall",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Esdeveniment",
                subsection: "Sun Cugat: Señor Gaviria (Tropical Beats)",
                time: "18:00h a 22:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut",
                time: "12:00h",
                info: "Info",
                // book: "Reservar",

                route: Concerts,
              },
            ],
          },
          {
            day: "Dissabte",
            number: "14/10",
            key: 4,
            display: true,
            activities: [
              {
                filter: "event",
                section: "Esdeveniment",
                subsection: "Meeting Point - Fira de rellotges",
                time: "10:00 a 14:00",
                display: true,
                // book: "Reservar",
                info: "Info",
                filter2: "markets",
                route: Markets,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Tapís",
                time: "10:00h a 14:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Esdeveniment",
                subsection: "Sun Cugat: Ali & Oli (Nu Disco)",
                time: "18:00h a 22:00h",
                info: "Info",
                // book: "Reservar",
                route: "event",
              },
            ],
          },
          {
            day: "Diumenge",
            number: "15/10",
            key: 5,
            display: true,
            activities: [
              {
                filter: "markets",
                section: "Mercat",
                subsection: "Sunday Market",
                time: "10:00h a 15:00h",
                info: "Info",
                // book: "Reservar",
                route: Markets,
              },
            ],
          },
        ],
        row3: [
          // {
          //   day: "Dilluns",
          //   number: "11/09",
          //   display: true,
          //   activities: [
          //     {
          //       filter: "workshops",
          //       section: "Taller",
          //       subsection: "Brodat",
          //       time: "16:00h a 19:00h",
          //       type: "Taller Permanent",
          //       info: "Info",
          //       bookAdress: "hola@theknotslab.com",
          //       book: "Reservar",
          //       route: tallerLlana,
          //     },
          //   ],
          // },
          {
            day: "Dimarts",
            number: "17/10",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de mobles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
              {
                filter: "workshops",
                section: "Crea un ram de flors per secar",
                subsection: "Restauració de mobles",
                time: "17:00h - 19:00h",
                info: "Info",
                bookAdress: " info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
            ],
          },
          {
            day: "Dimecres",
            number: "18/10",
            key: 1,
            display: false,
          },
          {
            day: "Dijous",
            number: "19/10",
            key: 2,
            display: false,
            activities: [
              {
                filter: "events",
                filter2: "concerts",
                section: "Jam Session",
                subsection: "Restauració de mobles",
                time: "20:00h - 23:00h",
                info: "Info",
                // bookAdress: " info@sugarden.es",
                // book: "Reservar",
                route: Concerts,
              },
            ],
          },
          {
            day: "Divendres",
            number: "20/10",
            display: true,
            key: 3,
            activities: [
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut",
                time: "12:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Iniciació al vitrall",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Concert",
                subsection: "Sun Cugat: Andra (Electro Funk)",
                time: "18:00h - 22:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Esdeveniment",
                subsection: "MISHIMA - L'Aigua Clara (SOLD OUT)",
                time: "22:00h a 03:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
                externalInfo:
                  "https://www.salaelsiglo.com/event-details/mishima-presenta-laigua-clara-sold-out",
              },
            ],
          },

          {
            day: "Dissabte",
            number: "21/10",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "events",
                section: "Esdeveniment",
                subsection: "Festival de Poesia",
                time: "10:00h a 15:00h",
                info: "Info",
                // book: "Reservar",
                route: "event",
                bookAdress: "info@mercantic.com",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut",
                time: "12:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Esdeveniment",
                subsection: "Sun Cugat: Qantic (Trip Hop)",
                time: "18:00h - 22:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
              },
            ],
          },

          {
            day: "Diumenge",
            number: "22/10",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Taller de Brodat Expressiu",
                time: "11:00h a 14:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Mercat",
                subsection: "Sunday Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: Markets,
                bookAdress: "",
              },
            ],
          },
        ],
        row4: [
          {
            day: "Dimarts",
            number: "24/10",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de Mobles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
            ],
          },
          {
            day: "Dimecres",
            number: "25/10",
            key: 1,
            display: false,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ram de flors per secar",
                time: "17:00h - 19:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
            ],
          },
          {
            day: "Dijous",
            number: "26/10",
            key: 2,
            display: true,
            activities: [
              {
                filter: "events",
                section: "Esdeveniment",
                subsection: "Lectures de gènere negra amb jazz & soul en viu",
                time: "19:00h",
                bookAdress: "info@mercantic.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: Concerts,
              },
            ],
          },
          {
            day: "Divendres",
            number: "27/10",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ram de flors per secar",
                time: "16:00h - 18:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ram de flors per secar",
                time: "16:00h - 18:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Iniciació al Vitrall",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
              {
                filter: "events",
                section: "Esdeveniment",
                subsection: "Fira de Vinils",
                time: "17:00h a 20:00h",
                // info: "Info",
                // book: "Reservar",
                // route: "event",
                // bookAdress: "",
              },
              {
                filter: "events",
                filter2: "concert",
                section: "Esdeveniment",
                subsection: "Sun Cugat: Rouch (Downtempo)",
                time: "18:00h a 22:00h",
                // info: "Info",
                // book: "Reservar",
                // route: "event",
                // bookAdress: "",
              },
            ],
          },
          {
            day: "Dissabte",
            number: "28/10",
            key: 4,
            display: true,
            activities: [
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut",
                time: "10:30h a 20:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
                // bookAdress: "",
              },
              {
                filter: "events",
                section: "Esdeveniment",
                subsection: "Fira de Vinils",
                time: "10:30h a 20:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
                bookAdress: "",
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Esdeveniment",
                subsection: "Sun Cugat: Andy Loop (Afrohouse)",
                time: "18:00h - 22:00h",
                info: "Info",
                // book: "Reservar",
                route: "event",
                // bookAdress: "",
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Esdeveniment",
                subsection: "Festival de Rockabilly",
                time: "20:00h - 03:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
                bookAdress:
                  "https://entradium.com/es/events/top-jive-a-rockabilly-dancing-2",
                externalInfo: "https://www.topjiverockabilly.com/",
              },
            ],
          },
          {
            day: "Diumenge",
            number: "29/10",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Mercat",
                subsection: "Sunday Market",
                time: "10:00h a 15:00h",
                info: "Info",
                // book: "Reservar",
                route: Markets,
                bookAdress: "",
              },
              {
                filter: "events",
                section: "Esdeveniment",
                subsection: "Fira de Vinils",
                time: "10:30h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: "",
              },
            ],
          },
        ],
        row5: [
          {
            day: "Dimarts",
            number: "31/10",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Restauració de Mobles",
                time: "10:00h - 13:30h o 15:30h - 19:00h",
                bookAdress: "restauraciomobles13@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Halloween Night",
                time: "23:00h - 03:00h",
                bookAdress:
                  "https://www.salaelsiglo.com/event-details/hell-siglo-halloween-night-dabeat-live-michael-jackson-tribute",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
                externalInfo:
                  "https://www.salaelsiglo.com/event-details/hell-siglo-halloween-night-dabeat-live-michael-jackson-tribute",
              },
            ],
          },
          {
            day: "Dimecres",
            number: "01/11",
            key: 1,
            display: false,
          },
          {
            day: "Dijous",
            number: "02/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Restauració de Mobles",
                time: "10:00h - 13:30h o 15:30h - 19:00h",
                bookAdress: "restauraciomobles13@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
            ],
          },
          {
            day: "Divendres",
            number: "03/11",
            display: false,
            key: 3,
          },
          {
            day: "Dissabte",
            number: "04/11",
            key: 4,
            display: false,
          },

          {
            day: "Diumenge",
            number: "05/11",
            key: 5,
            display: false,
          },
        ],
      },
      november: {
        name: "Novembre",
        //eslint-disable-next-line
        row1: [
          {
            day: "Dimarts",
            number: "31/10",
            key: 0,
            display: false,
          },
          {
            day: "Dimecres",
            number: "01/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de ceràmica",
                time: "11:00h - 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
            ],
          },
          {
            day: "Dijous",
            number: "02/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de ceràmica",
                time: "11:00h - 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Esdeveniment",
                subsection: "Jam Session",
                time: "20:00h a 23:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
          {
            day: "Divendres",
            number: "03/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea la teva tassa, gerro o set",
                time: "10:00h a 13:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de ceràmica",
                time: "11:00h - 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },

              {
                filter: "workshops",
                section: "Taller",
                subsection: "Iniciació al Vitrall",
                time: "17:30h a 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerVitralls,
                bookAdress: "metaphoravitralls@gmail.com",
              },
            ],
          },
          ,
          {
            day: "Dissabte",
            number: "04/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea la teva tassa, gerro o set",
                time: "10:00h a 13:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Torn",
                time: "11:00h a 14:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Brodat Expressiu",
                time: "11:00h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: tallerLlana,
                bookAdress: "hola@theknotslab.com",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut: Whitney Houston Tribut",
                time: "12:30h - 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea una corona de flors preservades",
                time: "16:00h a 18:00h",
                info: "Info",
                book: "Reservar",
                route: tallerFloristeria,
                bookAdress: "info@sugarden.es",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Aprèn a fer espelmes de mel",
                time: "18:00h a 20:00h",
                info: "Info",
                book: "Reservar",
                route: tallerFloristeria,
                bookAdress: "info@sugarden.es",
              },
            ],
          },
          ,
          {
            day: "Diumenge",
            number: "05/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea la teva tassa, gerro o set",
                time: "10:00h a 13:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Torn",
                time: "11:00h a 14:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanent",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut: Afroritmics - Afrobeat",
                time: "12:30h - 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
              {
                section: "Mercat",
                subsection: "Sunday Market",
                time: "10h a 15h",
                info: "Info",
                // book: "Reservar",
                route: "markets#setmanal",
                filter: "markets",
              },
            ],
          },
          ,
        ],

        row2: [
          {
            day: "Dimarts",
            number: "07/11",
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de ceràmica",
                time: "10:00h a 20:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de mobles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
            ],
          },

          {
            day: "Dimecres",
            number: "08/11",
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de ceràmica",
                type: "Taller Permanent",
                time: "10:00h a 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Dijous",
            number: "09/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de ceràmica",
                type: "Taller Permanent",
                time: "10:00h a 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Divendres",
            number: "10/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ram de flors - Tècnica espiral",
                time: "10:00h a 12:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea la teva tassa, gerro o set",
                type: "Taller Permanent",
                time: "10:00h a 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de ceràmica",
                type: "Taller Permanent",
                time: "10:00h a 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Iniciació al vitrall",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
            ],
          },
          {
            day: "Dissabte",
            number: "11/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea la teva tassa, gerro o set",
                type: "Taller Permanent",
                time: "10:00h a 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },

              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Knitting",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Ritual d'intencions",
                time: "10:00h a 13:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "markets",
                filter2: "events",
                section: "Mercat",
                subsection: "Fira de rellotges: Meeting Point",
                time: "10:00h a 14:00h",
                // bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                // book: "Reservar",
                route: Markets,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Torn",
                type: "Taller Permanent",
                time: "11:00h a 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut: Rythm & Blues",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
          {
            day: "Diumenge",
            number: "12/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea la teva tassa, gerro o set",
                type: "Taller Permanent",
                time: "10:00h a 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Tapís",
                time: "10:00h a 14:00h",
                info: "Info",
                bookAdress: "hola@theknotslab.com",
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Torn",
                type: "Taller Permanent",
                time: "11:00h a 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut: Trio El Siglo - Jazz",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
        ],
        row3: [
          {
            day: "Dimarts",
            number: "14/11",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de mobles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
              {
                filter: "workshops",
                section: "Tallers",
                subsection: "Classes de ceràmica",
                type: "Taller Permanent",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Dimecres",
            number: "15/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Tallers",
                subsection: "Classes de ceràmica",
                type: "Taller Permanent",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Dijous",
            number: "16/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Tallers",
                subsection: "Classes de ceràmica",
                type: "Taller Permanent",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Magalí Datzira",
                time: "20:00h - 00:00h",
                info: "Info",
                bookAdress:
                  "https://www.salaelsiglo.com/event-details/magali-datzira-live-des-de-la-cuina",
                externalInfo:
                  "https://www.salaelsiglo.com/event-details/magali-datzira-live-des-de-la-cuina",
                book: "Reservar",
                // route: Concerts,
              },
            ],
          },
          {
            day: "Divendres",
            number: "17/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Tallers",
                subsection: "Crea la teva tassa, gerro o set",
                type: "Taller Permanent",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Tallers",
                subsection: "Classes de ceràmica",
                type: "Taller Permanent",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Iniciació al vitrall",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
            ],
          },
          {
            day: "Dissabte",
            number: "18/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Tallers",
                subsection: "Crea la teva tassa, gerro o set",
                type: "Taller Permanent",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Tallers",
                subsection: "Introducció al Torn",
                type: "Taller Permanent",
                time: "11:00h - 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Tapís",
                time: "18:00h a 20:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut: Pere Soto - Jazz",
                time: "12:00h",
                info: "Info",
                book: "Reservar",
                bookAdress: "programacio@elsiglo.org",
                route: Concerts,
              },
            ],
          },

          {
            day: "Diumenge",
            number: "19/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Tallers",
                subsection: "Crea la teva tassa, gerro o set",
                type: "Taller Permanent",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Mercat",
                subsection: "Sunday Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: Markets,
                bookAdress: "",
              },
              {
                filter: "workshops",
                section: "Tallers",
                subsection: "Introducció al Torn",
                type: "Taller Permanent",
                time: "11:00h - 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut: Pol Cardona - Soul",
                time: "12:30h - 14:00h",
                info: "Info",
                book: "Reservar",
                bookAdress: "programacio@elsiglo.org",
                route: Concerts,
              },
            ],
          },
        ],
        row4: [
          {
            day: "Dimarts",
            number: "21/11",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de Ceràmica",
                time: "10:00h a 20:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de Mobles",
                time: "11:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
            ],
          },
          {
            day: "Dimecres",
            number: "22/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de Ceràmica",
                time: "10:00h a 20:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Dijous",
            number: "23/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de Ceràmica",
                time: "10:00h a 20:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Esdeveniment",
                subsection: "Jam Session",
                time: "19:00h",
                bookAdress: "programacio@elsiglo.org",
                info: "Info",
                display: true,
                book: "Reservar",
                route: Concerts,
              },
            ],
          },
          {
            day: "Divendres",
            number: "24/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ram de flors - Tècnica espiral",
                time: "10:00h - 12:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Crea la teva tassa, gerro o set",
                time: "10:00h a 13:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Classes de Ceràmica",
                time: "10:00h a 20:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Iniciació al Vitrall",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
            ],
          },
          {
            day: "Dissabte",
            number: "25/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Crea la teva tassa, gerro o set",
                time: "10:00h a 13:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Introducció al Torn",
                time: "11:00h a 14:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ram de flors - Tècnica espiral",
                time: "10:00h a 12:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut: Easy Tigers - Cover 90´",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
              {
                filter: "markets",
                section: "Mercat",
                subsection: "Patchwork Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: Markets,
                bookAdress: "info@mercantic.com",
              },
            ],
          },
          {
            day: "Diumenge",
            number: "26/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Crea la teva tassa, gerro o set",
                time: "10:00h a 13:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanent",
                subsection: "Introducció al Torn",
                time: "11:00h a 14:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Brodat Expressiu",
                time: "11:00h a 14:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Mercat",
                subsection: "Patchwork Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: Markets,
                bookAdress: "info@mercantic.com",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut: Ana Cristina Werring - Francesa",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
        ],
        row5: [
          {
            day: "Dimarts",
            number: "28/11",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de Ceràmica",
                time: "10:00h a 20:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de Mobles",
                time: "11:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
            ],
          },
          {
            day: "Dimecres",
            number: "29/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de Ceràmica",
                time: "10:00h a 20:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Dijous",
            number: "30/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Classes de Ceràmica",
                time: "10:00h a 20:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Divendres",
            number: "01/12",
            display: false,
            key: 3,
          },
          {
            day: "Dissabte",
            number: "02/12",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Torn",
                time: "11:00h - 14:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Knitting",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "hola@theknotslab.com",
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ram de flors",
                time: "10:00h - 12:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "markets",
                filter2: "events",
                section: "Mercat",
                subsection: "Fira de Rellotges: Meeting Point",
                time: "10:00h - 14:00h",
                info: "Info",
                route: Markets,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut",
                time: "12:30h - 14h",
                info: "Info",
                route: Concerts,
              },
            ],
          },
          {
            day: "Diumenge",
            number: "03/12",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Torn",
                time: "11:00h - 14:00h",
                type: "Taller Permanent",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Macramé",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "hola@theknotslab.com",
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Decoració Sostenible per a nens",
                time: "10:00h - 12:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "markets",
                section: "Mercat",
                subsection: "Sunday Market",
                time: "10:00h - 14:00h",
                info: "Info",
                route: Markets,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Concert Vermut",
                time: "12:30h - 14h",
                info: "Info",
                route: Concerts,
              },
            ],
          },
        ],
      },
    },
  },
  castella: {
    filterMenu: [
      { id: 0, name: "Taller Permanente" },
      { id: 1, name: "Mercados", filter: "markets" },
      { id: 2, name: "Talleres", filter: "workshops" },
      { id: 3, name: "Conciertos", filter: "concerts" },
      { id: 4, name: "Eventos", filter: "events" },
    ],
    days: ["Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
    filter: "Filtros",
    month: {
      october: {
        name: "Octubre",
        //eslint-disable-next-line
        row1: [
          // {
          //   day: "Dilluns",
          //   number: "28/08",
          //   key: 0,
          //   display: false,
          // },
          {
            day: "Martes",
            number: "03/10",
            key: 0,
            display: false,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauración de Muebles",
                time: "10:00h - 13:30h o 15:30h - 19:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerRestauracio,
                bookAdress: "restauraciomobles13@gmail.com",
              },
            ],
          },
          {
            day: "Miércoles",
            number: "04/10",
            key: 1,
            display: false,
            activities: [
              {
                filter: "events",
                section: "Evento",
                subsection: "Presentación Libro: Encara maten els cavalls",
                time: "19:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: "event",
                bookAdress: "info@mercantic.com",
              },
            ],
          },
          {
            day: "Jueves",
            number: "05/10",
            key: 2,
            display: false,
          },
          ,
          {
            day: "Viernes",
            number: "06/10",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Iniciación al Vitral",
                time: "17:30h a 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerVitralls,
                bookAdress: "metaphoravitralls@gmail.com",
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut",
                time: "12:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "Sun Cugat: (H)Ard Live Set",
                time: "18:00h a 22:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Please U2 (SOLD OUT)",
                time: "22:00h a 02:00h",
                info: "Info",
                // book: "Reservar",

                route: Concerts,
              },
            ],
          },
          ,
          {
            day: "Sábado",
            number: "07/10",
            key: 4,
            display: false,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Taller de velas y flores",
                time: "11:00h a 13:00h",
                info: "Info",
                book: "Reservar",
                route: tallerFloristeria,
                bookAdress: "info@sugarden.es",
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut",
                time: "12:00h",
                info: "Info",
                book: "Reservar",

                route: Concerts,
              },

              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "Sun Cugat: Nahuel Cosmico (Funky)",
                time: "18:00h a 22:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
              },
            ],
          },
          ,
          {
            day: "Domingo",
            number: "08/10",
            key: 5,
            display: true,
            activities: [
              {
                section: "Mercat",
                subsection: "Sunday Market",
                time: "10h a 15h",
                info: "Info",
                // book: "Reservar",
                route: "markets#setmanal",
                filter: "markets",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Workshop de Brodado con Aitor Saraiba",
                time: "10:00h a 14:00h / 16:00h a 20:00h",
                info: "Info",
                book: "Reservar",
                bookAdress: "hola@theknotslab.com",
                route: tallerLlana,
              },
              {
                filter: "events",
                section: "Evento",
                subsection: "Feria de Perfumes: Mercaperfums",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                bookAdress: "mermobjects@gmail.com",
                route: Markets,
                filter2: "markets",
              },
            ],
          },
          ,
        ],

        row2: [
          // {
          //   day: "Dilluns",
          //   number: "04/09",
          //   display: true,
          //   activities: [
          //     {
          //       filter: "workshops",
          //       section: "Taller",
          //       subsection: "Brodat",
          //       time: "16:00h a 19:00h",
          //       type: "Taller Permanente",
          //       info: "Info",
          //       bookAdress: "hola@theknotslab.com",
          //       book: "Reservar",
          //       route: tallerLlana,
          //     },
          //   ],
          // },
          {
            day: "Martes",
            number: "10/10",
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauración de Muebles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ramo de flores para secar",
                time: "17:00h a 19:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "events",
                section: "Evento",
                subsection:
                  "Presentación Libro: Allí donde los árboles tocan el cielo",
                time: "19:00h",
                bookAdress: "info@mercantic.com",
                info: "Info",
                book: "Reservar",
                route: Concerts,
              },
            ],
          },

          {
            day: "Miércoles",
            number: "11/10",
            display: false,
          },
          {
            day: "Jueves",
            number: "12/10",
            key: 2,
            display: true,
            activities: [
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Stereo Star Band (entrada libre)",
                time: "21:00h a 00:00h",
                bookAdress: "info@mercantic.com",
                info: "Info",
                // book: "Entrada libre",
                route: Concerts,
                externalInfo:
                  "https://www.salaelsiglo.com/event-details/stereo-star-band-live",
              },
            ],
          },
          {
            day: "Viernes",
            number: "13/10",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Iniciación al Vitral",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "Sun Cugat: Señor Gaviria (Tropical Beats)",
                time: "18:00h a 22:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut",
                time: "12:00h",
                info: "Info",
                // book: "Reservar",

                route: Concerts,
              },
            ],
          },
          {
            day: "Sábado",
            number: "14/10",
            key: 4,
            display: true,
            activities: [
              {
                filter: "event",
                section: "Evento",
                subsection: "Meeting Point - Feria de relojes",
                time: "10:00 a 14:00",
                display: true,
                // book: "Reservar",
                info: "Info",
                filter2: "markets",
                route: Markets,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Tapiz",
                time: "10:00h a 14:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "Sun Cugat: Ali & Oli (Nu Disco)",
                time: "18:00h a 22:00h",
                info: "Info",
                // book: "Reservar",
                route: "event",
              },
            ],
          },
          {
            day: "Domingo",
            number: "15/10",
            key: 5,
            display: true,
            activities: [
              {
                filter: "markets",
                section: "Mercado",
                subsection: "Sunday Market",
                time: "10:00h a 15:00h",
                info: "Info",
                // book: "Reservar",
                route: Markets,
              },
            ],
          },
        ],
        row3: [
          // {
          //   day: "Dilluns",
          //   number: "11/09",
          //   display: true,
          //   activities: [
          //     {
          //       filter: "workshops",
          //       section: "Taller",
          //       subsection: "Brodat",
          //       time: "16:00h a 19:00h",
          //       type: "Taller Permanente",
          //       info: "Info",
          //       bookAdress: "hola@theknotslab.com",
          //       book: "Reservar",
          //       route: tallerLlana,
          //     },
          //   ],
          // },
          {
            day: "Martes",
            number: "17/10",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauración de Muebles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
              {
                filter: "workshops",
                section: "Crea un ramo de flores para secar",
                subsection: "Restauración de Muebles",
                time: "17:00h - 19:00h",
                info: "Info",
                bookAdress: " info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
            ],
          },
          {
            day: "Miércoles",
            number: "18/10",
            key: 1,
            display: false,
          },
          {
            day: "Jueves",
            number: "19/10",
            key: 2,
            display: false,
            activities: [
              {
                filter: "events",
                filter2: "concerts",
                section: "Concierto",
                subsection: "Jam Session",
                time: "20:00h - 23:00h",
                info: "Info",
                // bookAdress: " info@sugarden.es",
                // book: "Reservar",
                route: Concerts,
              },
            ],
          },
          {
            day: "Viernes",
            number: "20/10",
            display: true,
            key: 3,
            activities: [
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut",
                time: "12:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Iniciación al Vitral",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Concierto",
                subsection: "Sun Cugat: Andra (Electro Funk)",
                time: "18:00h - 22:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "MISHIMA - L'Aigua Clara (SOLD OUT)",
                time: "22:00h a 03:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
                externalInfo:
                  "https://www.salaelsiglo.com/event-details/mishima-presenta-laigua-clara-sold-out",
              },
            ],
          },

          {
            day: "Sábado",
            number: "21/10",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "events",
                section: "Evento",
                subsection: "Festival de Poesía",
                time: "10:00h a 15:00h",
                info: "Info",
                // book: "Reservar",
                route: "event",
                bookAdress: "info@mercantic.com",
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut",
                time: "12:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "Sun Cugat: Qantic (Trip Hop)",
                time: "18:00h - 22:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
              },
            ],
          },

          {
            day: "Domingo",
            number: "22/10",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Taller de Brodado Expresivo",
                time: "11:00h a 14:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Mercado",
                subsection: "Sunday Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: Markets,
                bookAdress: "",
              },
            ],
          },
        ],
        row4: [
          {
            day: "Martes",
            number: "24/10",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauración de Muebles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
            ],
          },
          {
            day: "Miércoles",
            number: "25/10",
            key: 1,
            display: false,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ramo de flores para secar",
                time: "17:00h - 19:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
            ],
          },
          {
            day: "Jueves",
            number: "26/10",
            key: 2,
            display: true,
            activities: [
              {
                filter: "events",
                section: "Evento",
                subsection: "Lecturas de género negro con jazz & soul en vivo",
                time: "19:00h",
                bookAdress: "info@mercantic.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: Concerts,
              },
              // {
              //   filter: "concerts",
              //   section: "Concierto",
              //   subsection: "GIPSY KINGS - Gipsy al son",
              //   time: "20:00h - 00:00h",
              //   bookAdress:
              //     "https://www.salaelsiglo.com/event-details/gipsy-al-son-la-banda-original-de-los-gipsy-kings",
              //   info: "Info",
              //   display: false,
              //   book: "Reservar",
              //   route: Concerts,
              //   externalInfo:
              //     "https://www.salaelsiglo.com/event-details/gipsy-al-son-la-banda-original-de-los-gipsy-kings",
              // },
            ],
          },
          {
            day: "Viernes",
            number: "27/10",
            display: true,
            key: 3,
            activities: [
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut",
                time: "12:00h",
                bookAdress: "info@mercantic.com",
                info: "Info",
                display: true,
                // book: "Reservar",
                // route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ramo de flores para secar",
                time: "16:00h - 18:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Iniciació al Vitrall",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
              {
                filter: "events",
                section: "Evento",
                subsection: "Feria de Vinilos",
                time: "17:00h a 20:00h",
                // info: "Info",
                // book: "Reservar",
                // route: "event",
                // bookAdress: "",
              },
              {
                filter: "events",
                filter2: "concert",
                section: "Evento",
                subsection: "Sun Cugat: Rouch (Downtempo)",
                time: "18:00h a 22:00h",
                // info: "Info",
                // book: "Reservar",
                // route: "event",
                // bookAdress: "",
              },
            ],
          },
          {
            day: "Sábado",
            number: "28/10",
            key: 4,
            display: true,
            activities: [
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut",
                time: "10:30h a 20:00h",
                info: "Info",
                // book: "Reservar",
                route: Concerts,
                // bookAdress: "",
              },
              {
                filter: "events",
                section: "Evento",
                subsection: "Feria de Vinilos",
                time: "10:30h a 20:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
                // bookAdress: "",
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "Sun Cugat: Andy Loop (Afrohouse)",
                time: "18:00h - 22:00h",
                info: "Info",
                // book: "Reservar",
                route: "event",
                // bookAdress: "",
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "Festival de Rockabilly",
                time: "20:00h - 03:00h",
                info: "Info",
                book: "Reservar",
                // route: "event",
                externalInfo: "https://www.topjiverockabilly.com/",
                bookAdress:
                  "https://entradium.com/es/events/top-jive-a-rockabilly-dancing-2",
              },
            ],
          },
          {
            day: "Domingo",
            number: "29/10",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Mercat",
                subsection: "Sunday Market",
                time: "10:00h a 15:00h",
                info: "Info",
                // book: "Reservar",
                route: Markets,
                bookAdress: "",
              },
              {
                filter: "events",
                section: "Evento",
                subsection: "Feria de Vinilos",
                time: "10:30h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: "",
              },
            ],
          },
        ],
        row5: [
          // {
          //   day: "Dilluns",
          //   number: "25/09",
          //   display: true,
          //   activities: [
          //     {
          //       filter: "workshops",
          //       section: "Taller",
          //       subsection: "Brodat",
          //       time: "16:00h a 19:00h",
          //       type: "Taller Permanente",
          //       info: "Info",
          //       bookAdress: "hola@theknotslab.com",
          //       book: "Reservar",
          //       route: tallerLlana,
          //     },
          //   ],
          // },
          {
            day: "Martes",
            number: "31/10",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Restauración de Muebles",
                time: "10:00h - 13:30h o 15:30h - 19:00h",
                bookAdress: "restauraciomobles13@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Halloween Night",
                time: "23:00h - 03:00h",
                bookAdress:
                  "https://www.salaelsiglo.com/event-details/hell-siglo-halloween-night-dabeat-live-michael-jackson-tribute",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
                externalInfo:
                  "https://www.salaelsiglo.com/event-details/hell-siglo-halloween-night-dabeat-live-michael-jackson-tribute",
              },
            ],
          },
          {
            day: "Miércoles",
            number: "01/11",
            key: 1,
            display: false,
          },
          {
            day: "Jueves",
            number: "02/11",
            key: 2,
            display: false,
          },
          {
            day: "Viernes",
            number: "03/11",
            display: false,
            key: 3,
          },
          {
            day: "Sábado",
            number: "04/11",
            key: 4,
            display: false,
          },

          {
            day: "Domingo",
            number: "05/11",
            key: 5,
            display: false,
          },
        ],
      },
      november: {
        name: "Noviembre",
        //eslint-disable-next-line
        row1: [
          {
            day: "Martes",
            number: "31/10",
            key: 0,
            display: false,
          },
          {
            day: "Miércoles",
            number: "01/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de cerámica",
                time: "11:00h - 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
            ],
          },
          {
            day: "Jueves",
            number: "02/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de cerámica",
                time: "11:00h - 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "Jam Session",
                time: "20:00h a 23:00h",
                info: "Info",
                book: "Reservar",
                route: "event",
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
          {
            day: "Viernes",
            number: "03/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea tu taza, jarrón o set",
                time: "10:00h a 13:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de cerámica",
                time: "11:00h - 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },

              {
                filter: "workshops",
                section: "Taller",
                subsection: "Iniciación al Vitral",
                time: "17:30h a 20:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerVitralls,
                bookAdress: "metaphoravitralls@gmail.com",
              },
            ],
          },
          ,
          {
            day: "Sábado",
            number: "04/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea tu taza, jarrón o set",
                time: "10:00h a 13:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Torno",
                time: "11:00h a 14:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Brodado Expresivo",
                time: "11:00h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: tallerLlana,
                bookAdress: "hola@theknotslab.com",
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut: Whitney Houston Tribut",
                time: "12:30h - 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea una corona de flors preservades",
                time: "16:00h a 18:00h",
                info: "Info",
                book: "Reservar",
                route: tallerFloristeria,
                bookAdress: "info@sugarden.es",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Aprèn a fer espelmes de mel",
                time: "18:00h a 20:00h",
                info: "Info",
                book: "Reservar",
                route: tallerFloristeria,
                bookAdress: "info@sugarden.es",
              },
            ],
          },
          ,
          {
            day: "Domingo",
            number: "05/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea tu taza, jarrón o set",
                time: "10:00h a 13:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Torno",
                time: "11:00h a 14:00h",
                info: "Info",
                book: "Reservar",
                type: "Taller Permanente",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut: Afroritmics - Afrobeat",
                time: "12:30h - 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
              {
                section: "Mercado",
                subsection: "Sunday Market",
                time: "10h a 15h",
                info: "Info",
                // book: "Reservar",
                route: "markets#setmanal",
                filter: "markets",
              },
            ],
          },
          ,
        ],

        row2: [
          {
            day: "Martes",
            number: "07/11",
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de cerámica",
                time: "10:00h a 20:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de mobles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
            ],
          },

          {
            day: "Miércoles",
            number: "08/11",
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de cerámica",
                type: "Taller Permanente",
                time: "10:00h a 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Jueves",
            number: "09/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de cerámica",
                type: "Taller Permanente",
                time: "10:00h a 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Viernes",
            number: "10/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ramo de flores - Técnica espiral",
                time: "10:00h a 12:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea tu taza, jarrón o set",
                type: "Taller Permanente",
                time: "10:00h a 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de cerámica",
                type: "Taller Permanente",
                time: "10:00h a 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Iniciación al vitral",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
            ],
          },
          {
            day: "Sábado",
            number: "11/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea tu taza, jarrón o set",
                type: "Taller Permanente",
                time: "10:00h a 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducció al Knitting",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Ritual d'intencions",
                time: "10:00h a 13:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "markets",
                filter2: "events",
                section: "Mercado",
                subsection: "Feria de reloges: Meeting Point",
                time: "10:00h a 14:00h",
                // bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                // book: "Reservar",
                route: Markets,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Torno",
                type: "Taller Permanente",
                time: "11:00h a 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut: Rythm & Blues",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
          {
            day: "Domingo",
            number: "12/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea tu taza, jarrón o set",
                type: "Taller Permanente",
                time: "10:00h a 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Tapiz",
                time: "10:00h a 14:00h",
                info: "Info",
                bookAdress: "hola@theknotslab.com",
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Torno",
                type: "Taller Permanente",
                time: "11:00h a 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut: Trio El Siglo - Jazz",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
        ],
        row3: [
          {
            day: "Martes",
            number: "14/11",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauració de mobles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
              {
                filter: "workshops",
                section: "Talleres",
                subsection: "Clases de cerámica",
                type: "Taller Permanente",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Miércoles",
            number: "15/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Talleres",
                subsection: "Clases de cerámica",
                type: "Taller Permanente",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Jueves",
            number: "16/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Talleres",
                subsection: "Clases de cerámica",
                type: "Taller Permanente",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Magalí Datzira",
                time: "20:00h - 00:00h",
                info: "Info",
                bookAdress:
                  "https://www.salaelsiglo.com/event-details/magali-datzira-live-des-de-la-cuina",
                externalInfo:
                  "https://www.salaelsiglo.com/event-details/magali-datzira-live-des-de-la-cuina",
                book: "Reservar",
                // route: Concerts,
              },
            ],
          },
          {
            day: "Viernes",
            number: "17/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Talleres",
                subsection: "Crea tu taza, jarrón o set",
                type: "Taller Permanente",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Talleres",
                subsection: "Clases de cerámica",
                type: "Taller Permanente",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Iniciación al vitral",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
            ],
          },
          {
            day: "Sábado",
            number: "18/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Talleres",
                subsection: "Crea tu taza, jarrón o set",
                type: "Taller Permanente",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Talleres",
                subsection: "Introducción al Torno",
                type: "Taller Permanente",
                time: "11:00h - 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Tapiz",
                time: "18:00h a 20:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut: Pere Soto - Jazz",
                time: "12:00h",
                info: "Info",
                book: "Reservar",
                bookAdress: "programacio@elsiglo.org",
                route: Concerts,
              },
            ],
          },

          {
            day: "Domingo",
            number: "19/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Talleres",
                subsection: "Crea tu taza, jarrón o set",
                type: "Taller Permanente",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Mercado",
                subsection: "Sunday Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: Markets,
                bookAdress: "",
              },
              {
                filter: "workshops",
                section: "Talleres",
                subsection: "Introducción al Torno",
                type: "Taller Permanente",
                time: "11:00h - 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut: Pol Cardona - Soul",
                time: "12:30h - 14:00h",
                info: "Info",
                book: "Reservar",
                bookAdress: "programacio@elsiglo.org",
                route: Concerts,
              },
            ],
          },
        ],
        row4: [
          {
            day: "Martes",
            number: "21/11",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de Cerámica",
                time: "10:00h a 20:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauración de Muebles",
                time: "11:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
            ],
          },
          {
            day: "Miércoles",
            number: "22/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de Cerámica",
                time: "10:00h a 20:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Jueves",
            number: "23/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de Cerámica",
                time: "10:00h a 20:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Evento",
                subsection: "Jam Session",
                time: "19:00h",
                bookAdress: "programacio@elsiglo.org",
                info: "Info",
                display: true,
                book: "Reservar",
                route: Concerts,
              },
            ],
          },
          {
            day: "Viernes",
            number: "24/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ramo de flores - Técnica espiral",
                time: "10:00h - 12:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Crea tu taza, jarrón o set",
                time: "10:00h a 13:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Clases de Cerámica",
                time: "10:00h a 20:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Iniciación al Vitral",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerVitralls,
              },
            ],
          },
          {
            day: "Sábado",
            number: "25/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Crea tu taza, jarrón o set",
                time: "10:00h a 13:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Introducción al Torno",
                time: "11:00h a 14:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ramo de flores - Técnica espiral",
                time: "10:00h a 12:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut: Easy Tigers - Cover 90´",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
              {
                filter: "markets",
                section: "Mercado",
                subsection: "Patchwork Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: Markets,
                bookAdress: "info@mercantic.com",
              },
            ],
          },
          {
            day: "Domingo",
            number: "26/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Crea tu taza, jarrón o set",
                time: "10:00h a 13:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                type: "Taller Permanente",
                subsection: "Introducción al Torno",
                time: "11:00h a 14:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Brodado Expresivo",
                time: "11:00h a 14:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Mercado",
                subsection: "Patchwork Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Reservar",
                route: Markets,
                bookAdress: "info@mercantic.com",
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut: Ana Cristina Werring - Francesa",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Reservar",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
        ],
        row5: [
          {
            day: "Martes",
            number: "28/11",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de Cerámica",
                time: "10:00h a 20:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Restauración de Muebles",
                time: "11:00h a 13:30h o 15:30h a 19:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Reservar",
                route: tallerRestauracio,
              },
            ],
          },
          {
            day: "Miércoles",
            number: "29/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de Cerámica",
                time: "10:00h a 20:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Jueves",
            number: "30/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Clases de Cerámica",
                time: "10:00h a 20:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Viernes",
            number: "01/12",
            display: false,
            key: 3,
          },
          {
            day: "Sábado",
            number: "02/12",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Torno",
                time: "11:00h - 14:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Knitting",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "hola@theknotslab.com",
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Crea un ramo de flores",
                time: "10:00h - 12:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "markets",
                filter2: "events",
                section: "Mercado",
                subsection: "Feria de Relojes: Meeting Point",
                time: "10:00h - 14:00h",
                info: "Info",
                route: Markets,
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut",
                time: "12:30h - 14h",
                info: "Info",
                route: Concerts,
              },
            ],
          },
          {
            day: "Domingo",
            number: "03/12",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Torno",
                time: "11:00h - 14:00h",
                type: "Taller Permanente",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Reservar",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Introducción al Macramé",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "hola@theknotslab.com",
                book: "Reservar",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Taller",
                subsection: "Decoración Sostenible para niños",
                time: "10:00h - 12:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Reservar",
                route: tallerFloristeria,
              },
              {
                filter: "markets",
                section: "Mercado",
                subsection: "Sunday Market",
                time: "10:00h - 14:00h",
                info: "Info",
                route: Markets,
              },
              {
                filter: "concerts",
                section: "Concierto",
                subsection: "Concierto Vermut",
                time: "12:30h - 14h",
                info: "Info",
                route: Concerts,
              },
            ],
          },
        ],
      },
    },
  },
  angles: {
    filterMenu: [
      { id: 0, name: "Permanent Workshop" },
      { id: 1, name: "Markets", filter: "markets" },
      { id: 2, name: "Workshops", filter: "workshops" },
      { id: 3, name: "Concerts", filter: "concerts" },
      { id: 4, name: "Events", filter: "events" },
    ],
    days: ["Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    filter: "Filters",
    month: {
      november: {
        name: "November",
        //eslint-disable-next-line
        row1: [
          {
            day: "Tuesday",
            number: "31/10",
            key: 0,
            display: false,
          },
          {
            day: "Wednesday",
            number: "01/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                time: "11:00h - 20:00h",
                info: "Info",
                book: "Book",
                type: "Permanent Workshop",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
            ],
          },
          {
            day: "Thursday",
            number: "02/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                time: "11:00h - 20:00h",
                info: "Info",
                book: "Book",
                type: "Permanent Workshop",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Event",
                subsection: "Jam Session",
                time: "20:00h a 23:00h",
                info: "Info",
                book: "Book",
                route: "event",
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
          {
            day: "Friday",
            number: "03/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create your cup, vase or set",
                time: "10:00h a 13:00h",
                info: "Info",
                book: "Book",
                type: "Permanent Workshop",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                time: "11:00h - 20:00h",
                info: "Info",
                book: "Book",
                type: "Permanent Workshop",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },

              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Initiation to Stained Glass",
                time: "17:30h a 20:00h",
                info: "Info",
                book: "Book",
                type: "Permanent Workshop",
                route: tallerVitralls,
                bookAdress: "metaphoravitralls@gmail.com",
              },
            ],
          },
          ,
          {
            day: "Saturday",
            number: "04/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create your cup, vase or set",
                time: "10:00h a 13:00h",
                info: "Info",
                book: "Book",
                type: "Permanent Workshop",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Pottery Wheel for beginners",
                time: "11:00h a 14:00h",
                info: "Info",
                book: "Book",
                type: "Permanent Workshop",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Embroidery",
                time: "11:00h a 14:00h",
                info: "Info",
                book: "Book",
                route: tallerLlana,
                bookAdress: "hola@theknotslab.com",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert: Whitney Houston Tribute",
                time: "12:30h - 14:00h",
                info: "Info",
                book: "Book",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create a preserved flowers wreath",
                time: "16:00h a 18:00h",
                info: "Info",
                book: "Book",
                route: tallerFloristeria,
                bookAdress: "info@sugarden.es",
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Learn how to make honey candles",
                time: "18:00h a 20:00h",
                info: "Info",
                book: "Book",
                route: tallerFloristeria,
                bookAdress: "info@sugarden.es",
              },
            ],
          },
          ,
          {
            day: "Sunday",
            number: "05/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create your cup, vase or set",
                time: "10:00h a 13:00h",
                info: "Info",
                book: "Book",
                type: "Permanent Workshop",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Pottery Wheel for beginners",
                time: "11:00h a 14:00h",
                info: "Info",
                book: "Book",
                type: "Permanent Workshop",
                route: tallerCeramica,
                bookAdress: "mermobjects@gmail.com",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert: Afroritmics - Afrobeat",
                time: "12:30h - 14:00h",
                info: "Info",
                book: "Book",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
              {
                section: "Market",
                subsection: "Sunday Market",
                time: "10h a 15h",
                info: "Info",
                // book: "Book",
                route: "markets#setmanal",
                filter: "markets",
              },
            ],
          },
          ,
        ],

        row2: [
          {
            day: "Tuesday",
            number: "07/11",
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                time: "10:00h a 20:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Restauració de mobles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Book",
                route: tallerRestauracio,
              },
            ],
          },

          {
            day: "Wednesday",
            number: "08/11",
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                type: "Permanent Workshop",
                time: "10:00h a 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Thursday",
            number: "09/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                type: "Permanent Workshop",
                time: "10:00h a 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Friday",
            number: "10/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create a bouquet of flowers - Spiral technique",
                time: "10:00h a 12:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create your cup, vase or set",
                type: "Permanent Workshop",
                time: "10:00h a 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                type: "Permanent Workshop",
                time: "10:00h a 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                type: "Permanent Workshop",
                subsection: "Initiation to Stained Glass",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerVitralls,
              },
            ],
          },
          {
            day: "Saturday",
            number: "11/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create your cup, vase or set",
                type: "Permanent Workshop",
                time: "10:00h a 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Introducció al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Introducció al Knitting",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ritual d'intencions",
                time: "10:00h a 13:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerFloristeria,
              },
              {
                filter: "markets",
                filter2: "events",
                section: "Market",
                subsection: "Watches Fair: Meeting Point",
                time: "10:00h a 14:00h",
                // bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                // book: "Reservar",
                route: Markets,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Pottery Wheel for beginners",
                type: "Permanent Workshop",
                time: "11:00h a 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert: Rythm & Blues",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Book",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
          {
            day: "Sunday",
            number: "12/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create your cup, vase or set",
                type: "Permanent Workshop",
                time: "10:00h a 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Introducció al Tapís",
                time: "10:00h a 14:00h",
                info: "Info",
                bookAdress: "hola@theknotslab.com",
                book: "Book",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Pottery Wheel for beginners",
                type: "Permanent Workshop",
                time: "11:00h a 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert: Trio El Siglo - Jazz",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Book",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
        ],
        row3: [
          {
            day: "Tuesday",
            number: "14/11",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Restauració de mobles",
                time: "10:00h a 13:30h o 15:30h a 19:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Book",
                route: tallerRestauracio,
              },
              {
                filter: "workshops",
                section: "Workshops",
                subsection: "Ceramics Classes",
                type: "Permanent Workshop",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Wednesday",
            number: "15/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshops",
                subsection: "Ceramics Classes",
                type: "Permanent Workshop",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Thursday",
            number: "16/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshops",
                subsection: "Ceramics Classes",
                type: "Permanent Workshop",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Magalí Datzira",
                time: "20:00h - 00:00h",
                info: "Info",
                bookAdress:
                  "https://www.salaelsiglo.com/event-details/magali-datzira-live-des-de-la-cuina",
                externalInfo:
                  "https://www.salaelsiglo.com/event-details/magali-datzira-live-des-de-la-cuina",
                book: "Book",
                // route: Concerts,
              },
            ],
          },
          {
            day: "Friday",
            number: "17/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Workshops",
                subsection: "Create your cup, vase or set",
                type: "Permanent Workshop",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshops",
                subsection: "Ceramics Classes",
                type: "Permanent Workshop",
                time: "10:00h - 20:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                type: "Permanent Workshop",
                subsection: "Initiation to Stained Glass",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerVitralls,
              },
            ],
          },
          {
            day: "Saturday",
            number: "18/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshops",
                subsection: "Create your cup, vase or set",
                type: "Permanent Workshop",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshops",
                subsection: "Pottery Wheel for beginners",
                type: "Permanent Workshop",
                time: "11:00h - 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Introducció al Tapís",
                time: "18:00h a 20:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerLlana,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert: Pere Soto - Jazz",
                time: "12:00h",
                info: "Info",
                book: "Book",
                bookAdress: "programacio@elsiglo.org",
                route: Concerts,
              },
            ],
          },

          {
            day: "Sunday",
            number: "19/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshops",
                subsection: "Create your cup, vase or set",
                type: "Permanent Workshop",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Introducció al Macramé",
                time: "10:00h a 13:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Market",
                subsection: "Sunday Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Book",
                route: Markets,
                bookAdress: "",
              },
              {
                filter: "workshops",
                section: "Workshops",
                subsection: "Pottery Wheel for beginners",
                type: "Permanent Workshop",
                time: "11:00h - 14:00h",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert: Pol Cardona - Soul",
                time: "12:30h - 14:00h",
                info: "Info",
                book: "Book",
                bookAdress: "programacio@elsiglo.org",
                route: Concerts,
              },
            ],
          },
        ],
        row4: [
          {
            day: "Tuesday",
            number: "21/11",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                time: "10:00h a 20:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Furniture Restoration",
                time: "11:00h a 13:30h o 15:30h a 19:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Book",
                route: tallerRestauracio,
              },
            ],
          },
          {
            day: "Wednesday",
            number: "22/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                time: "10:00h a 20:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Thursday",
            number: "23/11",
            key: 2,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                time: "10:00h a 20:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "events",
                filter2: "concerts",
                section: "Event",
                subsection: "Jam Session",
                time: "19:00h",
                bookAdress: "programacio@elsiglo.org",
                info: "Info",
                display: true,
                book: "Book",
                route: Concerts,
              },
            ],
          },
          {
            day: "Friday",
            number: "24/11",
            display: true,
            key: 3,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create a bouquet of flowers - Spiral technique",
                time: "10:00h - 12:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerFloristeria,
              },
              {
                filter: "workshops",
                section: "Workshop",
                type: "Permanent Workshop",
                subsection: "Create your cup, vase or set",
                time: "10:00h a 13:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                type: "Permanent Workshop",
                subsection: "Ceramics Classes",
                time: "10:00h a 20:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                type: "Permanent Workshop",
                subsection: "Initiation to Stained Glass",
                time: "17:30h a 20:00h",
                bookAdress: "metaphoravitralls@gmail.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerVitralls,
              },
            ],
          },
          {
            day: "Saturday",
            number: "25/11",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                type: "Permanent Workshop",
                subsection: "Create your cup, vase or set",
                time: "10:00h a 13:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                type: "Permanent Workshop",
                subsection: "Pottery Wheel for beginners",
                time: "11:00h a 14:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create a bouquet of flowers - Spiral technique",
                time: "10:00h a 12:00h",
                bookAdress: "info@sugarden.es",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerFloristeria,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert: Easy Tigers - Cover 90´",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Book",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
              {
                filter: "markets",
                section: "Market",
                subsection: "Patchwork Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Book",
                route: Markets,
                bookAdress: "info@mercantic.com",
              },
            ],
          },
          {
            day: "Sunday",
            number: "26/11",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                type: "Permanent Workshop",
                subsection: "Create your cup, vase or set",
                time: "10:00h a 13:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                type: "Permanent Workshop",
                subsection: "Pottery Wheel for beginners",
                time: "11:00h a 14:00h",
                bookAdress: "mermobjects@gmail.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Embroidery",
                time: "11:00h a 14:00h",
                bookAdress: "hola@theknotslab.com",
                info: "Info",
                display: true,
                book: "Book",
                route: tallerLlana,
              },
              {
                filter: "markets",
                section: "Market",
                subsection: "Patchwork Market",
                time: "10:00h a 15:00h",
                info: "Info",
                book: "Book",
                route: Markets,
                bookAdress: "info@mercantic.com",
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert: Ana Cristina Werring - French",
                time: "12:30h a 14:00h",
                info: "Info",
                book: "Book",
                route: Concerts,
                bookAdress: "programacio@elsiglo.org",
              },
            ],
          },
        ],
        row5: [
          {
            day: "Tuesday",
            number: "28/11",
            key: 0,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                time: "10:00h a 20:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Furniture Restoration",
                time: "11:00h a 13:30h o 15:30h a 19:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "restauraciomobles13@gmail.com",
                book: "Book",
                route: tallerRestauracio,
              },
            ],
          },
          {
            day: "Wednesday",
            number: "29/11",
            key: 1,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Ceramics Classes",
                time: "10:00h a 20:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
            ],
          },
          {
            day: "Thursday",
            number: "30/11",
            key: 2,
            display: false,
          },
          {
            day: "Friday",
            number: "01/12",
            display: false,
            key: 3,
          },
          {
            day: "Saturday",
            number: "02/12",
            key: 4,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Pottery Wheel for beginners",
                time: "11:00h - 14:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Knitting for Beginners",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "hola@theknotslab.com",
                book: "Book",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Create a bouquet of flowers",
                time: "10:00h - 12:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Book",
                route: tallerFloristeria,
              },
              {
                filter: "markets",
                filter2: "events",
                section: "Market",
                subsection: "Watches Fair",
                time: "10:00h - 14:00h",
                info: "Info",
                route: Markets,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert",
                time: "12:30h - 14h",
                info: "Info",
                route: Concerts,
              },
            ],
          },
          {
            day: "Sunday",
            number: "03/12",
            key: 5,
            display: true,
            activities: [
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Pottery Wheel for beginners",
                time: "11:00h - 14:00h",
                type: "Permanent Workshop",
                info: "Info",
                bookAdress: "mermobjects@gmail.com",
                book: "Book",
                route: tallerCeramica,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Macramé for Beginners",
                time: "10:00h - 13:00h",
                info: "Info",
                bookAdress: "hola@theknotslab.com",
                book: "Book",
                route: tallerLlana,
              },
              {
                filter: "workshops",
                section: "Workshop",
                subsection: "Sustainable decoration for kids",
                time: "10:00h - 12:00h",
                info: "Info",
                bookAdress: "info@sugarden.es",
                book: "Book",
                route: tallerFloristeria,
              },
              {
                filter: "markets",
                section: "Market",
                subsection: "Sunday Market",
                time: "10:00h - 14:00h",
                info: "Info",
                route: Markets,
              },
              {
                filter: "concerts",
                section: "Concert",
                subsection: "Vermouth Concert",
                time: "12:30h - 14h",
                info: "Info",
                route: Concerts,
              },
            ],
          },
        ],
      },
    },
  },
};
