//SPACES
import initialPageEspais from "../assets/EspaisPage/initialPageEspais.jpg";
import naucentral01 from "../assets/EspaisPage/Nau Central/naucentral01.jpg";
import naucentral01Placeholder from "../assets/EspaisPage/Nau Central/naucentral01Placeholder.jpg";
import naucentral02 from "../assets/EspaisPage/Nau Central/naucentral02.jpg";
import naucentral02Placeholder from "../assets/EspaisPage/Nau Central/naucentral02Placeholder.jpg";
import naucentral03 from "../assets/EspaisPage/Nau Central/naucentral03.jpg";
import naucentral03Placeholder from "../assets/EspaisPage/Nau Central/naucentral03Placeholder.jpg";
import naucentral04 from "../assets/EspaisPage/Nau Central/naucentral04.jpg";
import naucentral04Placeholder from "../assets/EspaisPage/Nau Central/naucentral04Placeholder.jpg";
import launio01 from "../assets/EspaisPage/Nau Central/La Unió/launio01.jpg";
import launio01Placeholder from "../assets/EspaisPage/Nau Central/La Unió/launio01Placeholder.jpg";
import launio02Placeholder from "../assets/EspaisPage/Nau Central/La Unió/launio02Placeholder.jpg";
import launio03Placeholder from "../assets/EspaisPage/Nau Central/La Unió/launio03Placeholder.jpg";
import launio04Placeholder from "../assets/EspaisPage/Nau Central/La Unió/launio04Placeholder.jpg";
import launio02 from "../assets/EspaisPage/Nau Central/La Unió/launio02.jpg";
import launio03 from "../assets/EspaisPage/Nau Central/La Unió/launio03.jpg";
import launio04 from "../assets/EspaisPage/Nau Central/La Unió/launio04.jpg";
import lapuntual01 from "../assets/EspaisPage/Nau Central/La Puntual/lapuntual01.jpg";
import lapuntual01Placeholder from "../assets/EspaisPage/Nau Central/La Puntual/lapuntual01Placeholder.jpg";
import lapuntual02Placeholder from "../assets/EspaisPage/Nau Central/La Puntual/lapuntual02Placeholder.jpg";
import lapuntual03Placeholder from "../assets/EspaisPage/Nau Central/La Puntual/lapuntual03Placeholder.jpg";
import lapuntual04Placeholder from "../assets/EspaisPage/Nau Central/La Puntual/lapuntual04Placeholder.jpg";
import lapuntual02 from "../assets/EspaisPage/Nau Central/La Puntual/lapuntual02.jpg";
import lapuntual03 from "../assets/EspaisPage/Nau Central/La Puntual/lapuntual03.jpg";
import lapuntual04 from "../assets/EspaisPage/Nau Central/La Puntual/lapuntual04.jpg";
import escola01 from "../assets/EspaisPage/Nau Central/Escola/escola01.jpg";
import escola01Placeholder from "../assets/EspaisPage/Nau Central/Escola/escola01Placeholder.jpg";
import escola02Placeholder from "../assets/EspaisPage/Nau Central/Escola/escola02Placeholder.jpg";
import escola03Placeholder from "../assets/EspaisPage/Nau Central/Escola/escola03Placeholder.jpg";
import escola04Placeholder from "../assets/EspaisPage/Nau Central/Escola/escola04Placeholder.jpg";
import escola02 from "../assets/EspaisPage/Nau Central/Escola/escola02.jpg";
import escola03 from "../assets/EspaisPage/Nau Central/Escola/escola03.jpg";
import escola04 from "../assets/EspaisPage/Nau Central/Escola/escola04.jpg";
import ncbotigues01Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues01Placeholder.jpg";
import ncbotigues02Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues02Placeholder.jpg";
import ncbotigues03Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues03Placeholder.jpg";
import ncbotigues04Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues04Placeholder.jpg";
import ncbotigues05Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues05Placeholder.jpg";
import ncbotigues06Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues06Placeholder.jpg";
import ncbotigues07Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues07Placeholder.jpg";
import ncbotigues08Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues08Placeholder.jpg";
import ncbotigues09Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues09Placeholder.jpg";
import ncbotigues10Placeholder from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues10Placeholder.jpg";

import ncbotigues01 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues01.jpg";
import ncbotigues02 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues02.jpg";
import ncbotigues03 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues03.jpg";
import ncbotigues04 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues04.jpg";
import ncbotigues05 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues05.jpg";
import ncbotigues06 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues06.jpg";
import ncbotigues07 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues07.jpg";
import ncbotigues08 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues08.jpg";
import ncbotigues09 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues09.jpg";
import ncbotigues10 from "../assets/EspaisPage/Nau Central/Botigues/ncbotigues10.jpg";

import districteantic01 from "../assets/EspaisPage/Districte Antic/districteantic01.jpg";
import districteantic01Placeholder from "../assets/EspaisPage/Districte Antic/districteantic01Placeholder.jpg";
import districteantic02Placeholder from "../assets/EspaisPage/Districte Antic/districteantic02Placeholder.jpg";
import districteantic03Placeholder from "../assets/EspaisPage/Districte Antic/districteantic03Placeholder.jpg";
import districteantic04Placeholder from "../assets/EspaisPage/Districte Antic/districteantic04Placeholder.jpg";
import districteantic02 from "../assets/EspaisPage/Districte Antic/districteantic02.jpg";
import districteantic03 from "../assets/EspaisPage/Districte Antic/districteantic03.jpg";
import districteantic04 from "../assets/EspaisPage/Districte Antic/districteantic04.jpg";
import espaisubhasta01 from "../assets/EspaisPage/Districte Antic/Espai Subhasta/espaisubhasta01.jpg";
import espaisubhasta01Placeholder from "../assets/EspaisPage/Districte Antic/Espai Subhasta/espaisubhasta01Placeholder.jpg";
import espaisubhasta02Placeholder from "../assets/EspaisPage/Districte Antic/Espai Subhasta/espaisubhasta02Placeholder.jpg";
import espaisubhasta03Placeholder from "../assets/EspaisPage/Districte Antic/Espai Subhasta/espaisubhasta03Placeholder.jpg";
import espaisubhasta04Placeholder from "../assets/EspaisPage/Districte Antic/Espai Subhasta/espaisubhasta04Placeholder.jpg";
import espaisubhasta02 from "../assets/EspaisPage/Districte Antic/Espai Subhasta/espaisubhasta02.jpg";
import espaisubhasta03 from "../assets/EspaisPage/Districte Antic/Espai Subhasta/espaisubhasta03.jpg";
import espaisubhasta04 from "../assets/EspaisPage/Districte Antic/Espai Subhasta/espaisubhasta04.jpg";
import elsiglo01 from "../assets/EspaisPage/Districte Antic/El Siglo/elsiglo01.jpg";
import elsiglo01Placeholder from "../assets/EspaisPage/Districte Antic/El Siglo/elsiglo01Placeholder.jpg";
import elsiglo02Placeholder from "../assets/EspaisPage/Districte Antic/El Siglo/elsiglo02Placeholder.jpg";
import elsiglo03Placeholder from "../assets/EspaisPage/Districte Antic/El Siglo/elsiglo03Placeholder.jpg";
import elsiglo04Placeholder from "../assets/EspaisPage/Districte Antic/El Siglo/elsiglo04Placeholder.jpg";
import elsiglo02 from "../assets/EspaisPage/Districte Antic/El Siglo/elsiglo02.jpg";
import elsiglo03 from "../assets/EspaisPage/Districte Antic/El Siglo/elsiglo03.jpg";
import elsiglo04 from "../assets/EspaisPage/Districte Antic/El Siglo/elsiglo04.jpg";
import dabotigues01 from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues01.jpg";
import dabotigues01Placeholder from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues01Placeholder.jpg";
import dabotigues02Placeholder from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues02Placeholder.jpg";
import dabotigues03Placeholder from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues03Placeholder.jpg";
import dabotigues04Placeholder from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues04Placeholder.jpg";
import dabotigues05Placeholder from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues05Placeholder.jpg";
import dabotigues06Placeholder from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues06Placeholder.jpg";
import dabotigues07Placeholder from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues07Placeholder.jpg";
import dabotigues02 from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues02.jpg";
import dabotigues03 from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues03.jpg";
import dabotigues04 from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues04.jpg";
import dabotigues05 from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues05.jpg";
import dabotigues06 from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues06.jpg";
import dabotigues07 from "../assets/EspaisPage/Districte Antic/Botigues/dabotigues07.jpg";

import barricasetes01 from "../assets/EspaisPage/Barri de les Casetes/barricasetes01.jpg";
import barricasetes01Placeholder from "../assets/EspaisPage/Barri de les Casetes/barricasetes01Placeholder.jpg";
import barricasetes02Placeholder from "../assets/EspaisPage/Barri de les Casetes/barricasetes02Placeholder.jpg";
import barricasetes03Placeholder from "../assets/EspaisPage/Barri de les Casetes/barricasetes03Placeholder.jpg";
import barricasetes04Placeholder from "../assets/EspaisPage/Barri de les Casetes/barricasetes04Placeholder.jpg";
import barricasetes05Placeholder from "../assets/EspaisPage/Barri de les Casetes/barricasetes05Placeholder.jpg";
import barricasetes02 from "../assets/EspaisPage/Barri de les Casetes/barricasetes02.jpg";
import barricasetes03 from "../assets/EspaisPage/Barri de les Casetes/barricasetes03.jpg";
import barricasetes04 from "../assets/EspaisPage/Barri de les Casetes/barricasetes04.jpg";
import barricasetes05 from "../assets/EspaisPage/Barri de les Casetes/barricasetes05.jpg";
import bcbotigues01 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues01.jpg";
import bcbotigues01Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues01Placeholder.jpg";
import bcbotigues02Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues02Placeholder.jpg";
import bcbotigues03Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues03Placeholder.jpg";
import bcbotigues04Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues04Placeholder.jpg";
import bcbotigues05Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues05Placeholder.jpg";
import bcbotigues06Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues06Placeholder.jpg";
import bcbotigues07Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues07Placeholder.jpg";
import bcbotigues08Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues08Placeholder.jpg";
import bcbotigues09Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues09Placeholder.jpg";
import bcbotigues10Placeholder from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues10Placeholder.jpg";
import bcbotigues02 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues02.jpg";
import bcbotigues03 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues03.jpg";
import bcbotigues04 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues04.jpg";
import bcbotigues05 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues05.jpg";
import bcbotigues06 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues06.jpg";
import bcbotigues07 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues07.jpg";
import bcbotigues08 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues08.jpg";
import bcbotigues09 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues09.jpg";
import bcbotigues10 from "../assets/EspaisPage/Barri de les Casetes/Botigues/bcbotigues10.jpg";

import mercatsInitialPage from "../assets/MercatsPage/backgroundImage.jpg";
import permanent01 from "../assets/MercatsPage/Mercat Permanent/permanent01.jpg";
import permanent01Placeholder from "../assets/MercatsPage/Mercat Permanent/permanent01Placeholder.jpg";
import permanent02Placeholder from "../assets/MercatsPage/Mercat Permanent/permanent02Placeholder.jpg";
import permanent03Placeholder from "../assets/MercatsPage/Mercat Permanent/permanent03Placeholder.jpg";
import permanent04Placeholder from "../assets/MercatsPage/Mercat Permanent/permanent04Placeholder.jpg";
import permanent05Placeholder from "../assets/MercatsPage/Mercat Permanent/permanent05Placeholder.jpg";
import permanent02 from "../assets/MercatsPage/Mercat Permanent/permanent02.jpg";
import permanent03 from "../assets/MercatsPage/Mercat Permanent/permanent03.jpg";
import permanent04 from "../assets/MercatsPage/Mercat Permanent/permanent04.jpg";
import permanent05 from "../assets/MercatsPage/Mercat Permanent/permanent05.jpg";
import setmanal01 from "../assets/MercatsPage/Mercat Setmanal/setmanal01.jpg";
import setmanal01Placeholder from "../assets/MercatsPage/Mercat Setmanal/setmanal01Placeholder.jpg";
import setmanal02Placeholder from "../assets/MercatsPage/Mercat Setmanal/setmanal02Placeholder.jpg";
import setmanal03Placeholder from "../assets/MercatsPage/Mercat Setmanal/setmanal03Placeholder.jpg";
import setmanal04Placeholder from "../assets/MercatsPage/Mercat Setmanal/setmanal04Placeholder.jpg";
import setmanal05Placeholder from "../assets/MercatsPage/Mercat Setmanal/setmanal05Placeholder.jpg";
import setmanal02 from "../assets/MercatsPage/Mercat Setmanal/setmanal02.jpg";
import setmanal03 from "../assets/MercatsPage/Mercat Setmanal/setmanal03.jpg";
import setmanal04 from "../assets/MercatsPage/Mercat Setmanal/setmanal04.jpg";
import setmanal05 from "../assets/MercatsPage/Mercat Setmanal/setmanal05.jpg";

import initialPageTallers from "../assets/TallersPage/initialPageTallers.jpg";
import initialPageGastronomia from "../assets/GastronomiaPage/initialPageGastronomia.jpg";

import carousel1 from "../assets/HomePageCarousel/carrusel1.jpg";
import carousel2 from "../assets/HomePageCarousel/carrusel2.jpg";
import carousel3 from "../assets/HomePageCarousel/carrusel3.jpg";
import carousel4 from "../assets/HomePageCarousel/carrusel4.jpg";
import carousel5 from "../assets/HomePageCarousel/carrusel5.jpg";
import carousel6 from "../assets/HomePageCarousel/carrusel6.jpg";
import carousel7 from "../assets/HomePageCarousel/carrusel7.jpg";
import carousel8 from "../assets/HomePageCarousel/carrusel8.jpg";
import carousel9 from "../assets/HomePageCarousel/carrusel9.jpg";
import carousel10 from "../assets/HomePageCarousel/carrusel10.jpg";
import carousel11 from "../assets/HomePageCarousel/carrusel11.jpg";
import carousel12 from "../assets/HomePageCarousel/carrusel12.jpg";
import carousel13 from "../assets/HomePageCarousel/carrusel13.jpg";
import carousel14 from "../assets/HomePageCarousel/carrusel14.jpg";
import carousel15 from "../assets/HomePageCarousel/carrusel15.jpg";
import carousel16 from "../assets/HomePageCarousel/carrusel16.jpg";
import carousel17 from "../assets/HomePageCarousel/carrusel17.jpg";
import carousel18 from "../assets/HomePageCarousel/carrusel18.jpg";
import carousel19 from "../assets/HomePageCarousel/carrusel19.jpg";

export const initialPageImage = [
  { img: initialPageEspais },
  { img: mercatsInitialPage },
  { img: initialPageTallers },
  { img: initialPageGastronomia },
];
export const carouselImages = [
  { img: carousel1 },
  { img: carousel2 },
  { img: carousel3 },
  { img: carousel4 },
  { img: carousel5 },
  { img: carousel6 },
  { img: carousel7 },
  { img: carousel8 },
  { img: carousel9 },
  { img: carousel10 },
  { img: carousel11 },
  { img: carousel12 },
  { img: carousel13 },
  { img: carousel14 },
  { img: carousel15 },
  { img: carousel16 },
  { img: carousel17 },
  { img: carousel18 },
  { img: carousel19 },
];
export const naucentralImages = [
  {
    img: naucentral01,
    placeholder: naucentral01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/NauCentral/naucentral01.jpg?updatedAt=1690188049417",
  },
  {
    img: naucentral02,
    placeholder: naucentral02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/NauCentral/naucentral02.jpg?updatedAt=1690188055163",
  },
  {
    img: naucentral03,
    placeholder: naucentral03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/NauCentral/naucentral03.jpg?updatedAt=1690188046419",
  },
  {
    img: naucentral04,
    placeholder: naucentral04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/NauCentral/naucentral04.jpg?updatedAt=1690188052134",
  },
];
export const launioImages = [
  {
    img: launio01,
    placeholder: launio01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/La%20Uni%C3%B3/launio01.jpg?updatedAt=1690188094283",
  },
  {
    img: launio02,
    placeholder: launio02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/La%20Uni%C3%B3/launio02.jpg?updatedAt=1690188094411",
  },
  {
    img: launio03,
    placeholder: launio03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/La%20Uni%C3%B3/launio03.jpg?updatedAt=1690188093664",
  },
  {
    img: launio04,
    placeholder: launio04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/La%20Uni%C3%B3/launio04.jpg?updatedAt=1690188093636",
  },
];

export const lapuntualImages = [
  {
    img: lapuntual01,
    placeholder: lapuntual01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/La%20Puntual/lapuntual01.jpg?updatedAt=1690184939337",
  },
  {
    img: lapuntual02,
    placeholder: lapuntual02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/La%20Puntual/lapuntual02.jpg?updatedAt=1690184938394",
  },
  {
    img: lapuntual03,
    placeholder: lapuntual03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/La%20Puntual/lapuntual03.jpg?updatedAt=1690184938348",
  },
  {
    img: lapuntual04,
    placeholder: lapuntual04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/La%20Puntual/lapuntual04.jpg?updatedAt=1690184938137",
  },
];
export const escolaImages = [
  {
    img: escola01,
    placeholder: escola01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Escola/escola01.jpg?updatedAt=1690184979466",
  },
  {
    img: escola02,
    placeholder: escola02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Escola/escola02.jpg?updatedAt=1690184979577",
  },
  {
    img: escola03,
    placeholder: escola03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Escola/escola03.jpg?updatedAt=1690184979444",
  },
  {
    img: escola04,
    placeholder: escola04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Escola/escola04.jpg?updatedAt=1690184979536",
  },
];
export const boboisImages = [
  {
    path: "https://ik.imagekit.io/gwms6thri/Roche%20Bobois/bobois01.jpg?updatedAt=1698305897986",
  },
  {
    path: "https://ik.imagekit.io/gwms6thri/Roche%20Bobois/bobois03.jpg?updatedAt=1698305873150",
  },
  {
    path: "https://ik.imagekit.io/gwms6thri/Roche%20Bobois/bobois02.jpg?updatedAt=1698305870754",
  },
  {
    path: "https://ik.imagekit.io/gwms6thri/Roche%20Bobois/bobois04.jpg?updatedAt=1698305868653",
  },
];

export const ncbotiguesImages = [
  {
    img: ncbotigues01,
    placeholder: ncbotigues01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues01.jpg?updatedAt=1690185021709",
  },
  {
    img: ncbotigues02,
    placeholder: ncbotigues02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues02.jpg?updatedAt=1690185021828",
  },
  {
    img: ncbotigues03,
    placeholder: ncbotigues03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues03.jpg?updatedAt=1690185018788",
  },
  {
    img: ncbotigues04,
    placeholder: ncbotigues04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues04.jpg?updatedAt=1690185018823",
  },
  {
    img: ncbotigues05,
    placeholder: ncbotigues05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues05.jpg?updatedAt=1690185018776",
  },
  {
    img: ncbotigues06,
    placeholder: ncbotigues06Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues06.jpg?updatedAt=1690185018751",
  },
  {
    img: ncbotigues07,
    placeholder: ncbotigues07Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues07.jpg?updatedAt=1690185018719",
  },
  {
    img: ncbotigues08,
    placeholder: ncbotigues08Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues08.jpg?updatedAt=1690185021632",
  },
  {
    img: ncbotigues09,
    placeholder: ncbotigues09Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues09.jpg?updatedAt=1690185021443",
  },
  {
    img: ncbotigues10,
    placeholder: ncbotigues10Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Nau%20Central/Botigues/ncbotigues10.jpg?updatedAt=1690185021728",
  },
];

export const districteanticImages = [
  {
    img: districteantic01,
    placeholder: districteantic01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/districteantic01.jpg?updatedAt=1690185154300",
  },
  {
    img: districteantic02,
    placeholder: districteantic02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/districteantic02.jpg?updatedAt=1690185154176",
  },
  {
    img: districteantic03,
    placeholder: districteantic03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/districteantic03.jpg?updatedAt=1690185154212",
  },
  {
    img: districteantic04,
    placeholder: districteantic04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/districteantic04.jpg?updatedAt=1690185154383",
  },
];

export const espaisubhastaImages = [
  {
    img: espaisubhasta01,
    placeholder: espaisubhasta01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Espai%20Subhasta/espaisubhasta01.jpg?updatedAt=1690185154297",
  },
  {
    img: espaisubhasta02,
    placeholder: espaisubhasta02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Espai%20Subhasta/espaisubhasta02.jpg?updatedAt=1690185157115",
  },
  {
    img: espaisubhasta03,
    placeholder: espaisubhasta03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Espai%20Subhasta/espaisubhasta03.jpg?updatedAt=1690185157182",
  },
  {
    img: espaisubhasta04,
    placeholder: espaisubhasta04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Espai%20Subhasta/espaisubhasta04.jpg?updatedAt=1690185157563",
  },
];
export const elsigloImages = [
  {
    img: elsiglo01,
    placeholder: elsiglo01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/El%20Siglo/elsiglo01.jpg?updatedAt=1690185157543",
  },
  {
    img: elsiglo02,
    placeholder: elsiglo02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/El%20Siglo/elsiglo02.jpg?updatedAt=1690185160890",
  },
  {
    img: elsiglo03,
    placeholder: elsiglo03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/El%20Siglo/elsiglo03.jpg?updatedAt=1690185160895",
  },
  {
    img: elsiglo04,
    placeholder: elsiglo04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/El%20Siglo/elsiglo04.jpg?updatedAt=1690185161308",
  },
];

export const dabotiguesImages = [
  {
    img: dabotigues01,
    placeholder: dabotigues01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Botigues/dabotigues01.jpg?updatedAt=1690185161162",
  },
  {
    img: dabotigues02,
    placeholder: dabotigues02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Botigues/dabotigues02.jpg?updatedAt=1690185161120",
  },
  {
    img: dabotigues03,
    placeholder: dabotigues03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Botigues/dabotigues03.jpg?updatedAt=1690185164679",
  },
  {
    img: dabotigues04,
    placeholder: dabotigues04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Botigues/dabotigues04.jpg?updatedAt=1690185164825",
  },
  {
    img: dabotigues05,
    placeholder: dabotigues05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Botigues/dabotigues05.jpg?updatedAt=1690185164787",
  },
  {
    img: dabotigues06,
    placeholder: dabotigues06Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Botigues/dabotigues06.jpg?updatedAt=1690185165055",
  },
  {
    img: dabotigues07,
    placeholder: dabotigues07Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Districte%20Antic/Botigues/dabotigues07.jpg?updatedAt=1690185165081",
  },
];

export const barricasetesImages = [
  {
    img: barricasetes01,
    placeholder: barricasetes01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/barricasetes01.jpg?updatedAt=1690185204512",
  },
  {
    img: barricasetes02,
    placeholder: barricasetes02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/barricasetes02.jpg?updatedAt=1690185204485",
  },
  {
    img: barricasetes03,
    placeholder: barricasetes03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/barricasetes03.jpg?updatedAt=1690185204388",
  },
  {
    img: barricasetes04,
    placeholder: barricasetes04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/barricasetes04.jpg?updatedAt=1690185204420",
  },
  {
    img: barricasetes05,
    placeholder: barricasetes05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/barricasetes05.jpg?updatedAt=1690185204432",
  },
];

export const bcbotiguesImages = [
  {
    img: bcbotigues01,
    placeholder: bcbotigues01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues01.jpg?updatedAt=1690185207451",
  },
  {
    img: bcbotigues02,
    placeholder: bcbotigues02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues02.jpg?updatedAt=1690185207506",
  },
  {
    img: bcbotigues03,
    placeholder: bcbotigues03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues03.jpg?updatedAt=1690185207385",
  },
  {
    img: bcbotigues04,
    placeholder: bcbotigues04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues04.jpg?updatedAt=1690185207714",
  },
  {
    img: bcbotigues05,
    placeholder: bcbotigues05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues05.jpg?updatedAt=1690185207723",
  },
  {
    img: bcbotigues06,
    placeholder: bcbotigues06Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues06.jpg?updatedAt=1690185211139",
  },
  {
    img: bcbotigues07,
    placeholder: bcbotigues07Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues07.jpg?updatedAt=1690185211692",
  },
  {
    img: bcbotigues08,
    placeholder: bcbotigues08Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues08.jpg?updatedAt=1690185211477",
  },
  {
    img: bcbotigues09,
    placeholder: bcbotigues09Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues09.jpg?updatedAt=1690185211546",
  },
  {
    img: bcbotigues10,
    placeholder: bcbotigues10Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/Espais/Barri%20de%20les%20Casetes/Botigues/bcbotigues10.jpg?updatedAt=1690185211781",
  },
];

export const permanentImages = [
  {
    img: permanent01,
    placeholder: permanent01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Permanent/permanent01.jpg?updatedAt=1690185543731",
  },
  {
    img: permanent02,
    placeholder: permanent02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Permanent/permanent02.jpg?updatedAt=1690185545923",
  },
  {
    img: permanent03,
    placeholder: permanent03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Permanent/permanent03.jpg?updatedAt=1690185547222",
  },
  {
    img: permanent04,
    placeholder: permanent04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Permanent/permanent04.jpg?updatedAt=1690185550262",
  },
  {
    img: permanent05,
    placeholder: permanent05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Permanent/permanent05.jpg?updatedAt=1690185550419",
  },
];
export const setmanalImages = [
  {
    img: setmanal01,
    placeholder: setmanal01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Setmanal/setmanal01.jpg?updatedAt=1690185540190",
  },
  {
    img: setmanal02,
    placeholder: setmanal02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Setmanal/setmanal02.jpg?updatedAt=1690185540186",
  },
  {
    img: setmanal03,
    placeholder: setmanal03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Setmanal/setmanal03.jpg?updatedAt=1690185542910",
  },
  {
    img: setmanal04,
    placeholder: setmanal04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Setmanal/setmanal04.jpg?updatedAt=1690185546083",
  },
  {
    img: setmanal05,
    placeholder: setmanal05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/MercatsPage/Mercat%20Setmanal/setmanal05.jpg?updatedAt=1690185543088",
  },
];
export const espaiWemImages = [
  {
    path: "https://ik.imagekit.io/gwms6thri/Espai%20WEM/EspaiWEM1.jpg?updatedAt=1705589832371",
  },
  {
    path: "https://ik.imagekit.io/gwms6thri/Espai%20WEM/EspaiWEM2.jpg?updatedAt=1705589806939",
  },
  {
    path: "https://ik.imagekit.io/gwms6thri/Espai%20WEM/EspaiWEM3.jpg?updatedAt=1705589806799",
  },
  {
    path: "https://ik.imagekit.io/gwms6thri/Espai%20WEM/EspaiWEM4.jpg?updatedAt=1705589806669",
  },
  {
    path: "https://ik.imagekit.io/gwms6thri/Espai%20WEM/EspaiWEM5.jpg?updatedAt=1705589806905",
  },
  {
    path: "https://ik.imagekit.io/gwms6thri/Espai%20WEM/EspaiWEM6.jpg?updatedAt=1705589806822",
  },
];
