import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.backgroundColor};
  height: 80vh;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Title = styled.p`
  font-size: 38px;
  margin-top: 0;
  color: ${(props) => props.color};
  text-align: center;
  line-height: 110%;
  letter-spacing: -0.005em;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.color};
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 0;
`;
