export const ContacteData = {
  catala: {
    emailInfo: [
      { text: "General: ", email: "info@mercantinc.com", key: 0 },
      { text: "Comunicació: ", email: "comunicacio@mercantic.com", key: 1 },
      { text: "Esdeveniments: ", email: "eventos@mercantic.com", key: 2 },
    ],

    basicInfo: [
      "Av. de Rius i Taulet, 120, 081 73",
      "Sant Cugat del Vallès, Barcelona",
      "info@mercantic.com",
      "T: +34 93 674 49 50",
    ],
    howToGet: {
      first: {
        first: "Cotxe: ",
        second: "Per AP7 Sortida 13",
        third: "Per C-16 (Túnels de Vallvidrera) Sortida 10",
        fourth: "Per B-20 Sortida 16",
      },
      second: {
        first: "Tren: ",
        second: "FGC, Línia S2 parada",
        third: "Volpelleres (2 minuts a peu)",
      },
    },

    espais: [
      {
        space: "Nau Central",
        br1: "De dimarts a divendres de 10h a 20h",
        br2: "Dissabtes i Diumenges de 10h a 15h",
      },
      {
        space: "Barri de les Casetes",
        br1: "Divendres, dissabtes i diumenges de 10h a 15h.",
      },
      {
        space: "El Siglo",
        br1: "Dijous i diumenge de 10h a 15h.",
        br2: "Divendres i dissabte de 10h a 20h.",
      },
    ],
  },
  castella: {
    emailInfo: [
      { text: "General: ", email: "info@mercantinc.com" },
      { text: "Comunicación: ", email: "comunicacio@mercantic.com" },
      { text: "Eventos: ", email: "eventos@mercantic.com" },
    ],

    basicInfo: [
      "Av. de Rius i Taulet, 120, 081 73",
      "Sant Cugat del Vallès, Barcelona",
      "info@mercantic.com",
      "T: +34 93 674 49 50",
    ],
    howToGet: {
      first: {
        first: "Coche: ",
        second: "Por AP7 Salida 13",
        third: "Por C-16 (Túneles de Vallvidrera) Salida 10",
        fourth: "Por B-20 Salida 16",
      },
      second: {
        first: "Tren: ",
        second: "FGC, Línea S2 parada",
        third: "Volpelleres (2 minutos a pie)",
      },
    },

    espais: [
      {
        space: "Nau Central",
        br1: "De martes a viernes de 10:00 a 20:00.",
        br2: "Sábados y Domingos de 10:00 a 15:00.",
      },
      {
        space: "Barrio de les Casetes",
        br1: "Viernes, sábados y domingos de 10:00 a 15:00.",
      },
      {
        space: "El Siglo",
        br1: "Jueves y domingo de 10:00 a 15:00.",
        br2: "Viernes y sábado de 10:00 a 20:00.",
      },
    ],
  },
  angles: {
    emailInfo: [
      { text: "General: ", email: "info@mercantinc.com" },
      { text: "Communication: ", email: "comunicacio@mercantic.com" },
      { text: "Events: ", email: "eventos@mercantic.com" },
    ],

    basicInfo: [
      "Av. de Rius i Taulet, 120, 081 73",
      "Sant Cugat del Vallès, Barcelona",
      "info@mercantic.com",
      "T: +34 93 674 49 50",
    ],
    howToGet: {
      first: {
        first: "Car: ",
        second: "In AP7 Sortida 13",
        third: "In C-16 (Vallvidrera Roadside) Exit 10",
        fourth: "In B-20 Exit 16",
      },
      second: {
        first: "Train: ",
        second: "FGC, Línia S2 stop",
        third: "Volpelleres (2 minutes on foot)",
      },
    },
    espais: [
      {
        space: "Central Hall",
        br1: "Tuesday to Friday: 10:00h-20:00.",
        br2: "Saturday and Sunday: 10:00h-15:00h.",
      },
      {
        space: "Casetes Neighbourhood:",
        br1: "Fridays, Saturdays, and Sundays: 10:00h-15:00h",
      },
      {
        space: "El Siglo",
        br1: "Thursdays and Sundays: 10:00h-15:00h. ",
        br2: "Fridays and Saturdays: 10:00h-20:00h.",
      },
    ],
  },
};
