import React from "react";
import { useWindowSize } from "../../../../custom-hooks/useWindowSize";
import { IKImage } from "imagekitio-react";
//SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules"; // Importar mòduls des de 'swiper/modules'
// Importar el CSS de Swiper
import "swiper/swiper-bundle.css"; // Importació del CSS de Swiper
//STYLES
import "./DefaultCarousel.css";

const DefaultCarousel = ({ images, id }) => {
  const displayMobile = useWindowSize();
  return (
    <div className="defaultCarousel-container" id={id}>
      {images !== undefined && (
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          scrollbar={false}
          modules={[Pagination, Navigation]}
          style={{
            "--swiper-pagination-bullet-inactive-color": "red",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-inactive-backgroundColor": "blue",
          }}
        >
          {images.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                {displayMobile && (
                  <IKImage
                    src={image.path}
                    transformation={[
                      {
                        width: "700",
                      },
                    ]}
                    className="default-carousel__img"
                    lqip={{ active: true, quality: 20, blur: 10 }}
                  />
                )}
                {!displayMobile && (
                  <IKImage
                    src={image.path}
                    className="default-carousel__img"
                    lqip={{ active: true, quality: 20, blur: 10 }}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
};

export default DefaultCarousel;
