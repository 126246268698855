import llanes01 from "../assets/TallersPage/Llanes, cordes i fils/llanes01.jpg";
import llanes01Placeholder from "../assets/TallersPage/Llanes, cordes i fils/llanes01Placeholder.jpg";
import llanes02Placeholder from "../assets/TallersPage/Llanes, cordes i fils/llanes02Placeholder.jpg";
import llanes03Placeholder from "../assets/TallersPage/Llanes, cordes i fils/llanes03Placeholder.jpg";
import llanes04Placeholder from "../assets/TallersPage/Llanes, cordes i fils/llanes04Placeholder.jpg";
import llanes05Placeholder from "../assets/TallersPage/Llanes, cordes i fils/llanes05Placeholder.jpg";
import llanes02 from "../assets/TallersPage/Llanes, cordes i fils/llanes02.jpg";
import llanes03 from "../assets/TallersPage/Llanes, cordes i fils/llanes03.jpg";
import llanes04 from "../assets/TallersPage/Llanes, cordes i fils/llanes04.jpg";
import llanes05 from "../assets/TallersPage/Llanes, cordes i fils/llanes05.jpg";

import ceramica01 from "../assets/TallersPage/Ceràmica/ceramica01.jpg";
import ceramica01Placeholder from "../assets/TallersPage/Ceràmica/ceramica01Placeholder.jpg";
import ceramica02Placeholder from "../assets/TallersPage/Ceràmica/ceramica02Placeholder.jpg";
import ceramica03Placeholder from "../assets/TallersPage/Ceràmica/ceramica03Placeholder.jpg";
import ceramica04Placeholder from "../assets/TallersPage/Ceràmica/ceramica04Placeholder.jpg";
import ceramica05Placeholder from "../assets/TallersPage/Ceràmica/ceramica05Placeholder.jpg";
import ceramica02 from "../assets/TallersPage/Ceràmica/ceramica02.jpg";
import ceramica03 from "../assets/TallersPage/Ceràmica/ceramica03.jpg";
import ceramica04 from "../assets/TallersPage/Ceràmica/ceramica04.jpg";
import ceramica05 from "../assets/TallersPage/Ceràmica/ceramica05.jpg";

import llum01 from "../assets/TallersPage/Llums i vitralls/llums01.jpg";
import llum01Placeholder from "../assets/TallersPage/Llums i vitralls/llums01Placeholder.jpg";
import llum02Placeholder from "../assets/TallersPage/Llums i vitralls/llums02Placeholder.jpg";
import llum03Placeholder from "../assets/TallersPage/Llums i vitralls/llums03Placeholder.jpg";
import llum04Placeholder from "../assets/TallersPage/Llums i vitralls/llums04Placeholder.jpg";
import llum02 from "../assets/TallersPage/Llums i vitralls/llums02.jpg";
import llum03 from "../assets/TallersPage/Llums i vitralls/llums03.jpg";
import llum04 from "../assets/TallersPage/Llums i vitralls/llums04.jpg";

import restauracio01 from "../assets/TallersPage/Restauració mobles/restauraciomobles01.jpg";
import restauracio01Placeholder from "../assets/TallersPage/Restauració mobles/restauraciomobles01Placeholder.jpg";
import restauracio02Placeholder from "../assets/TallersPage/Restauració mobles/restauraciomobles02Placeholder.jpg";
import restauracio03Placeholder from "../assets/TallersPage/Restauració mobles/restauraciomobles03Placeholder.jpg";
import restauracio04Placeholder from "../assets/TallersPage/Restauració mobles/restauraciomobles04Placeholder.jpg";
import restauracio02 from "../assets/TallersPage/Restauració mobles/restauraciomobles02.jpg";
import restauracio03 from "../assets/TallersPage/Restauració mobles/restauraciomobles03.jpg";
import restauracio04 from "../assets/TallersPage/Restauració mobles/restauraciomobles04.jpg";

import extraescolars01 from "../assets/TallersPage/Extraescolars/extraescolars01.webp";
import extraescolars02 from "../assets/TallersPage/Extraescolars/extraescolars02.webp";
import extraescolars03 from "../assets/TallersPage/Extraescolars/extraescolars03.jpeg";
import extraescolars03Placeholder from "../assets/TallersPage/Extraescolars/extraescolars03Placeholder.jpg";
import extraescolars04Placeholder from "../assets/TallersPage/Extraescolars/extraescolars04Placeholder.jpg";

import extraescolars04 from "../assets/TallersPage/Extraescolars/extraescolars04.jpg";

import floristeria01 from "../assets/TallersPage/Floristeria/floristeria01.jpg";
import floristeria01Placeholder from "../assets/TallersPage/Floristeria/floristeria01Placeholder.jpg";
import floristeria02Placeholder from "../assets/TallersPage/Floristeria/floristeria02Placeholder.jpg";
import floristeria03Placeholder from "../assets/TallersPage/Floristeria/floristeria03Placeholder.jpg";
import floristeria04Placeholder from "../assets/TallersPage/Floristeria/floristeria04Placeholder.jpg";
import floristeria02 from "../assets/TallersPage/Floristeria/floristeria02.jpg";
import floristeria03 from "../assets/TallersPage/Floristeria/floristeria03.jpg";
import floristeria04 from "../assets/TallersPage/Floristeria/floristeria04.jpg";

export const llanesImages = [
  {
    img: llanes01,
    placeholder: llanes01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Llanes,%20cordes%20i%20fils/llanes01.jpg?updatedAt=1690185587607",
  },
  {
    img: llanes02,
    placeholder: llanes02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Llanes,%20cordes%20i%20fils/llanes02.jpg?updatedAt=1690185593958",
  },
  {
    img: llanes03,
    placeholder: llanes03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Llanes,%20cordes%20i%20fils/llanes03.jpg?updatedAt=1690185590826",
  },
  {
    img: llanes04,
    placeholder: llanes04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Llanes,%20cordes%20i%20fils/llanes04.jpg?updatedAt=1690185590679",
  },
  {
    img: llanes05,
    placeholder: llanes05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Llanes,%20cordes%20i%20fils/llanes05.jpg?updatedAt=1690185590823",
  },
];

export const restauracioMoblesImages = [
  {
    img: restauracio01,
    placeholder: restauracio01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Restauraci%C3%B3%20mobles/restauraciomobles01.jpg?updatedAt=1690185577166",
  },
  {
    img: restauracio02,
    placeholder: restauracio02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Restauraci%C3%B3%20mobles/restauraciomobles02.jpg?updatedAt=1690185577238",
  },
  {
    img: restauracio03,
    placeholder: restauracio03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Restauraci%C3%B3%20mobles/restauraciomobles03.jpg?updatedAt=1690185583717",
  },
  {
    img: restauracio04,
    placeholder: restauracio04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Restauraci%C3%B3%20mobles/restauraciomobles04.jpg?updatedAt=1690185585529",
  },
];

export const floristeriaImages = [
  {
    img: floristeria01,
    placeholder: floristeria01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Floristeria/floristeria01.jpg?updatedAt=1690185603461",
  },
  {
    img: floristeria02,
    placeholder: floristeria02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Floristeria/floristeria02.jpg?updatedAt=1690185594324",
  },
  {
    img: floristeria03,
    placeholder: floristeria03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Floristeria/floristeria03.jpg?updatedAt=1690185594335",
  },
  {
    img: floristeria04,
    placeholder: floristeria04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Floristeria/floristeria04.jpg?updatedAt=1690185594293",
  },
];

export const extraescolarsImages = [
  {
    img: extraescolars01,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Extraescolars/extraescolars01.webp?updatedAt=1690185594418",
  },
  {
    img: extraescolars02,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Extraescolars/extraescolars02.webp?updatedAt=1690185594729",
  },
  {
    img: extraescolars03,
    placeholder: extraescolars03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Extraescolars/extraescolars03.jpeg?updatedAt=1690185598027",
  },
  {
    img: extraescolars04,
    placeholder: extraescolars04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Extraescolars/extraescolars04.jpg?updatedAt=1690185598420",
  },
];

export const ceramicaImages = [
  {
    img: ceramica01,
    placeholder: ceramica01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Cer%C3%A0mica/ceramica01.jpg?updatedAt=1690185598367",
  },
  {
    img: ceramica02,
    placeholder: ceramica02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Cer%C3%A0mica/ceramica02.jpg?updatedAt=1690185598440",
  },
  {
    img: ceramica03,
    placeholder: ceramica03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Cer%C3%A0mica/ceramica03.jpg?updatedAt=1690185598905",
  },
  {
    img: ceramica04,
    placeholder: ceramica04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Cer%C3%A0mica/ceramica04.jpg?updatedAt=1690185602851",
  },
  {
    img: ceramica05,
    placeholder: ceramica05Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Cer%C3%A0mica/ceramica05.jpg?updatedAt=1690185602689",
  },
];

export const llumImages = [
  {
    img: llum01,
    placeholder: llum01Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Llums%20i%20vitralls/llums01.jpg?updatedAt=1690185586834",
  },
  {
    img: llum02,
    placeholder: llum02Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Llums%20i%20vitralls/llums02.jpg?updatedAt=1690185586823",
  },
  {
    img: llum03,
    placeholder: llum03Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Llums%20i%20vitralls/llums03.jpg?updatedAt=1690185586910",
  },
  {
    img: llum04,
    placeholder: llum04Placeholder,
    path: "https://ik.imagekit.io/gwms6thri/TallersPage/Llums%20i%20vitralls/llums04.jpg?updatedAt=1690185586908",
  },
];
