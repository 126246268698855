import React, { useContext, useEffect } from "react";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import empresaImage from "../../../assets/EsdevenimentPage/empresaImage.jpg";

import { useWindowSize } from "../../../custom-hooks/useWindowSize";

import "../EsdevenimentPage/EsdevenimentPage.css";
import "./EmpresaPage.css";
const EmpresaPage = () => {
  const { data } = useContext(LanguageActivity);
  const { setNavbarColor, setHamburgerColor } = useContext(NavbarActivity);
  const displayMobile = useWindowSize();
  useEffect(() => {
    setNavbarColor("var(--mercagreen)");
    setHamburgerColor("var(--mercagreen)");
  }, []);

  return (
    <div className="esdevenimentPage-container">
      {data.empresa !== undefined && (
        <div className="esdevenimentPage-content-container">
          <div className="esdevenimentPage-content-title">
            {data.empresa.title}
          </div>
          <div className="esdevenimentPage-content-text">
            {data.empresa.text}
          </div>
          <div className="esdevenimentPage-content-footer">
            <a
              className="empresaPage-link"
              href="mailto:comunicacio@mercantic.com"
            >
              {data.empresa.footer.item1}
            </a>
          </div>
        </div>
      )}
      {displayMobile === true ? (
        <img src={empresaImage} alt="event" className="esdevenimentPage-img" />
      ) : (
        <div style={{ width: "50%" }}>
          <img
            src={empresaImage}
            alt="event"
            className="esdevenimentPage-img"
          />
        </div>
      )}
    </div>
  );
};

export default EmpresaPage;
