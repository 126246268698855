import { useContext, useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ItemsWrapper,
  ItemContainer,
  ItemH3,
  CrossContainer,
  Cross,
  Img,
} from "./HomePageMenuStyles";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
//IMAGES
import plus from "../../../assets/HomePage/plus.svg";
import espais from "../../../assets/HomePage/HomePage-flipMenu-space.jpg";
import mercats from "../../../assets/HomePage/HomePage-flipMenu-markets.jpg";
import gastronomia from "../../../assets/HomePage/HomePage-flipMenu-gastronomy.jpg";
import tallers from "../../../assets/HomePage/HomePage-flipMenu-workshops.jpg";
import agenda from "../../../assets/HomePage/HomePage-flipMenu-agenda.jpg";
const HomePageMenu = () => {
  const { data } = useContext(LanguageActivity);
  const displayMobile = useWindowSize();
  const [background, setBackground] = useState("var(--skyblue)");
  const [color, setColor] = useState("var(--mercagreen)");
  const [controller, setController] = useState(null);
  //eslint-disable-next-line
  const [imgName, setImgName] = useState("");
  const navigate = useNavigate();
  const { setDynamicBackgroundColor, setNavbarColor, setHamburgerColor } =
    useContext(NavbarActivity);
  useEffect(() => {
    switch (controller) {
      // case 0:
      //   setBackground("var(--mercablue)");
      //   setColor("var(--skyblue)");
      //   setImgName(espais);
      //   setDynamicBackgroundColor("var(--mercablue)");
      //   setNavbarColor("var(--skyblue)");
      //   setHamburgerColor("var(--skyblue)");
      //   break;
      // case 1:
      //   setBackground("var(--skyblue)");
      //   setColor("var(--mercared)");
      //   setImgName(mercats);
      //   setDynamicBackgroundColor("var(--skyblue)");
      //   setNavbarColor("var(--mercared)");
      //   setHamburgerColor("var(--mercared)");
      //   break;
      case 2:
        setBackground("var(--lightgreen)");
        setColor("var(--mercagreen)");
        setImgName(tallers);
        setDynamicBackgroundColor("var(--lightgreen)");
        setNavbarColor("var(--mercagreen)");
        setHamburgerColor("var(--mercagreen)");
        break;
      case 3:
        setBackground("var(--mercapink)");
        setColor("var(--mercabrown)");
        setImgName(gastronomia);
        setDynamicBackgroundColor("var(--mercapink)");
        setNavbarColor("var(--mercabrown)");
        setHamburgerColor("var(--mercabrown)");
        break;
      case 4:
        setBackground("var(--skyblue)");
        setColor("var(--mercagreen)");
        setImgName(agenda);
        setDynamicBackgroundColor("var(--skyblue)");
        setNavbarColor("var(--mercagreen)");
        setHamburgerColor("var(--mercagreen)");
        break;
      case null:
        setBackground("var(--skyblue)");
        setColor("var(--mercagreen)");
        setImgName(null);
        setDynamicBackgroundColor("var(--skyblue)");
        setNavbarColor("var(--mercagreen)");
        setHamburgerColor("var(--skyblue)");
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [controller]);

  if (!displayMobile) {
    return (
      <ItemsWrapper backgroundColor={background}>
        <>
          <Img
            src={espais}
            alt="espais"
            display={controller === 0 ? "1" : "0"}
            right={"330px"}
            top={"170px"}
          />
          <Img
            src={mercats}
            alt="mercats"
            display={controller === 1 ? "1" : "0"}
            left={"460px"}
            bottom={"100px"}
          />
          <Img
            src={tallers}
            alt="tallers"
            display={controller === 2 ? "1" : "0"}
            right={"175px"}
            top={"80px"}
          />
          <Img
            src={gastronomia}
            alt="gastronomia"
            display={controller === 3 ? "1" : "0"}
            left={"530px"}
            top={"340px"}
          />
          <Img
            src={agenda}
            alt="agenda"
            display={controller === 4 ? "1" : "0"}
            top={"80px"}
            right={"520px"}
            heightController={true}
          />
        </>

        {data.homePage !== undefined &&
          data.homePage.frame7 !== undefined &&
          data.homePage.frame7.map((item, index) => {
            return (
              <ItemContainer
                backgroundColor={color}
                border={item.key === 0 ? true : false}
                background={background}
                onMouseEnter={() => {
                  if (!displayMobile) {
                    setController(item.key);
                  }
                }}
                onMouseLeave={() => {
                  setController(null);
                }}
                key={index}
                onClick={() => {
                  navigate(`/${item.route}`);
                }}
              >
                <ItemH3
                  opacity={
                    controller === null || controller === item.key ? "1" : "0.5"
                  }
                  color={color}
                >
                  {item.title}
                </ItemH3>

                {displayMobile ? (
                  <img alt="plus" src={plus} />
                ) : (
                  <CrossContainer>
                    <Cross color={color}></Cross>
                  </CrossContainer>
                )}
              </ItemContainer>
            );
          })}
      </ItemsWrapper>
    );
  } else if (displayMobile) {
    return (
      <ItemsWrapper backgroundColor={background}>
        {data.homePage !== undefined &&
          data.homePage.frame7 !== undefined &&
          data.homePage.frame7.map((item, index) => {
            return (
              <ItemContainer
                backgroundColor={color}
                border={item.key === 0 ? true : false}
                background={background}
                key={index}
                onClick={() => {
                  navigate(`/${item.route}`);
                }}
              >
                <ItemH3 color={"var(--mercagreen)"}>{item.title}</ItemH3>

                <img alt="plus" src={plus} />
              </ItemContainer>
            );
          })}
      </ItemsWrapper>
    );
  }
};

export default HomePageMenu;
