import { useState, useEffect } from "react";

export const useScroll = () => {
  const [scroll, setScroll] = useState(window.scrollY > 0);
  const [scrollPercent, setScrollPercent] = useState(0);
  const triggerAnimation = (e) => {
    let scrollTop = window.scrollY;
    let winHeight = window.innerHeight;
    setScrollPercent(Math.round((scrollTop / winHeight) * 100));
  };

  const handleScroll = () => {
    setScroll(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", () => {
      triggerAnimation();
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", (e) => triggerAnimation(e));
    };
  }, []);

  return {
    scroll,
    setScroll,
    scrollPercent,
    setScrollPercent,
  };
};
