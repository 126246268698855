import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules"; // Canviar a 'swiper/modules'
import "swiper/swiper-bundle.css"; // Canviar a 'swiper/swiper-bundle.css'
import { Container, Title, Text, Card } from "./EspaisCarouselStyles.js";
import "./EspaisCarousel.css";
import { useParams } from "react-router-dom";

const EspaisCarousel = ({ info, id, cta, cta2, cta3 }) => {
  const [beginning, setBeginning] = useState(true);
  const [mySwiper, setMySwiper] = useState(null); // Referència al Swiper
  const params = useParams();
  // Inicialitza el Swiper com una variable
  let swiper;

  const scrollElementIntoView = (element) => {
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    if (typeof Swiper === "function" && !mySwiper) {
      // Inicialitza el Swiper només una vegada
      swiper = new Swiper(".swiper-container", {
        // Configuració de Swiper
      });
      setMySwiper(swiper); // Actualitza la referència al Swiper
    }
    console.log("swiper inicialitzat");
  }, [mySwiper]);

  useEffect(() => {
    if (mySwiper && params.section) {
      try {
        if (
          params.section === "naucentral" ||
          params.section === "rochebobois" ||
          params.section === "launio" ||
          params.section === "lapuntual" ||
          params.section === "escolarestauracio" ||
          params.section === "botigues"
        ) {
          const element = document.getElementById("naucentral");
          scrollElementIntoView(element);
          if (params.section === "launio") {
            mySwiper.slideTo(1);
          } else if (params.section === "lapuntual") {
            mySwiper.slideTo(1);
          } else if (params.section === "escoltarestauracio") {
            mySwiper.slideTo(4);
          } else if (params.section === "rochebobois") {
            mySwiper.slideTo(4);
          }
        } else if (
          params.section === "districteantic" ||
          params.section === "espaisubhasta" ||
          params.section === "botiguesdistricte"
        ) {
          const element = document.getElementById("districteantic");
          scrollElementIntoView(element);
          if (params.section === "espaisubhasta") {
            mySwiper.slideTo(1);
          }
        } else if (params.section === "elsiglo") {
          const element = document.getElementById("districteantic");
          scrollElementIntoView(element);
        } else if (params.section === "barricasetes") {
          const element = document.getElementById("barricasetes");
          scrollElementIntoView(element);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }, [params.section, mySwiper]);

  return (
    <Container id={id.idName}>
      <Swiper
        modules={[Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        scrollbar={false}
        onSwiper={(swiper) => {
          setMySwiper(swiper);
        }}
        style={{
          "--swiper-pagination-bullet-inactive-color": "red",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-inactive-backgroundColor": "blue",
        }}
        onSlideChange={() => {
          if (beginning) {
            document.documentElement.style.setProperty(
              "--pagination-color",
              "var(--skyblue)"
            );
            setBeginning(false);
          } else if (!beginning) {
            document.documentElement.style.setProperty(
              "--pagination-color",
              "var(--skyblue)"
            );
          }
        }}
        onReachBeginning={() => {
          setBeginning(true);
        }}
      >
        {info !== undefined &&
          info.map((item, index) => {
            if (item.title === "Barri de les Casetes") {
              return (
                <SwiperSlide>
                  <Card
                    background={
                      index === 0 ? "var(--skyblue)" : "var(--mercablue)"
                    }
                    id={index}
                    paddingTop={"15%"}
                  >
                    <Title
                      color={
                        index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                      }
                    >
                      {item.title}
                    </Title>
                    <Text
                      color={
                        index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                      }
                    >
                      {item.text}
                    </Text>
                    {item.text2 !== undefined && (
                      <Text
                        color={
                          index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                        }
                      >
                        {item.text2}
                      </Text>
                    )}
                  </Card>
                </SwiperSlide>
              );
            } else if (
              item.title !== "Barri de les Casetes" &&
              item.title !== "Roche Bobois"
            ) {
              return (
                <SwiperSlide>
                  <Card
                    background={
                      index === 0 ? "var(--skyblue)" : "var(--mercablue)"
                    }
                    id={index}
                    paddingTop={item.escola === true ? "2rem" : "35%"}
                    margin
                  >
                    <Title
                      color={
                        index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                      }
                      marginBottom={item.escola === true ? "0" : "none"}
                    >
                      {item.title}
                    </Title>
                    <Text
                      color={
                        index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                      }
                    >
                      {item.text}
                    </Text>
                    {item.text2 !== undefined && (
                      <Text
                        color={
                          index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                        }
                      >
                        {item.text2}
                      </Text>
                    )}
                    {cta !== undefined && index === 1 && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          style={{ color: "var(--skyblue)" }}
                          href="https://www.launiodebrocanters.com/es/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {cta}
                        </a>
                      </div>
                    )}
                  </Card>
                </SwiperSlide>
              );
            } else if (item.title === "Roche Bobois") {
              return (
                <SwiperSlide>
                  <Card
                    background={
                      index === 0 ? "var(--skyblue)" : "var(--mercablue)"
                    }
                    id={index}
                    paddingTop="4rem"
                    margin
                  >
                    <Title
                      color={
                        index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                      }
                      marginBottom={item.escola === true ? "0" : "auto"}
                    >
                      {item.title}
                    </Title>
                    <Text
                      color={
                        index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                      }
                    >
                      {item.text}
                    </Text>
                    {item.text2 !== undefined && (
                      <Text
                        color={
                          index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                        }
                      >
                        {item.text2}
                      </Text>
                    )}
                    {item.text3 !== undefined && (
                      <Text
                        color={
                          index === 0 ? "var(--mercablue)" : "var(--skyblue)"
                        }
                      >
                        {item.text3}
                      </Text>
                    )}
                    {cta2 !== undefined && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          style={{ color: "var(--skyblue)" }}
                          href="mailto:rbmercantic@roche-bobois.es"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {cta2}
                        </a>
                      </div>
                    )}
                    {cta3 !== undefined && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <a
                          style={{ color: "var(--skyblue)" }}
                          href="tel:639 279 144"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {cta3}
                        </a>
                      </div>
                    )}
                  </Card>
                </SwiperSlide>
              );
            }
            return null;
          })}
      </Swiper>
    </Container>
  );
};

export default EspaisCarousel;
