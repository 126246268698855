//REACT
import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { UserActivity } from "../../../context/UserActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { agendaData } from "../../../data/AgendaData";
import AgendaColumn from "../../Individual/AgendaColumn/AgendaColumn";
//STYLES
import "./AgendaPage.css";

//COMPONENTS
import AgendaCard from "../../Individual/AgendaCard/AgendaCard";
import AgendaFilter from "../../Individual/AgendaFilter/AgendaFilter";

const AgendaPage = () => {
  const displayMobile = useWindowSize();
  const [currentDate, setCurrentDate] = useState(null);
  const { data } = useContext(LanguageActivity);
  const { setNavbarColor, setHamburgerColor, setNavbarBackground } =
    useContext(NavbarActivity);
  const { dropdownMenuSelected } = useContext(UserActivity);

  useEffect(() => {
    setNavbarColor("var(--skyblue)");
    setHamburgerColor("var(--skyblue)");
    setNavbarBackground("var(--mercagreen)");

    const current = new Date();
    const date = current.getDate();
    setCurrentDate(date);
    //eslint-disable-next-line
  }, []);
  if (displayMobile) {
    return (
      <>
        <div
          className={dropdownMenuSelected ? "overflow" : "agenda__container"}
        >
          <div className="agenda-mobile-fixingDiv"></div>
          {data.agenda !== undefined && (
            <div className="agenda-mobile__month-container">
              <div className="agenda-mobile__month-slide">
                <div className="agenda-mobile__month-wrapper">
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                </div>
                <div className="agenda-mobile__month-wrapper">
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                  <span>{data.agenda.month}</span>
                </div>
              </div>
            </div>
          )}

          {data.agenda !== undefined &&
            //eslint-disable-next-line
            data.agenda[1].map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={currentDate}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}
          {data.agenda !== undefined &&
            //eslint-disable-next-line
            data.agenda[2].map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={currentDate}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}
          {data.agenda !== undefined &&
            //eslint-disable-next-line
            data.agenda[3].map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={currentDate}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}

          {data.agenda !== undefined &&
            //eslint-disable-next-line
            data.agenda[4].map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={currentDate}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}

          {data.agenda !== undefined &&
            //eslint-disable-next-line
            data.agenda[5].map((item, index) => {
              if (item.display) {
                return (
                  <AgendaCard
                    display={item.display}
                    day={item.dia}
                    number={item.number}
                    activities={item.activities}
                    key={index}
                    date={`${currentDate}/07`}
                    holidays={item.holidays}
                  />
                );
              } else if (item.display === false) {
                return null;
              }
            })}

          {data.agenda !== undefined && (
            <AgendaFilter
              info={agendaData.catala.filterMenu.slice(1)}
              month={data.agenda.month}
            />
          )}
        </div>
      </>
    );
  } else if (!displayMobile) {
    return (
      <>
        <div className="agenda__day__column">
          {data.agenda !== undefined &&
            data.agenda[1].map((item, index) => {
              return (
                <div className="agenda__day__column__wrapper" key={index}>
                  <p>{item.dia}</p>
                </div>
              );
            })}
        </div>
        <div className="agenda__container">
          {data.agenda !== undefined && (
            <>
              <div className="agenda__row__container">
                {data.agenda[1].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <div className="agenda__day__column">
                {data.agenda !== undefined &&
                  data.agenda[1].map((item, index) => {
                    return (
                      <div className="agenda__day__column__wrapper" key={index}>
                        <p>{item.dia}</p>
                      </div>
                    );
                  })}
              </div>
              <div className="agenda__row__container">
                {data.agenda[2].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <div className="agenda__day__column">
                {data.agenda !== undefined &&
                  data.agenda[1].map((item, index) => {
                    return (
                      <div className="agenda__day__column__wrapper" key={index}>
                        <p>{item.dia}</p>
                      </div>
                    );
                  })}
              </div>
              <div className="agenda__row__container">
                {data.agenda[3].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <div className="agenda__day__column">
                {data.agenda !== undefined &&
                  data.agenda[1].map((item, index) => {
                    return (
                      <div className="agenda__day__column__wrapper" key={index}>
                        <p>{item.dia}</p>
                      </div>
                    );
                  })}
              </div>
              <div className="agenda__row__container">
                {data.agenda[4].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <div className="agenda__day__column">
                {data.agenda !== undefined &&
                  data.agenda[1].map((item, index) => {
                    return (
                      <div className="agenda__day__column__wrapper" key={index}>
                        <p>{item.dia}</p>
                      </div>
                    );
                  })}
              </div>
              <div className="agenda__row__container">
                {data.agenda[5].map((item, index) => {
                  return (
                    <AgendaColumn
                      column={item.activities}
                      display={item.display}
                      day={item.day}
                      number={item.number}
                      key={index}
                    />
                  );
                })}
              </div>
              <AgendaFilter
                info={data.agendaFilter}
                month={data.agenda.month}
              />
            </>
          )}
        </div>
      </>
    );
  }
};

export default AgendaPage;
