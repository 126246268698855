import React, { useContext } from "react";
import "./CookiesBanner.css";
import { TfiClose } from "react-icons/tfi";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { UserActivity } from "../../../context/UserActivity";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { useNavigate } from "react-router-dom";
const CookiesBanner = () => {
  const { data } = useContext(LanguageActivity);
  const {
    setNavbarColor,
    setHamburgerColor,
    setNavbarBackground,
    setNavbarOpacity,
  } = useContext(NavbarActivity);
  const navigate = useNavigate();
  const { setDisplayCookiesBanner } = useContext(UserActivity);
  return (
    <div className="cookies-banner__container">
      {data.homePage !== undefined && (
        <p>
          <span>{data.homePage.cookies.br1}</span>
          <div>
            <span>{data.homePage.cookies.br2}</span>"
            <span
              className="cookies__link-span"
              onClick={() => {
                navigate("/cookies");
                setHamburgerColor("var(--mercagreen)");
                setNavbarColor("var(--mercagreen)");
                setNavbarBackground("transparent");
                setNavbarOpacity(1);
              }}
            >
              {data.homePage.cookies.br3}
            </span>
            "<span>{data.homePage.cookies.br4}</span>.
          </div>
        </p>
      )}
      <TfiClose
        size={40}
        className="cookies__close"
        onClick={() => {
          setDisplayCookiesBanner(false);
        }}
      />
    </div>
  );
};

export default CookiesBanner;
