//REACT
import React, { useContext, useState } from "react";
import { UserActivity } from "../../../context/UserActivity";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
//STYLES
import "./AgendaFilter.css";
import MobileSectionMenu from "../MobileSectionMenu/MobileSectionMenu";
const AgendaFilter = ({ info, month }) => {
  const { setFilterSelected, filterSelected } = useContext(UserActivity);
  const { data } = useContext(LanguageActivity);
  const [displayFilterMenu, setDisplayFilterMenu] = useState(false);
  const displayMobile = useWindowSize();

  const handleFilter = (filter) => {
    if (!displayMobile) {
      if (filter === filterSelected) {
        setFilterSelected("all");
      } else if (filter !== filterSelected) {
        setFilterSelected(filter);
      }
    }
  };
  /*eslint-disable*/
  if (!displayMobile) {
    return (
      <>
        <div
          className="agenda-filter__container"
          onClick={() => {
            console.log(info);
          }}
        >
          <div className="agenda-month__container">
            {month !== undefined && (
              <>
                <div className="agenda-month__wrapper">
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                </div>
                <div className="agenda-month__wrapper">
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                  <p>{month}</p>
                </div>
              </>
            )}
          </div>
          <div className="agenda-filter__item-wrapper-id0">
            <div className="id0-bullet"></div>
            <p>{info?.permanent}</p>
          </div>
          {info !== undefined &&
            info?.filter?.map((item) => {
              return (
                <div
                  className={
                    filterSelected === item.filter || filterSelected === "all"
                      ? "agenda-filter__item-wrapper"
                      : "agenda-filter__item-wrapper opacity-filter"
                  }
                  onClick={() => {
                    handleFilter(item.filter);
                  }}
                >
                  <p>{item.name}</p>
                </div>
              );
            })}
        </div>
      </>
    );
  } else if (displayMobile) {
    return (
      <>
        {data.agenda !== undefined && !displayFilterMenu && (
          <div
            className="filter-container"
            onClick={() => {
              setDisplayFilterMenu(true);
            }}
          >
            <p>{info.filter}</p>
          </div>
        )}
        {data.agenda !== undefined && displayFilterMenu && (
          <MobileSectionMenu
            items={info}
            backgroundColor={"var(--mercagreen)"}
            color={"var(--skyblue)"}
            filter={true}
            setDisplayFilterMenu={setDisplayFilterMenu}
            setFilterSelected={setFilterSelected}
          />
        )}
      </>
    );
  }
};

export default AgendaFilter;
