import styled from "styled-components";

export const NavbarContainer = styled.div`
  height: 35px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 997;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${(props) => props.background};
  opacity: ${(props) => props.opacity};
  transition: opacity 0.5s;
  padding-top: 15px;
  margin-top: ${(props) => props.margin};
`;

export const NavbarText = styled.p`
  font-variant-alternates: stylistic(alternates);
  color: ${(props) => props.color};
  transition: color 0.5s ease-out;
  font-size: 21px;
  margin-bottom: 0;
  margin-top: -4px;
  :hover {
    cursor: pointer;
  }
`;

export const HamburgerContainer = styled.div`
  height: 21px;
  display: flex;
  flex-direction: column;
  width: 25px;
  justify-content: space-between;
  padding-top: 1px;
  :hover {
    cursor: pointer;
  }
`;

export const HamburgerRow = styled.div`
  width: 100%;
  height: 2.5px;
  background-color: ${(props) => props.backgroundColor};
  transition: background-color 0.5s ease-out;
`;
