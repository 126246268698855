import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router";
import { UserActivity } from "../../../context/UserActivity";

const ScrollToTop = (props) => {
  const { enableScrollToTop } = useContext(UserActivity);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/spaces/rochebobois") {
      return;
    } else if (
      location.pathname !== "/spaces/rochebobois" &&
      enableScrollToTop
    ) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
