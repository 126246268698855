//REACT
import React, { useContext, useState, useEffect, useRef } from "react";
import { LanguageActivity } from "../../../context/LanguageActivity";
import { UserActivity } from "../../../context/UserActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { NavbarActivity } from "../../../context/NavbarActivity";
import { HashLink } from "react-router-hash-link";
import { useScroll } from "../../../custom-hooks/useScroll";
import { IKImage } from "imagekitio-react";
import { useNavigate, useParams } from "react-router-dom";

//STYLES
import "./EspaisPage.css";
import {
  Link,
  LinkContainer,
  MobileLink,
  MobileLinkContainer,
} from "./EspaisPageStyles";
//IMAGES
import logo from "../../../assets/Footer/EspaisFooter.svg";
//COMPONENTS
import InitialPage from "../../Individual/InitialPage/InitialPage";
import EspaisDefaultPage from "../../Individual/DefaultPages/EspaisDefaultPage/EspaisDefaultPage";
import MobileSectionMenu from "../../Individual/MobileSectionMenu/MobileSectionMenu";
import Footer from "../../Individual/Footer/Footer";
import DefaultCarousel from "../../Individual/DefaultPages/DefaultCarousel/DefaultCarousel";

import {
  barricasetesImages,
  districteanticImages,
  naucentralImages,
  launioImages,
  lapuntualImages,
  escolaImages,
  ncbotiguesImages,
  espaisubhastaImages,
  elsigloImages,
  dabotiguesImages,
  bcbotiguesImages,
  boboisImages,
  espaiWemImages,
} from "../../../data/ImagesData";
import EspaisPageDefaultContainer from "../../Individual/EspaisPageDefaultContainer/EspaisPageDefaultContainer";
import SubEspaisDefaultPage from "../../Individual/DefaultPages/SubEspaisDefaultPage/SubEspaisDefaultPage";

const EspaisPage = () => {
  const { data } = useContext(LanguageActivity);
  const {
    scheduleToSpacesController,
    setScheduleToSpacesController,
    setEnableScrollToTop,
  } = useContext(UserActivity);
  const [scrollToTopController, setScrollToTopController] = useState(true);
  const firstRender = useRef(true);
  const params = useParams();
  const [paramsSection, setParamsSection] = useState("");
  const { dropdownMenuSelected } = useContext(UserActivity);

  const [controller1, setController1] = useState({ boolean: false, key: 0 });
  const [images1, setImages1] = useState(naucentralImages);

  const [controller2, setController2] = useState({ boolean: false, key: 0 });
  const [images2] = useState(elsigloImages);

  const [controller3, setController3] = useState({ boolean: false, key: 0 });
  const [images3, setImages3] = useState(districteanticImages);
  //eslint-disable-next-line
  const [controller4, setController4] = useState({ boolean: false, key: 0 });
  //eslint-disable-next-line
  const [images4, setImages4] = useState(barricasetesImages);

  // const [controller5, setController5] = useState({ boolean: false, key: 0 });
  //eslint-disable-next-line
  const [images5, setImages5] = useState(espaiWemImages);
  const displayMobile = useWindowSize();
  const { setNavbarColor, setHamburgerColor } = useContext(NavbarActivity);
  const { mobileSectionMenuSelected, setMobileSectionMenuSelected } =
    useContext(UserActivity);

  const handleOpacity = (index) => {
    if (index === 0) {
      if (scrollPercent <= 150) {
        return 1;
      } else return 0.5;
    }
    if (index === 1) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 150 && scrollPercent < 250) {
        return 1;
      } else return 0.5;
    }
    if (index === 2) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 250 && scrollPercent < 350) {
        return 1;
      } else return 0.5;
    }
    if (index === 3) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 350 && scrollPercent < 450) {
        return 1;
      } else return 0.5;
    }
    if (index === 4) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 450 && scrollPercent < 550) {
        return 1;
      } else return 0.5;
    }
    if (index === 5) {
      if (scrollPercent === 0) {
        return 1;
      }
      if (scrollPercent >= 550) {
        return 1;
      } else return 0.5;
    }
  };
  const { scrollPercent } = useScroll();
  useEffect(() => {
    if (scrollToTopController === false) {
      setEnableScrollToTop(false);
      setScrollToTopController(false);
    }
  }, [scrollToTopController]);
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--pagination-color",
      "var(--mercablue)"
    );
    document.documentElement.style.setProperty("--swiper-wrapper-padding", "0");
    if (displayMobile) {
      if (!dropdownMenuSelected) {
        setNavbarColor("var(--skyblue)");
        setHamburgerColor("var(--skyblue)");
      }
    }
    if (params.section) {
      if (
        params.section === "naucentral" ||
        params.section === "rochebobois" ||
        params.section === "launio" ||
        params.section === "lapuntual" ||
        params.section === "escolarestauracio" ||
        params.section === "botigues"
      ) {
        setParamsSection("naucentral");
        if (params.section === "launio") {
          setController1({ boolean: true, key: 1 });
          window.history.pushState(null, "", "launio");
        } else if (params.section === "naucentral") {
          setController1({ boolean: false, key: 0 });
          window.history.pushState(null, "", "naucentral");
        } else if (params.section === "lapuntual") {
          setController1({ boolean: true, key: 2 });
          window.history.pushState(null, "", "lapuntual");
        } else if (params.section === "escolarestauracio") {
          setController1({ boolean: true, key: 3 });
          window.history.pushState(null, "", "escolarestauracio");
        } else if (params.section === "rochebobois") {
          setController1({ boolean: true, key: 4 });
          window.history.pushState(null, "", "rochebobois");
        } else if (params.section === "rochebobois") {
          setController1({ boolean: true, key: 5 });
          window.history.pushState(null, "", "botigues");
        }
      }
      //  else if (params.section === "elsiglo") {
      //   setParamsSection("elsiglo");
      //   window.history.pushState(null, "", "/spaces/elsiglo");
      // }
      else if (
        params.section === "districteantic" ||
        params.section === "espaisubhasta" ||
        params.section === "botiguesdistricte"
      ) {
        setParamsSection("districteantic");
        if (params.section === "districteantic") {
          setController3({
            key: 0,
            boolean: false,
          });
        } else if (params.section === "espaisubhasta") {
          setController3({ boolean: true, key: 1 });
        } else if (params.section === "botiguesdistricte") {
          setController3({ boolean: true, key: 2 });
        }
      } else if (
        params.section === "barricasetes" ||
        params.section === "botiguescasetes"
      ) {
        setParamsSection("barricasetes");
        if (params.section === "barricasetes") {
          setController4({ boolean: false, key: 0 });
        } else if (params.section === "botiguescasetes") {
          setController4({ boolean: true, key: 1 });
        }
      }
    }
    if (!displayMobile) {
      setNavbarColor("var(--skyblue)");
      setHamburgerColor("var(--skyblue)");
    }
    if (scheduleToSpacesController && !displayMobile) {
      window.scrollTo(0, 1350);
      setScheduleToSpacesController(false);
    }
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      // En la primera renderització, no facis res
      firstRender.current = false;
      return;
    }
    if (!displayMobile) {
      if (scrollPercent < 201 && scrollPercent > 103) {
        if (controller1.boolean === false) {
          setNavbarColor("var(--mercablue)");
        } else if (controller1.boolean === true) {
          setNavbarColor("var(--skyblue)");
        }
      } else if (scrollPercent > 201 && scrollPercent < 303) {
        if (controller2.boolean === false) {
          setNavbarColor("var(--mercablue)");
        } else if (controller2.boolean === true) {
          setNavbarColor("var(--skyblue)");
        }
      } else if (scrollPercent > 303) {
        if (controller3.boolean === false) {
          setNavbarColor("var(--mercablue)");
        } else if (controller3.boolean === true) {
          setNavbarColor("var(--skyblue)");
        }
      } else if (scrollPercent < 103) {
        setNavbarColor("var(--skyblue)");
      }

      if (scrollPercent >= 0 && scrollPercent < 60) {
        window.history.pushState(null, "", "/spaces/home");
      }
      if (scrollPercent > 60 && scrollPercent < 155) {
        if (controller1.boolean === false && controller1.key === 0) {
          window.history.pushState(null, "", "/spaces/naucentral");
        } else if (controller1.key === 1 && controller1.boolean === true) {
          window.history.pushState(null, "", "/spaces/launio");
        } else if (controller1.key === 2 && controller1.boolean === true) {
          window.history.pushState(null, "", "/spaces/lapuntual");
        } else if (controller1.key === 3 && controller1.boolean === true) {
          window.history.pushState(null, "", "/spaces/escolarestauracio");
        } else if (controller1.key === 4 && controller1.boolean === true) {
          window.history.pushState(null, "", "/spaces/rochebobois");
        } else if (controller1.key === 5 && controller1.boolean === true) {
          window.history.pushState(null, "", "/spaces/botigues");
        }
      }
      // if (scrollPercent > 158 && scrollPercent < 253) {
      //   if (controller2.boolean === false) {
      //     window.history.pushState(null, "", "/spaces/elsiglo");
      //   }
      // }
      if (scrollPercent > 253 && scrollPercent < 363) {
        if (controller3.boolean === false) {
          window.history.pushState(null, "", "/spaces/districteantic");
        } else if (controller3.key === 1) {
          window.history.pushState(null, "", "/spaces/espaisubhasta");
        } else if (controller3.key === 2) {
          window.history.pushState(null, "", "/spaces/botiguesdistricte");
        }
      }
      if (scrollPercent > 363 && scrollPercent < 465) {
        if (controller4.boolean === false) {
          window.history.pushState(null, "", "/spaces/barricasetes");
        } else if (controller4.key === 1 && controller4.boolean === true) {
          window.history.pushState(null, "", "/spaces/botiguescasetes");
        }
      }
    } else if (displayMobile) {
      if (!dropdownMenuSelected) {
        if (scrollPercent > 100) {
          setNavbarColor("var(--mercablue)");
          setHamburgerColor("var(--mercablue)");
        } else if (scrollPercent < 100) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--skyblue)");
        }
      }
    }
  }, [scrollPercent, controller1, controller2, controller3, controller4]);
  const navigate = useNavigate();

  useEffect(() => {
    if (controller1.boolean === true) {
      if (controller1.key === 1) {
        setImages1(launioImages);
      } else if (controller1.key === 2) {
        setImages1(lapuntualImages);
      } else if (controller1.key === 3) {
        setImages1(escolaImages);
      } else if (controller1.key === 4) {
        setImages1(boboisImages);
      } else if (controller1.key === 5) {
        setImages1(ncbotiguesImages);
      }
    } else if (controller1.boolean === false) {
      setImages1(naucentralImages);
    }
  }, [controller1.key]);

  useEffect(() => {
    if (controller3.boolean === true) {
      if (controller3.key === 1) {
        setImages3(espaisubhastaImages);
      } else if (controller3.key === 2) {
        setImages3(dabotiguesImages);
      }
    } else if (controller3.boolean === false) {
      setImages3(districteanticImages);
    }
  }, [controller3.key]);

  useEffect(() => {
    if (controller4.boolean) {
      if (controller4.key === 1) {
        setImages4(bcbotiguesImages);
      }
    } else if (controller4.boolean === false) {
      setImages4(barricasetesImages);
    }
  }, [controller4.key]);

  // useEffect(() => {
  //   if (controller5.boolean) {
  //     if (controller5.key === 1) {
  //       setImages4(bcbotiguesImages);
  //     }
  //   } else if (controller5.boolean === false) {
  //     setImages4(naucentralImages);
  //   }
  // }, [controller5.key]);

  if (displayMobile) {
    return (
      <>
        {data.espais !== undefined && (
          <div style={{ height: "100%" }}>
            <InitialPage
              color={"var(--skyblue)"}
              backgroundColor={"var(--mercablue)"}
              section={data.espais.initialPage.section}
              text={data.espais.initialPage.title}
              map={data.espais.initialPage.laptop}
              link={data.espais.initialPage.link}
              image1={
                "https://ik.imagekit.io/gwms6thri/InitialPage/initialPageEspais.jpg?updatedAt=1690295186110"
              }
              image2={
                "https://ik.imagekit.io/gwms6thri/InitialPage/ESPAIS.svg?updatedAt=1690294490098"
              }
            />
          </div>
        )}
        <div className="espaisPage-container">
          {data.espais !== undefined && (
            <>
              <EspaisPageDefaultContainer
                info={data.espais.sections[0].espais.slice(0, -1)}
                itemKey={data.espais.sections[0].key}
                id={data.espais.initialPage.laptop[0]}
                counter={0}
                cta={data.espais.sections[0].espais[1].cta}
                cta2={data.espais.sections[0].espais[4].cta}
                cta3={data.espais.sections[0].espais[4].cta2}
                paramsSection={paramsSection}
              />
              <EspaisPageDefaultContainer
                info={data.espais.sections[1].espais}
                itemKey={data.espais.sections[1].key}
                id={data.espais.initialPage.laptop[1]}
                counter={1}
                paramsSection={paramsSection}
              />
              <EspaisPageDefaultContainer
                info={data.espais.sections[2].espais.slice(0, -1)}
                itemKey={data.espais.sections[2].key}
                id={data.espais.initialPage.laptop[2]}
                counter={2}
                paramsSection={paramsSection}
              />
              <EspaisPageDefaultContainer
                info={data.espais.sections[3].espais.slice(0, -1)}
                itemKey={data.espais.sections[3].key}
                id={data.espais.initialPage.laptop[3]}
                counter={3}
                paramsSection={paramsSection}
              />
              <EspaisPageDefaultContainer
                info={data.espais.sections[4].espais.slice(0, -1)}
                itemKey={data.espais.sections[4].key}
                id={data.espais.initialPage.laptop[4]}
                counter={4}
              />

              {mobileSectionMenuSelected && (
                <MobileSectionMenu
                  items={data.espais.initialPage.laptop}
                  color={"var(--skyblue)"}
                  backgroundColor={"var(--mercablue)"}
                  section="spaces"
                />
              )}
            </>
          )}
          {data.espais !== undefined && mobileSectionMenuSelected === false && (
            <MobileLinkContainer backgroundColor={"var(--mercablue)"}>
              <MobileLink
                color={"var(--skyblue)"}
                onClick={() => {
                  setMobileSectionMenuSelected(true);
                }}
              >
                {data.espais.initialPage.link}
              </MobileLink>
            </MobileLinkContainer>
          )}
          <Footer
            backgroundColor={"var(--mercablue)"}
            color={"var(--skyblue)"}
            logo={logo}
          />
        </div>
      </>
    );
  } else if (!displayMobile) {
    return (
      <>
        <div className="espaisPage-container">
          {data.espais !== undefined && (
            <>
              <div
                className="espaisPage-laptop-initialPage-container"
                onClick={() => {
                  console.log("clicking");
                  navigate("/spaces/elsiglo");
                }}
              >
                <InitialPage
                  color={"var(--skyblue)"}
                  backgroundColor={"var(--mercablue)"}
                  section={data.espais.initialPage.section}
                  text={data.espais.initialPage.title}
                  map={data.espais.initialPage.laptop}
                  page="spaces"
                  firstSquareWidth={"40%"}
                  secondSquareWidth={"60%"}
                  firstSquareHeight={15}
                  secondSquareHeight={15}
                />
                <IKImage
                  src={
                    "https://ik.imagekit.io/gwms6thri/InitialPage/initialPageEspais.jpg?updatedAt=1690295186110"
                  }
                  lqip={{ active: true, quality: 20, blur: 10 }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <div className="laptop-defaultPage-wrapper">
                    {controller1.boolean === false ? (
                      <EspaisDefaultPage
                        title={data.espais.sections[0].title}
                        text={data.espais.sections[0].espais[0].text}
                        navbar={data.espais.navbar}
                        verticalNavbar={data.espais.sections[0].verticalNavbar}
                        itemKey={0}
                        setController={setController1}
                        controller={"controller1"}
                        id={data.espais.initialPage.laptop[0].idName}
                        setController1={setController1}
                        setController2={setController2}
                        setController3={setController3}
                        setController4={setController4}
                        paramsSection={paramsSection}
                        text2={data?.espais?.sections[0]?.espais[0]?.text2}
                      />
                    ) : (
                      <SubEspaisDefaultPage
                        title={
                          data.espais.sections[0].espais[controller1.key].title
                        }
                        text={
                          data.espais.sections[0].espais[controller1.key].text
                        }
                        navbar={data.espais.navbar}
                        verticalNavbar={
                          data.espais !== undefined &&
                          data.espais.sections[0].espais[controller1.key]
                            .verticalNavbar
                        }
                        text2={
                          data.espais.sections[0].espais[controller1.key].text2
                        }
                        text3={
                          data.espais.sections[0].espais[controller1.key].text3
                        }
                        id={"naucentral"}
                        section={data.espais.sections[0].title.br1}
                        section2={data.espais.sections[0].title.br2}
                        itemKey={0}
                        setController={setController1}
                        setController1={setController1}
                        setController2={setController2}
                        setController3={setController3}
                        setController4={setController4}
                        paramsSection={paramsSection}
                        controller={"controller1"}
                        isRestoration={controller1.key === 3}
                        table={data.espais.sections[0].espais[5].table}
                        isStore={controller1.key === 5}
                        verticalHeight={90}
                        opacity={controller1.key}
                        cta={
                          data.espais.sections[0].espais[controller1.key].cta
                        }
                        cta2={
                          data.espais.sections[0].espais[controller1.key].cta2
                        }
                      />
                    )}
                    <EspaisDefaultPage
                      title={data.espais.sections[1].title}
                      text={data.espais.sections[1].espais[0].text}
                      navbar={data.espais.navbar}
                      verticalNavbar={data.espais.sections[1].verticalNavbar}
                      itemKey={1}
                      setController={setController2}
                      controller="controller2"
                      id={data.espais.initialPage.laptop[1].idName}
                      setController1={setController1}
                      paramsSection={paramsSection}
                      text2={data.espais.sections[1].espais[0].text2}
                    />
                    {controller3.boolean === false ? (
                      <EspaisDefaultPage
                        title={data.espais.sections[2].title}
                        text={data.espais.sections[2].espais[0].text}
                        text2={data.espais.sections[2].espais[0].text2}
                        navbar={data.espais.navbar}
                        verticalNavbar={data.espais.sections[2].verticalNavbar}
                        itemKey={2}
                        setController={setController3}
                        controller="controller3"
                        id={data.espais.initialPage.laptop[2].idName}
                        setController1={setController1}
                        setController2={setController2}
                        setController3={setController3}
                        setController4={setController4}
                        paramsSection={paramsSection}
                      />
                    ) : (
                      <SubEspaisDefaultPage
                        title={
                          data.espais.sections[2].espais[controller3.key].title
                        }
                        text={
                          data.espais.sections[2].espais[controller3.key].text
                        }
                        navbar={data.espais.navbar}
                        verticalNavbar={
                          data.espais !== undefined &&
                          data.espais.sections[2].espais[controller3.key]
                            .verticalNavbar
                        }
                        section={data.espais.sections[2].title.br1}
                        section2={data.espais.sections[2].title.br2}
                        itemKey={2}
                        setController={setController3}
                        controller={"controller3"}
                        setController1={setController1}
                        setController2={setController2}
                        setController3={setController3}
                        setController4={setController4}
                        table={data.espais.sections[2].espais[2].table}
                        isStore={controller3.key === 2}
                        id={data.espais.initialPage.laptop[2].idName}
                        paramsSection={paramsSection}
                      />
                    )}
                    {controller4.boolean === false &&
                    data.espais !== undefined ? (
                      <EspaisDefaultPage
                        title={data.espais.sections[3].title}
                        text={data.espais.sections[3].espais[0].text}
                        text2={data.espais.sections[3].espais[0].text2}
                        navbar={data.espais.navbar}
                        verticalNavbar={data.espais.sections[3].verticalNavbar}
                        itemKey={3}
                        controller="controller4"
                        setController={setController4}
                        setController1={setController1}
                        setController2={setController2}
                        setController3={setController3}
                        setController4={setController4}
                        id={data.espais.initialPage.laptop[3].idName}
                        paramsSection={paramsSection}
                      />
                    ) : (
                      <SubEspaisDefaultPage
                        title={
                          data.espais.sections[3].espais[controller4.key].title
                        }
                        text={
                          data.espais.sections[3].espais[controller4.key].text
                        }
                        navbar={data.espais.navbar}
                        verticalNavbar={
                          data.espais !== undefined &&
                          data.espais.sections[3].espais[controller4.key]
                            .verticalNavbar
                        }
                        section={data.espais.sections[3].title.br1}
                        section2={data.espais.sections[3].title.br2}
                        itemKey={3}
                        setController={setController4}
                        setController1={setController1}
                        setController2={setController2}
                        setController3={setController3}
                        setController4={setController4}
                        paramsSection={paramsSection}
                        controller={"controller4"}
                        table={data.espais.sections[3].espais[1].table}
                        isStore={controller4.key === 1}
                      />
                    )}
                    <EspaisDefaultPage
                      title={data.espais.sections[4].title}
                      text={data.espais.sections[4].espais[0].text}
                      text2={data.espais.sections[4].espais[0].text2}
                      navbar={data.espais.navbar}
                      verticalNavbar={data.espais.sections[4].verticalNavbar}
                      itemKey={3}
                      setController={setController2}
                      id={data.espais.initialPage.laptop[4].idName}
                    />
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <DefaultCarousel images={images1} id={"centralnau"} />
                  <DefaultCarousel images={images2} id={"sigloel"} />
                  <DefaultCarousel images={images3} id={"anticdistricte"} />
                  <DefaultCarousel images={images4} id={"casetesbarri"} />
                  <DefaultCarousel images={images5} id={"espaiwem"} />
                </div>
              </div>
              {data.espais !== undefined && (
                <LinkContainer backgroundColor={"var(--mercablue)"}>
                  {data.espais.initialPage.laptop.map((item, index) => {
                    return (
                      <HashLink
                        to={`/spaces/home#${item.idName}`}
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                          })
                        }
                        key={index}
                        onClick={() => {
                          setEnableScrollToTop(false);
                          setScrollToTopController(false);
                        }}
                      >
                        <Link
                          color={"var(--skyblue)"}
                          opacity={handleOpacity(index)}
                        >
                          {item.name}
                        </Link>
                      </HashLink>
                    );
                  })}
                </LinkContainer>
              )}
            </>
          )}
          <div style={{ height: "100%", display: "flex" }} id="test">
            <Footer
              color={"var(--skyblue)"}
              backgroundColor={"var(--mercablue)"}
              logo={logo}
            />
          </div>
        </div>
      </>
    );
  }
};

export default EspaisPage;
